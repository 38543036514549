import React, { useState, useEffect } from "react";
import "../../assets/css/notification.css";
import { Box, Typography, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

//import images
// import boxIcon from '../assets/img/boxIcon.svg';
// import rupeeIcon from '../assets/img/rupeeIcon.svg';
import loader from "../../assets/images/loader.gif";
import axios from "../../config/axios.Config.v1";
import back from "../../assets/images/chevron-left.svg"
import bellicon from "../../assets/images/bell-black.svg";
import NinjaLoader from "../../components/NinjaLoader";
const Notification = () => {
  const navigate = useNavigate();
  // state objects ...
  const [allNotification, setAllNotification] = useState();
  const [loaderObject, setLoaderObject] = useState(true);

  // API calling for get all notification
  useEffect(() => {
    const userId = sessionStorage.getItem("userId");
    axios
      .get(`/notifications/get`)
      .then((res) => {
        setAllNotification(res.data.result);
        setLoaderObject(false);
        console.log(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Add timer
  function formatTimeElapsed(date) {
    const now = new Date();
    const diffInMs = now.getTime() - date.getTime();
    const diffInSec = Math.floor(diffInMs / 1000);

    if (diffInSec < 60) {
      return "Now";
    } else if (diffInSec < 3600) {
      const diffInMin = Math.floor(diffInSec / 60);
      return `${diffInMin} minute${diffInMin > 1 ? "s" : ""} ago`;
    } else if (diffInSec < 86400) {
      const diffInHr = Math.floor(diffInSec / 3600);
      return `${diffInHr} hour${diffInHr > 1 ? "s" : ""} ago`;
    } else {
      const diffInDay = Math.floor(diffInSec / 86400);
      return `${diffInDay} day${diffInDay > 1 ? "s" : ""} ago`;
    }
  }
  const timestamp = new Date();
  if (!loaderObject) {
    return (
      
      <Box className="h-100">
        <Helmet>
                <meta name="theme-color" content="#002236" />
            </Helmet>
        <Box className="header_notification">
          {/* <ArrowBackIosIcon onClick={() => navigate(-1)} /> */}
          <img src={back} alt="bell" onClick={() => navigate(-1)} />
          <Typography className="notname">Notifications</Typography>
          <Box style={{visibility: 'hidden'}}>
            <img src={bellicon} alt="bell" />
          </Box>
        </Box>
        <Box className="header_offset">
          <Box>
            <Typography className="nHeading hor_space">Order</Typography>
          </Box>
          {allNotification ? (
            allNotification.map((items) => {
              return (
                <Box className="notificationBox" key={items.id}>
                  <img
                    src={items.icon}
                    alt="notificationIcon"
                    className="n-img-box map_img"
                  />
                  <Box className="n-contentBox">
                    <Typography className="n-subheading">
                      {items.message}
                    </Typography>
                    <Typography className="n-content">{items.title}</Typography>
                  </Box>
                  <Typography className="n-time">
                    {formatTimeElapsed(new Date())}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <></>
          )}
        </Box>
      </Box>
    );
  } else {
    return <NinjaLoader />;
  }
};
export default Notification;

// design for future use....in notification
{
  /* <Box className="notificationBox">
                    <img src={boxIcon} alt="boxIcon" className="n-img-box" />
                    <Box className="n-contentBox">
                        <Typography className="n-subheading">Your package ready to pickup soon.</Typography>
                        <Typography className="n-content">Order BX-71720 is already to pickup on 5:20 PM,
                            Hi Kitani, please prepare your package thank you!</Typography>
                        <Button className="btnn accept">Accept</Button>
                        <Button className="btnn deny">Deny</Button>
                    </Box>
                    <Typography className="n-time">New</Typography>
                </Box> */
}
{
  /* <Box>
                    <Typography className="nHeading phorizontal">For You</Typography>
                </Box>
                    <Box className="notificationBox2">
                        <img src={rupeeIcon} alt="rupeeIcon" style={{ marginTop: 5, marginRight: 5 }} />
                        <Box style={{ padding: 5 }}>
                            <Typography className="n-subheading">Congratulation, top up successfully.</Typography>
                            <Typography></Typography>
                            <Typography className="n-content">Your transaction top up 50,00 is success</Typography>
                        </Box>
                        <Typography className="n-time">10 min</Typography>
                    </Box>
                <Box className="phorizontal read-all-n">
                    <Button className='n-read-button'>Read All</Button>
                </Box> */
}
