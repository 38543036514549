import React, { useEffect } from "react";
import Router from "./routes/Router";
import MainContextWrapper from "./config/context.Config";
import "./app.css";
import { messaging } from "./config/firebase.Config";
import "firebase/messaging";
import { getToken } from "firebase/messaging";
import PwaInstallPopupIOS from 'react-pwa-install-ios';

const App = () =>
{
  async function requestPermission()
  {
    const permission = await Notification.requestPermission();
    if (permission === "granted")
    {
      //generate token
      // const token = await getToken(messaging, {
      //   vapidKey: `${process.env.REACT_APP_FCM_SERVER_KEY}`,
      // });

      getToken(messaging, { vapidKey: process.env.REACT_APP_FCM_SERVER_KEY })
        .then((currentToken) =>
        {
          if (currentToken)
          {
            // The FCM token is available
            console.log("FCM token:", currentToken);
          } else
          {
            // No FCM token available
            console.log("Unable to retrieve FCM token.");
          }
        })
        .catch((err) => console.log(err));
    } else if (permission === "denied")
    {
      console.log("you denied for the notifictaion");
    }
  }

  useEffect(() =>
  {
    requestPermission();
  }, []);

  useEffect(() =>
  {
    const meta = document.createElement("meta");
    meta.setAttribute("name", "viewport");
    meta.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0"
    );
    document.head.appendChild(meta);
    return () =>
    {
      document.head.removeChild(meta);
    };
  }, []);
  return (
    <MainContextWrapper>
      <Router />
      <PwaInstallPopupIOS>
        <div
          delay={0}
          style={{
            padding: '15px 30px',
            backgroundColor: '#002236',
            color: 'white',
            textAlign: 'center',
          }}
        >
          Hey ! you can install Pickup Ninja on your iPhone - <br />
          1. Click on share button.<br />
          2. Select 'Add to Homescreen'.<br />
          3. Click 'Add' on top right.<br />
        </div>
      </PwaInstallPopupIOS>
    </MainContextWrapper>
  );
};
export default App;
