import React, { useState, useEffect, useContext } from "react";
import "../../assets/css/savedAddress.css";
import { Box, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../config/axios.Config";
import { toast, Toaster } from "react-hot-toast";
import { MainContext } from "../../config/context.Config";

//image..
import locationIcon from "../../assets/images/locationIcon.svg";
import logo from "../../assets/images/appstore.png";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import NinjaLoader from "../../components/NinjaLoader";
import { BottomSheet } from "react-spring-bottom-sheet";

const SavedAddress = (props) => {
  // navigate object for page navigation
  const navigate = useNavigate();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const slug = useQuery().get("businessUrl");
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [addressDeleteAddress, setAddressDeleteAddress] = useState(null);

  const {
    setPickupDetails,
    setDropDetails,
    userId,
    setEditAddress,
    setBusinessAddDetails,
    setSelectedAddress,
    selectedAddress,
    newAddressList,
    setNewAddressList  
  } = useContext(MainContext);

  // global variables
  let addressName = window.location.pathname.split("/")[2];
 

  // states..........................
  const [addressList, setAddressList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDataId, setUserDataId] = useState(userId);
  const [loaderState, setLoaderState] = useState(false);
 
  
  // mounting process ................
  // Updating list of address when  loading is updated

  useEffect(() => {
    async function getUserData() {
      const { result } = await axios.get("/users/get/user");
      setUserDataId(result?._id);
    }
    getUserData();
  }, []);

  useEffect(() => { 
    const getAddressDetails =()=>{
      axios
      .get(`destinations/get/${userId}`)
      .then((res) => {
        setAddressList([...res.data.result], {});
        setNewAddressList([...res.data.result], {})
        setLoaderState(true);
       
      })
      .catch((err) => console.log(err));
    }
      if(userId){
        getAddressDetails();
      }
      
  }, [userId,setNewAddressList]);
  const  updateAddressList =() =>{
    console.log("update addressList called");
    console.log("selected address" , selectedAddress);
    console.log("addressList" , addressList);

    if(selectedAddress){
    const address = addressList.filter(item=>item.id !== selectedAddress.id) 
    setNewAddressList([...address ,selectedAddress])
    console.log(address);
    }else{
      setNewAddressList(addressList)
    }
  }
  useEffect(()=>{
    async function  addressListData (address) {
   
      console.log(address);
      setNewAddressList([...address, selectedAddress])
    }
    if(selectedAddress){
      const address = addressList?.filter(item=>item.id !== selectedAddress.id) 
      console.log("***********",addressList);
      addressListData(address);
    }
  },[addressList,setNewAddressList,selectedAddress])
   
  console.log("@@@@@@@@@",newAddressList)
   
  const handleDeleteAddress = (sheet, id) => {
    setOpenBottomSheet(sheet);
    setAddressDeleteAddress(id);
  };

  // Method for delete destination .
  const deleteDestination = (destId) => {
    setNewAddressList(newAddressList.filter((id) => id.id !== destId));
    setOpenBottomSheet(false);
    axios
      .delete(`/destinations/delete/${destId}`)
      .then((res) => {
        toast.success("Deleted Successfully");
      })
      .catch(() => toast.error("Error while deleting please try again"));
  };

  // this method is to edit destination address it will redirect to edit page and Store information in the localStorage
  const editDestination = (data) => {
    navigate("/destination/Edit");
    setEditAddress(data);
  };

  const setItemForRemove = (itemInfo) => {
      console.log(itemInfo);
    if (addressName === "Pickup") {
      setPickupDetails(itemInfo);
    }
    if (addressName === "Drop") {
      setDropDetails(itemInfo);
    }
  };
  

  const handleCardClick = (items) => {
    if(items.id !== selectedAddress?.id){
    setSelectedAddress({...items , type:addressName});
    }
    console.log(items)
    if (slug) {
      setBusinessAddDetails(items);
      navigate(`/businessorder?businessUrl=${slug}`, {
        state: { result: items, type: addressName },
      });
    } else {
      if (selectedAddress?.id !== items.id || selectedAddress?.type === addressName){
        setItemForRemove(items);
        navigate("/dashboard", { state: { result: items, type: addressName } });

      }else{
        toast.error("Both Pick and Drop address can not be same please select a different address");
      }
     
    }
  };
  const handleContainerClick = (event, items) => {
    // Check if the clicked element is the edit or delete button
    if (event.target.classList.contains("button-tab")) {
      return;
    }

    // Handle the card click event
    handleCardClick(items);
  };

  const handleAddress = () => {
    if (slug) {
      return navigate(`/destination/Add?businessUrl=${slug}`);
    }
    navigate(`/destination/Add?addressType=${addressName}`);
  };

  //  JSX Start.......

  return (
    <Box className="h-100">
      <Helmet>
        <meta name="theme-color" content="#002236" />
      </Helmet>
      <Toaster toastOptions={{ duration: 1000 }} />
      <Box className="header_notification">
        {/* <ArrowBackIosIcon onClick={() => navigate(-1)} /> */}
        <img src={back} alt="bell" onClick={() => navigate(-1)} />
        <Typography className="notname">Saved Addresses</Typography>
        <Link to="/notification">
          {/* <NotificationsNoneIcon className="d-change-colour" /> */}
          <img src={bellicon} alt="bell" />
        </Link>
      </Box>
      <Box className="header_offset d-flex flex-column h-100">
        {loaderState ? (
          <>
            <Box className="last-address phorizontalll hor_space" style={{flex: '1', overflow: 'auto', marginBottom: '20px'}}>
              {
                // renderAddresses
                newAddressList.length ? (
                  newAddressList.map((items) => {
                    if (Object.keys(items).length !== 0) {
                      return (
                        <Box key={items.id} className="">
                          {
                            <Box
                              className="da-content"
                              key={newAddressList.indexOf(items)}
                              onClick={(event) =>
                                handleContainerClick(event, items)
                              }
                            >
                              <img src={locationIcon} alt="location" />
                              <Box className="flex-fill">
                                {/* <Box onClick={()=>{localStorage.setItem('selectedItem', items.id)}}> */}
                                <Box>
                                  <Typography className="da-contentHeading">
                                    {items.alias}
                                  </Typography>
                                  <Typography className="da-contentText">
                                    {items.address}
                                  </Typography>
                                </Box>

                                <Box
                                  className="d-flex"
                                  style={{ marginTop: "10px" }}
                                >
                                  <Box
                                    className="button-tab"
                                    style={{ marginRight: "30px" }}
                                    onClick={() => editDestination(items)}
                                  >
                                    Edit
                                  </Box>
                                  <Box
                                    className="button-tab"
                                    // onClick={() => deleteDestination(items.id)}
                                    onClick={() =>
                                      handleDeleteAddress(true, items.id)
                                    }
                                  >
                                    Delete
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          }
                        </Box>
                      );
                    } else {
                      return <Box mt={12}>&nbsp;</Box>;
                    }
                  })
                ) : (
                  <Box>
                    <img src={logo} alt="logo" className="inactive_logo" />
                    <Typography className="inactive_text">
                      Please add an address
                    </Typography>
                  </Box>
                )
              }
            </Box>
            <Box className="phorizontal mt-auto">
              <Button
                sx={{ textTransform: "none"}}
                className="add-button"
                onClick={handleAddress}
              >
                Add New Address
              </Button>
            </Box>
          </>
        ) : (
          <div>
            <NinjaLoader />
            {/* <img src={loader}  alt="loader" className="loader_img"/> */}
          </div>
        )}
      </Box>
      <BottomSheet
        defaultSnap={({ maxHeight }) => maxHeight / 5}
        snapPoints={({ minHeight }) => minHeight}
        expandOnContentDrag={true}
        open={openBottomSheet}
        close={openBottomSheet}
        onDismiss={() => setOpenBottomSheet(false)}
      >
        <div className="addess-sheet" style={{ overflow: "auto" }}>
          <Box className="bottomdesign">
            <Typography className="placedorder">
              Are you sure you want to delete address?
            </Typography>
            <Box className="d-flex" sx={{marginBottom:5}}>
              <Button
                sx={{
                  marginTop: "10px !important",
                  marginRight: "5px !important",
                }}
                className="deleteAccBtn flex-fill"
                onClick={() => deleteDestination(addressDeleteAddress)}
              >
                Yes
              </Button>
              <Button
                onClick={() => setOpenBottomSheet(false)}
                className="logOutBtn flex-fill"
                sx={{
                  marginTop: "10px !important",
                  marginLeft: "5px !important",
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </div>
      </BottomSheet>
    </Box>
  );
};
export default SavedAddress;
