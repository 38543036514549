import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Business/businessshare.css";
import axios from "../../config/axios.Config";
import { MainContext } from "../../config/context.Config";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import { toast, Toaster } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// mui imports start
import {
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Modal,
} from "@mui/material";
// mui import end
// image import start
import pnlogoo from "../../assets/images/business/pnlogoo.svg";
import cuser from "../../assets/images/business/cuser-white.png";
import copy from "../../assets/images/business/copy.svg";
import Close from "../../assets/images/business/Close.svg";
import location from "../../assets/images/locationIcon.svg";
import rdot from "../../assets/images/business/rdot.svg";
import machine from "../../assets/images/business/machine.svg";
import chain from "../../assets/images/business/linkLink.png";
import clock from "../../assets/images/clock.png";
import call from "../../assets/images/callicon.svg";
import wwhatsapp from "../../assets/images/business/wwhatsapp.svg";
import landmark from "../../assets/images/business/landmark.svg";
import { Helmet } from "react-helmet";
import { tConvert } from "../../util/FormatTime";
import NinjaLoader from "../../components/NinjaLoader";
// image import end

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 335,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  padding: "10px",
};

const Businesssharetwo = () => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [businessInfo, setBusinessInfo] = useState(null);
  const { userId } = useContext(MainContext);
  const { slug } = useParams();
  useEffect(() => {
    axios
      .get(`/business/get/businessByUrl/${slug}`)
      .then((res) => {
        setBusinessInfo(res.data.result);
      })
      .catch((err) => {
        navigate("/pageNotFound")
        console.log(err);
      });
  }, [slug, navigate]);  
  
  const clipboardHandler = () => {
    toast.success("Copied successfully!");
    navigator.clipboard.writeText(`${process.env.REACT_APP_CUST_URL}${businessInfo?.url}`);
  };

  if(!businessInfo) {
    return <NinjaLoader/>
  }

  return (
    <Box>
      <Helmet>
        <meta name="theme-color" content="#002236" />
      </Helmet>
      <Toaster toastOptions={{ duration: 4000 }} />
      <Box className="sharehead">
        <img src={pnlogoo} alt="pnlogo" className="plogooo" />
        {!businessInfo?.avatar && (
          <img src={cuser} alt="pnlogo" className="plogoooo" />
        )}
        {businessInfo?.avatar && (
          <img src={businessInfo?.avatar} alt="pnlogo" className="plogoooo" />
        )}
      </Box>
      <Typography className="bsaccount">Business Account</Typography>
      <Box className="phorizontal">
        <Box className="dcontain">
          <img
            src={location}
            alt="location"
            className="locicon"
            style={{ minWidth: "32px" }}
          />
          <Box>
            <Typography className="locationhead">
            {businessInfo?.name || businessInfo?.addressObj?.name}
            </Typography>
            <Typography className="locationtitle">
              {businessInfo?.addressObj?.address}
            </Typography>
            <Typography className="locationtitle dflexxx">
              {" "}
              <img src={rdot} alt="pnlogo" className="locicon" /> Reopen at{" "}
              {businessInfo?.startTime} am, today
            </Typography>
          </Box>
        </Box>
        <Box className="dcontain align-items-center">
          <img
            src={machine}
            alt="location"
            className="locicon"
            style={{ minWidth: "32px" }}
          />
          <Box>
            <Typography className="locationhead">About business</Typography>
            <Typography className="locationtitle">
              {businessInfo?.about}
            </Typography>
          </Box>
        </Box>
        <Box className="dcontain align-items-center">
          <img
            src={chain}
            alt="location"
            className="locicon"
            style={{ minWidth: "32px" }}
          />
          <Box className="flex-fill">
            <Typography className="locationhead">
            {process.env.REACT_APP_CUST_URL}{businessInfo?.url}
            </Typography>
            <Typography className="locationtitle">
              Link for customers
            </Typography>
          </Box>
          {/* <img src={contact} alt="location" className="lociconn" /> */}
          <Typography className="changeName " onClick={clipboardHandler} style={{alignSelf: 'center'}}>
          <img src={copy} alt="location" className="locicon" />
          </Typography>
        </Box>
        <Box className="dcontain align-items-center">
          <img
            src={clock}
            alt="location"
            className="locicon"
            style={{ minWidth: "32px" }}
          />
          <Box>
            <Typography className="locationtitle">Working hours</Typography>
            <Typography className="locationhead">
              {tConvert(businessInfo?.startTime)} -{" "}
              {tConvert(businessInfo?.endTime)}
            </Typography>
          </Box>
        </Box>
        {/* <div>
            <Typography className="i-package">Package Type</Typography>
            <Swiper
              spaceBetween={10}
              breakpoints={{
                576: {
                  width: 576,
                  slidesPerView: 4.2,
                },
                768: {
                  width: 768,
                  slidesPerView: 5.5,
                },
              }}
              slidesPerView={4.2}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              className="i-swipe-package"
            >
              {businessInfo?.packageTypes?.map((data) => {
                return (
                  <SwiperSlide className="i-swiper-itemss" key={data?.id}>
                    <Box className={`i-package-imgg-lay`}>
                      <img
                        src={data?.icon}
                        alt="pnlogo"
                        className="i-package-imgg"
                      />
                    </Box>
                    <Typography className="i-package-item-name">
                      {data?.type}
                    </Typography>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div> */}
      </Box>
      <Box className="borderbottomm"></Box>
      <Box className="phorizontal">
        <Box className="dcontain">
          <img
            src={call}
            alt="location"
            className="locicon"
            style={{ minWidth: "32px" }}
          />
          <Box>
            <Typography className="locationhead mbottommm">
              Contact the seller directly for orders & settle the payment
            </Typography>
            <Box className="bsdlay">
              <Button
                className="callsellerrr"
                href={"tel:+91" + businessInfo?.mobile}
              >
                Call The Seller
              </Button>
              <ReactWhatsapp
                number={businessInfo?.whatsapp || ""}
                message={`Hi there, I would like to place an order via your Pickup Ninja business page.`}
                className="chatsellerrr"
              >
                <img src={wwhatsapp} alt="location" className="" />
              </ReactWhatsapp>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="borderbottomm"></Box>
      <Box className="phorizontal">
        <Box className="dcontain bs-eb">
          <img
            src={landmark}
            alt="location"
            className="locicon"
            style={{ minWidth: "32px" }}
          />
          <Box>
            <Typography className="locationhead mbottommm">
              Already ordered? Use PickUp Ninja to pick up your order
            </Typography>
            <Box>
              <Button className="callseller" onClick={handleOpen}>
                Get My Order Pick Up
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box onClick={handleClose} className="button-close">
            <img src={Close} alt="close" />
          </Box>
          <Typography className="placedorder">
            Have you placed your order?
          </Typography>
          <Typography className="placedordercon">
            We hope that you already do it and it is ready for pick up
          </Typography>
          <Link to={`/businessorder?businessUrl=${slug}`}>
            {" "}
            <Button className="mbuttonnm">
              Yes, continue get order pick up
            </Button>
          </Link>
          <Button
            className="mbuttonnm mbuttonnmmm"
            href={"tel:+91" + businessInfo?.mobile}
          >
            No, call saler place my order
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Businesssharetwo;
