import { useEffect, useRef ,useState} from 'react';
import './components.css';

export const OTPInput = ({setOtp}) => {
  const inputsRef = useRef([]);
  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  const handleInput = (event, index) => {
    const inputLength = event.target.value.length;
    const maxLength = parseInt(event.target.getAttribute('maxlength'));
    // Move focus to the next input field if user has typed something and is at maxLength
    if (inputLength > 0 && inputLength === maxLength && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    } 
    // Update the value of the next input field if user types something in the current input field
    else if (inputLength > 0 && inputLength < maxLength && index < inputsRef.current.length - 1) {
      const nextInput = inputsRef.current[index + 1];
      nextInput.value = event.target.value.substr(-1);
      nextInput.focus();
    }
    // Move focus to the previous input field if user has deleted all characters in the current input field
    else if (inputLength === 0 && index > 0) {
      inputsRef.current[index - 1].focus();
    }
    
  };
  
  const handleArrowKeyPress = (event, index) => {
    // Move focus to the next input field if user presses the right arrow key
    if (event.key === 'ArrowRight' && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    } 
    // Move focus to the previous input field if user presses the left arrow key
    else if (event.key === 'BackSpace' && index > 0) {
      inputsRef.current[index - 1].focus();
      const currentValue = event.target.value.trim();
      // Move focus to previous input field if current input is empty or only contains whitespace
      if (currentValue === '') {
        inputsRef.current[index - 1].focus();
      }
    }
  };
  const handleBlur = (event) => {
    const allInputs = document.querySelectorAll('.otp_Input_created');
    let otpValue = '';
    allInputs.forEach((input) => {
      otpValue += input.value;
    });
    setOtp(otpValue);
  };
 
  return (
    <div className="otp_input_box">
      <input
        className='otp_Input_created'
        type="tel"
        maxLength="1"
        required
        ref={el => inputsRef.current[0] = el}
        onInput={e => handleInput(e, 0)}
        onKeyDown={(event) => handleArrowKeyPress(event,0)}
      />
      <input
        className='otp_Input_created'
        type="tel"
        maxLength="1"
        required
        ref={el => inputsRef.current[1] = el}
        onInput={e => handleInput(e, 1)}
        onKeyDown={(event) => handleArrowKeyPress(event,1)}
      />
      <input
        className='otp_Input_created'
        type="tel"
        maxLength="1"
        required
        ref={el => inputsRef.current[2] = el}
        onInput={e => handleInput(e, 2)}
        onKeyDown={(event) => handleArrowKeyPress(event,2)}
      />
      <input
        className='otp_Input_created'
        type="tel"
        maxLength="1"
        required
        ref={el => inputsRef.current[3] = el}
        onInput={e => handleInput(e, 3)}
        onKeyDown={(event) => handleArrowKeyPress(event,3)}
      />
      <input
        className='otp_Input_created'
        type="tel"
        maxLength="1"
        required
        ref={el => inputsRef.current[4] = el}
        onInput={e => handleInput(e, 4)}
        onKeyDown={(event) => handleArrowKeyPress(event,4)}
      />
      <input
        className='otp_Input_created'
        style={{marginRight:0}}
        type="tel"
        maxLength="1"
        required
        ref={el => inputsRef.current[5] = el}
        onInput={e => handleInput(e, 5)}
        onBlur={handleBlur}
        onKeyDown={(event) => handleArrowKeyPress(event,5)}
      />
    </div>
  );
};
