import React, { useState, useEffect, useContext, useRef } from "react";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import "../../assets/css/dashBoard.css";
import { MainContext } from "../../config/context.Config";
import axios from "../../config/axios.Config.jsx";
import Businessshare from "../business/Business";
import RegisterBusiness from "../business/RegisterBusiness";
import back from "../../assets/images/chevron-left.svg";
import cuser from "../../assets/images/business/cuser-white.png";
import pnlogoo from "../../assets/images/pickupninjatexticonred.svg";
import menuicon from "../../assets/images/menu-burger-square.svg";
import bellicon from "../../assets/images/bell.svg";
import pnbluelogo from "../../assets/images/pickupninjatexticonblue.svg";
import locationn from "../../assets/images/locationIcon.svg";
import rarrow from "../../assets/images/greaterthanIcon.svg";
import flag from "../../assets/images/flagIcon.svg";
import myorder from "../../assets/images/myorder.svg";
import DA from "../../assets/images/DA.svg";
import PH from "../../assets/images/PH.svg";
import ADL from "../../assets/images/arrow-down-light.svg";
import NOT from "../../assets/images/NOT.svg";
import clockIcon2 from "../../assets/images/clockIcon2.svg";
import clockIcon1 from "../../assets/images/clockIcon1.svg";
import inst from "../../assets/images/instructionIcon.svg";
import fraigle1Disabled from "../../assets/images/fraigle-disabled.svg";
import fraigle1 from "../../assets/images/fraigle.svg";
import myTask from "../../assets/images/business/myTask.svg";
import fragile_warn from "../../assets/images/fragile-warn.png";
import deliveryboy from "../../assets/images/delivery-boy.png";
import box from "../../assets/images/landmark_add.svg";
import aavtar from "../../assets/images/business/cuser-white.svg";
import selectLocation from "../../assets/images/🦆 icon _location_.svg";
import { getAuth, signOut } from "firebase/auth";
//import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NinjaLoader from "../../components/NinjaLoader";
const Dashboard = (props) =>
{
  const {
    userInfo,
    userId,
    loading,
    isError,
    name,
    dashboard,
    pickupDetails,
    setPickupDetails,
    businessAddDetails,
    dropDetails,
    setDropDetails,
    instructionDetails,
    dispatch,
    getUser,
    cityId,
    setCityId,
    userPreference,
    setUserPreference,
    cityName,
  } = useContext(MainContext);
  const [dateTimeValue, setDateTimeValue] = useState(dayjs(new Date().toISOString()));
  const [openDateTime, setOpenDateTime] = useState(false);
  const [pickupAddress, setPickupAddress] = useState(pickupDetails);
  const [businessData, setBusinessData] = useState(null);
  // const [businessResponse, setBusinessResponse] = useState(false);
  const [switchAccount, setSwitchAccount] = useState(false);
  const [scheduleBooking, setScheduleBooking] = useState();
  const [editBusiness, setEditBusiness] = useState(false);
  const [instructionpath, setInstructionPath] = useState("/instruction/Create");
  const [dropAddress, setDropAddress] = useState(dropDetails);
  const [instructionAdded, setInstructionAdded] = useState(instructionDetails);
  const [pickDone, setPickDone] = useState(false);
  const [dropDone, setDropDone] = useState(false);
  const [displayy, setDisplayy] = useState("upperlayerone");
  // ----------------------------------------------------
  const [booknow, setbooknow] = useState("/paymentdetails");

  // -------------------------------------------
  const [active, setActive] = useState("aavtarlaybox");
  const [activee, setActivee] = useState("aavtarlaybox");
  const [activeee, setActiveee] = useState("aavtarlaybox");
  const [activeeee, setActiveeee] = useState("aavtarlaybox");
  const [instructionDone, setInstructionDone] = useState(false);
  const [date, setDate] = useState("");

  const [bookingButtonClass, setBookingButtonClass] = useState(false);
  const datepickerRef = useRef(null);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [displayMenu, setDisplayMenu] = useState(false);
  const locationPath = useLocation();

  useEffect(() =>
  {
    setDisplayy(locationPath.state?.displayy || "upperlayerone");
    setSwitchAccount(locationPath.state?.switchAccount);
    setEditBusiness(locationPath.state?.isEditing);
  }, [
    locationPath.state?.displayy,
    locationPath.state?.switchAccount,
    locationPath.state?.isEditing,
  ]);

  console.log(scheduleBooking);
  // useEffect(() => {
  //   if (
  //     dropDetails?.address?.length &&
  //     pickupDetails?.address?.length &&
  //     dropDetails?.address === pickupDetails?.address
  //   ) {
  //     setPickupAddress("");
  //     setDropAddress("");
  //     setPickupDetails("");
  //     setDropDetails("");
  //     toast.error("Pickup Address and Drop Address must be different");
  //   }
  // }, [
  //   dropDetails?.address,
  //   pickupDetails?.address,
  //   setPickupDetails,
  //   setDropDetails,
  // ]);

  useEffect(() =>
  {
    setUserPreference(localStorage.getItem("businessPreference"));
    if (userPreference)
    {
      navigate("/business");
      // setSwitchAccount(true);
      // setEditBusiness(false)
    }
    setCityId(localStorage.getItem("cityId"));
    if (!cityId)
    {
      navigate("/city/add");
    }
    axios
      .get("/users/get/user")
      .then((res) =>
      {
        if (res.data.result.name === null)
        {
          navigate("/profile/add");
        }
        dispatch({ type: "USER_DATA", payload: res.data });
        setUserData(res.data.result);
        setDisplayMenu(true);
      })
      .catch((err) => console.log(err));

    if (instructionAdded)
    {
      setInstructionPath("/instruction/Edit");
    }
  }, [cityId, dispatch, instructionAdded, navigate, setCityId, setUserPreference, userPreference]);

  useEffect(() =>
  {
    if (pickupAddress)
    {
      setPickDone(true);
    }
    if (dropAddress)
    {
      setDropDone(true);
    }
    if (instructionAdded)
    {
      setInstructionDone(true);
    }
  }, [pickupAddress, dropAddress, instructionAdded]);

  useEffect(() =>
  {
    if (pickDone && dropDone && instructionDone)
    {
      setBookingButtonClass(true);
    }
  }, [pickDone, dropDone, instructionDone]);

  useEffect(() =>
  {
    async function getBusinessDetails()
    {
      try
      {
        const businessData = await axios.get(`/business/get/${userId}`);
        setBusinessData(businessData.data.result);
        // setBusinessResponse(true)
      } catch (error)
      {
        // setBusinessResponse(true)
        setBusinessData(false);
      }
    }
    getBusinessDetails();
  }, [userId]);

  const displayUser = () =>
  {
    if (userData)
    {
      console.log(userData, "********************User Data");
      return (
        <>
          <Typography className="aavtarrcountry">{userData.name}</Typography>
          <Typography className="aavtarrcountry">{userData.mobile}</Typography>
        </>
      );
    } else
    {
      <Typography className="aavtarrcountry">User not found</Typography>;
    }
  };

  const handleDatePickerImageClick = () =>
  {
    datepickerRef.current.showPicker();
  };

  // -------------------------------------------Api

  const slideHandler = () =>
  {
    setbooknow("");
    setDisplayy(
      displayy === "upperlayerone" ? "upperlayertwo" : "upperlayerone"
    );
  };
  const slideHandlerr = () =>
  {
    setDisplayy(
      displayy === "upperlayertwo" ? "upperlayerone" : "upperlayerone"
    );

    setbooknow("/paymentdetails");
  };
  // -------------------------------------------
  const activeHandler = () =>
  {
    setActive(active === "aavtarlaybox" ? "aavtarlayboxx" : "aavtarlaybox");
    setActivee(activee === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox");
    setActiveee(activeee === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox");
    setActiveeee(
      activeeee === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox"
    );
  };
  const activeHandlerr = () =>
  {
    setActive(active === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox");
    setActivee(activee === "aavtarlaybox" ? "aavtarlayboxx" : "aavtarlaybox");
    setActiveee(activeee === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox");
    setActiveeee(
      activeeee === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox"
    );
  };
  const activeHandlerrr = () =>
  {
    setActive(active === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox");
    setActivee(activee === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox");
    setActiveee(activeee === "aavtarlaybox" ? "aavtarlayboxx" : "aavtarlaybox");
    setActiveeee(
      activeeee === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox"
    );
  };
  const activeHandlerrrr = () =>
  {
    setActive(active === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox");
    setActivee(activee === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox");
    setActiveee(activeee === "aavtarlaybox" ? "aavtarlaybox" : "aavtarlaybox");
    setActiveeee(
      activeeee === "aavtarlaybox" ? "aavtarlayboxx" : "aavtarlaybox"
    );
  };
  // -------------------------------------------
  // add booking.....
  const addBooking = () =>
  {
    navigate("/payments");
  };

  const handleBack = () =>
  {
    setEditBusiness(false);
    setSwitchAccount(true);
    setDisplayy("upperlayerone");
    navigate("/dashboard");
  };

  const handleClickBookNow = () =>
  {
    if (bookingButtonClass)
    {
      let state = {
        pickupDetails: pickupDetails,
        dropDetails: dropDetails,
        instructionDetails: instructionDetails,
        scheduleBooking: scheduleBooking,
      };
      sessionStorage.setItem("paymentDetails", JSON.stringify(state));
      navigate("/payments");
    } else
    {
      return null;
    }
  };
  // function for logout
  function handelLogout()
  {
    const auth = getAuth();
    signOut(auth)
      .then(() =>
      {
        // Sign-out successful.
        localStorage.clear();
        navigate("/");
      })
      .catch((error) =>
      {
        // An error happened.
        toast.err(error);
      });
  }

  // function for delete Account
  function handelDelete()
  {
    axios
      .delete(`users/delete/${userId}`)
      .then(() =>
      {
        localStorage.clear();
        navigate("/");
      })
      .catch(() =>
      {
        toast.error("Unable to delete .....");
      });
  }

  function goToUserAccount()
  {
    if (businessData)
    {
      localStorage.setItem("businessPreference", true);
    }
    setDisplayy("upperlayerone");
    setSwitchAccount(true);
  }
  function goToBusinessAccount()
  {
    setDisplayy("upperlayerone");
    setSwitchAccount(false);
  }

  const handleBusinessProfileEdit = () =>
  {
    if (businessData)
    {
      setDisplayy("upperlayerone");
      setEditBusiness(true);
    }
  };

  const handleDateChange = (date) =>
  {
    setDate(date);
  };

  let page = null;
  if (switchAccount)
  {
    page = (
      <>
        <Box
          className={displayy}
          onClick={displayy === "upperlayertwo" ? slideHandlerr : () => { }}
        >
          <Box className={businessData ? "register-header" : "register-header"}>
            <Box className="hammenuu ham-menu">
              <img
                src={editBusiness ? back : menuicon}
                alt="menu"
                onClick={editBusiness ? handleBack : slideHandler}
              />
              {/* <MenuIcon className="ham" onClick={slideHandler} /> */}
              <img src={pnlogoo} alt="location" />
              <Link to="/notification">
                {/* <NotificationsNoneIcon className="ham" /> */}
                <img src={bellicon} alt="bell" />
              </Link>
            </Box>
          </Box>
          {businessData && !editBusiness && (
            <Businessshare businessData={businessData} />
          )}
          {(!businessData || editBusiness) && (
            <RegisterBusiness
              displayy={displayy}
              editBusiness={editBusiness}
              setSwitchAccount={setSwitchAccount}
              setDisplayy={setDisplayy}
              setEditBusiness={setEditBusiness}
            />
          )}
        </Box>
        <Box
          className="lowerlayer"
        // onClick={slideHandler}
        >
          <Box className="shadow" />
          <Box className="hammenuu">
            {/* <MenuIcon className="ham"/> */}
            <Box></Box>
            <img src={pnbluelogo} alt="location" />
            <Link to="/notification">
              {/* <NotificationsNoneIcon className="ham" /> */}
              <img src={bellicon} alt="bell" />
            </Link>
          </Box>
          <Box className="aavtarlay mmkk">
            {/* <Link to={`/profile/${userId}`}> */}
            <div onClick={handleBusinessProfileEdit}>
              {businessData?.avatar ? (
                <img
                  src={businessData?.avatar}
                  alt="location"
                  className="aavtarr"
                />
              ) : (
                <img src={cuser} alt="kmlkolo" className="aavtarr" />
              )}
            </div>
            {/* </Link> */}
            {/* <div>
              <Typography onClick={goToBusinessAccount} className="aavtarracc">
                Switch to Individuals account
              </Typography>
            </div> */}
          </Box>

          <Typography
            className="aavtar-name"
            style={{ maxWidth: "190px", marginBottom: "15px" }}
          >
            {businessData?.name || businessData?.addressObj?.name}
          </Typography>
          {/* <Typography className="aavtar-country">
            {businessData?.cityObj?.city}
          </Typography> */}
          <Box className="m-lay-content">
            <Box>
              <Box className={active} onClick={activeHandler}>
                <img src={myTask} alt="location" className="aavtarricon" />
                <Link to="/pageNotFound">
                  {" "}
                  <Typography className="aavtarrorder">Bookings</Typography>
                </Link>
              </Box>
            </Box>
            <Box>
              <Box className={activee} onClick={activeHandlerr}>
                <img src={DA} alt="location" className="aavtarricon" />
                <Link to="/pageNotFound">
                  <Typography className="aavtarrorder">Customers</Typography>
                </Link>
              </Box>
            </Box>

            {/* <Box className="aavtarlaybox aavtarlayboxxx">
              <img src={LO} alt="location" className="aavtarricon" />
              <Typography
                className="aavtarrorder"
                style={{ cursor: "pointer" }}
                onClick={() => handelLogout()}
              >
                Log out
              </Typography>
            </Box> */}
            <Typography
              className="aavtarrorder avatar-legal"
              style={{ cursor: "pointer" }}
            >
              Legal Policy
            </Typography>
            <Link to="/TermsAndConditions">
              <Typography
                className="aavtarrorder avatar-legal"
                style={{ cursor: "pointer", marginLeft: "102px" }}
              >
                Terms
              </Typography>
            </Link>
            {/* <Typography
              className="aavtarrorder avatar-delete"
              style={{ cursor: "pointer" }}
              onClick={() => handelDelete()}
            >
              Delete account
            </Typography> */}
          </Box>

          <Typography onClick={goToBusinessAccount} className="switch-business">
            Switch to Individual account
          </Typography>
        </Box>
      </>
    );
  } else
  {
    page = (
      <>
        <Box
          className={displayy}
          onClick={displayy === "upperlayertwo" ? slideHandlerr : () => { }}
        >
          <Helmet>
            <meta name="theme-color" content="#002236" />
          </Helmet>
          <Box className="hor_space home-header">
            <Box className="hammenuu">
              {/* <MenuIcon className="ham" onClick={slideHandler} /> */}
              <img src={menuicon} alt="menu" onClick={slideHandler} />
              <img src={pnlogoo} alt="location" />
              <Link to="/notification">
                {/* <NotificationsNoneIcon className="ham" /> */}
                <img src={bellicon} alt="bell" />
              </Link>
            </Box>
          </Box>
          <Box className="ulupper">
            <Typography className="ppppaddress ff_Cabin">
              Pickup Address
            </Typography>
            <Link
              to={`${displayy === "upperlayerone" ? "/address/Pickup" : ""}`}
            >
              <Box className="pickbox">
                <img src={locationn} alt="location" className="pickboxloc" />
                <Box className="flex-fill">
                  {pickupAddress ? (
                    <>
                      <Typography className="pickboxtitle ff_Cabin">
                        {pickupAddress.alias}
                      </Typography>
                      <Typography className="pickboxcon ff_Cabin">
                        {pickupAddress.address}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography className="pickboxtitle ff_Cabin">
                        Where to pick???
                      </Typography>
                      <Typography className="pickboxcon ff_Cabin">
                        The place of pickup
                      </Typography>
                    </>
                  )}
                </Box>
                <img src={rarrow} alt="location" className="pickboxicon" />
              </Box>
            </Link>
            <Typography className="ppppaddress ff_Cabin">
              Drop Address
            </Typography>
            <Link to={`${displayy === "upperlayerone" ? "/address/Drop" : ""}`}>
              <Box className="pickbox">
                <img src={flag} alt="location" className="pickboxloc" />
                <Box className="flex-fill">
                  {dropAddress ? (
                    <>
                      <Typography className="pickboxtitle ff_Cabin">
                        {dropAddress.alias}
                      </Typography>
                      <Typography className="pickboxcon ff_Cabin">
                        {dropAddress.address}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography className="pickboxtitle ff_Cabin">
                        Where to go???
                      </Typography>
                      <Typography className="pickboxcon ff_Cabin">
                        The place of drop
                      </Typography>
                    </>
                  )}
                </Box>
                <img src={rarrow} alt="location" className="pickboxicon" />
              </Box>
            </Link>
          </Box>
          <Box className="ullower">
            <Link to={`${displayy === "upperlayerone" ? instructionpath : ""}`}>
              <Box className="pickbox pickboxx">
                {instructionAdded ? (
                  <img
                    src={instructionAdded.itemImg}
                    alt="location"
                    className="pickboxloc"
                  />
                ) : (
                  <img src={inst} alt="location" className="pickboxloc" />
                )}
                {instructionAdded ? (
                  <Box className="flex-fill">
                    <Typography className="pickboxtitle ff_Cabin">
                      {instructionAdded.msg}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="pickboxcon ff_Cabin">
                        {instructionAdded.name}
                      </Typography>
                      {instructionAdded.fragile ? (
                        <img
                          src={fraigle1}
                          alt="location"
                          className=""
                          style={{ height: "16px" }}
                        />
                      ) : (
                        <img
                          src={fraigle1Disabled}
                          alt="location"
                          className=""
                          style={{ height: "16px" }}
                        />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box className="flex-fill">
                    <Typography className="pickboxtitle ff_Cabin">
                      Add instructions
                    </Typography>
                    <Typography className="pickboxcon ff_Cabin">
                      Add a detailed description to the order
                    </Typography>
                  </Box>
                )}
                <img src={rarrow} alt="location" className="pickboxicon" />
              </Box>
            </Link>
            <Box className="booknowbox">
              <div
                className={
                  bookingButtonClass
                    ? "booknowbuttonnsuccess flex-fill"
                    : "booknowbuttonn flex-fill"
                }
                onClick={() => handleClickBookNow()}
              >
                <Box className="ff_Cabin">Book Now</Box>
              </div>

              {/* <div
                className={
                  bookingButtonClass
                    ? "booklaterbuttonnsuccess flex-fill"
                    : "booklaterbuttonn flex-fill"
                }
              >
                <div className={bookingButtonClass ? "date-time1" : "date-time"}>
                  <input
                    disabled={!bookingButtonClass}
                    className="customized-date"
                    type="datetime-local"
                    min={new Date().toISOString().substring(0, 16)}
                    max={new Date(new Date().setDate(new Date().getDate() + 4)).toISOString().substring(0, 16)}
                    onChange={(e) => setScheduleBooking(e.target.value)}
                  />
                  Book Later
                </div>


              </div> */}
              <div
                className={
                  bookingButtonClass
                    ? "booklaterbuttonnsuccess flex-fill"
                    : "booklaterbuttonn flex-fill"
                }
              >
                <div
                  className={bookingButtonClass ? "date-time1" : "date-time"}
                  onClick={() => setOpenDateTime(true)}
                >
                  Book Later
                </div>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  // label="For mobile"
                  value={dateTimeValue}
                  onChange={(newValue) => {
                    setDateTimeValue(newValue);
                    setScheduleBooking(newValue);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField {...params} style={{ display: "none" }} />
                    );
                  }}
                  minDate={new Date().toISOString().substring(0, 10)}
                  maxDate={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0, 10)}
                  open={openDateTime}
                  onClose={() => setOpenDateTime(false)}
                  onAccept={() => handleClickBookNow()}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box className="mbottom"></Box>
          {/* <OtherBusinessType/> */}
        </Box>
        <Box
          className="lowerlayer"
          //onClick={slideHandler}
        >
          <Box className="shadow" />
          <Box className="hammenuu">
            {/* <MenuIcon className="ham"/> */}
            <Box></Box>
            <img src={pnbluelogo} alt="location" />
            <Link to="/notification">
              <img src={bellicon} alt="bell" />
              {/* <NotificationsNoneIcon className="ham" /> */}
            </Link>
          </Box>
          <Box className="aavtarlay">
            <div>
              <div>
                <Link to={`/profile/${userId}`}>
                  <>
                    {userData?.avatar ? (
                      <img
                        src={userData?.avatar}
                        alt="location"
                        className="aavtarr user-image"
                      />
                    ) : (
                      <img
                        src={aavtar}
                        alt="location"
                        className="aavtarr user-image"
                      />
                    )}
                  </>
                </Link>
                <div className="user-profile">
                  {userData ? (
                    <>
                      <Typography className="aavtarrcountry">
                        Hi&nbsp;{`${userData?.name?.split(" ")[0]}`}
                      </Typography>
                      <Typography
                        sx={{ marginBottom: "30px" }}
                        className="aavtarrcountry"
                      >
                        {userData?.mobile}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      sx={{ marginBottom: "30px" }}
                      className="aavtarrcountry"
                    >
                      User not found
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </Box>
          {/* <Typography>{userInfo.mobile.slice(2, 12)}</Typography> */}
          <Box className="m-lay-content">
            <Box>
              <Box className={active} onClick={activeHandler}>
                <img src={myorder} alt="location" className="aavtarricon" />
                <Link to="/history/order">
                  {" "}
                  <Typography className="aavtarrorder">My orders</Typography>
                </Link>
              </Box>
            </Box>
            <Box>
              <Box className={activee} onClick={activeHandlerr}>
                <img src={DA} alt="location" className="aavtarricon" />
                <Link to="/address/Saved">
                  <Typography className="aavtarrorder">
                    Saved addresses
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Box>
              <Box className={activeee} onClick={activeHandlerrr}>
                <img src={PH} alt="location" className="aavtarricon" />
                <Link to="/history/payment">
                  <Typography className="aavtarrorder">
                    Payment history
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Box>
              <Box className={activeeee} onClick={activeHandlerrrr}>
                <img src={NOT} alt="location" className="aavtarricon" />
                <Link to="/notification">
                  {" "}
                  <Typography className="aavtarrorder">
                    Notifications
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Box
              className={activeeee}
              onClick={activeHandlerrrr}
              style={{ position: "absolute", bottom: "4%" }}
            >
              <img
                src={selectLocation}
                alt="location"
                className="aavtarricon"
              />
              <Link to="/city/add">
                {" "}
                <Typography className="aavtarrorder">
                  {cityName ? cityName : "No city"}
                </Typography>
              </Link>
            </Box>

            <Typography onClick={goToUserAccount} className="switch-business">
              Switch to Business account
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  if (!userData)
  {
    return <NinjaLoader />;
  }

  return (
    <Box className="parent">
      <Toaster toastOptions={{ duration: 4000 }} />
      {page}
    </Box>
  );
};

export default Dashboard;
