import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import OnDeliveyMap from "../../components/OnDeliveryMap";
import { Helmet } from "react-helmet";
import { LoadScript } from "@react-google-maps/api";
import { libraries, apiKey } from "../../config/map.config";
import NinjaLoader from "../../components/NinjaLoader";
import { Typography, Box, Button } from "@mui/material";
import Slider from "@mui/material/Slider";
import { Toaster } from "react-hot-toast";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import box from "../../assets/images/landmark_add.svg";
import avtar from "../../assets/images/aavtar.jpg";
import call_circle from "../../assets/images/call-circle.svg";
import msg_circle from "../../assets/images/message-circle.svg";
import ninja_head from "../../assets/images/ninja_head.png";
import share from "../../assets/images/Share.svg";
import { toast } from "react-hot-toast";
import axios from "../../config/axios.Config";
import PickupPoint from "../../assets/images/pickup-point.png";
import DropPoint from "../../assets/images/drop-point.png";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../config/firebase.Config";
import * as geolib from "geolib";
const OnDelivery = () => {
  const navigate = useNavigate();
  const [bookingDetails, setBookingDetails] = useState({});
  const [bookingnumber, setBookingNumber] = useState();
  const [pickupmob, setpickupmob] = useState();
  const [dropmob, setdropmob] = useState();
  const [pickupOtp, setPickupOtp] = useState();
  const [dropOtp, setDropOtp] = useState();
  const [ridername, setRiderName] = useState();
  const [riderph, SetRiderph] = useState();
  const [center, setCenter] = useState();
  const [markers, setMarkers] = useState([]);
  const [percentageOffset, setPercentageOffset] = useState(0);
  const [realtimeStage, setRealTimeStage] = useState("Pickup");
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {});
  }, []);

  const bookingId = localStorage.getItem("bookingId");
  // ${bookingId}
  // test booking id LF56gA58aTdZAHquEP68

  // const marks = [
  //   {
  //     value: 0,
  //     label: "Accepted",
  //   },
  //   {
  //     value: 100,
  //     label: "Picked Up",
  //   },
  //   {
  //     value: 200,
  //     label: "Destination",
  //   },
  // ];

  useEffect(() => {
    axios
      .get(`/bookings/get/bookingDetails/${bookingId}`)
      .then((res) => {
        const bookingsData = res.data.result;
        setBookingDetails(bookingsData);
        setBookingNumber(bookingsData?.bookingNumber);
        setpickupmob(bookingsData?.pickupDestinationObj?.mobile);
        setdropmob(bookingsData?.dropDestinationObj?.mobile);
        setPickupOtp(bookingsData?.tasksList[0]?.otp);
        setDropOtp(bookingsData?.tasksList[1]?.otp);
        setRiderName(bookingsData?.rider?.bankDetails?.name);
        SetRiderph(bookingsData?.rider?.mobile);
      })
      .catch((err) => {
        toast.error("Some error occured");
      });
  }, []);
  useEffect(() => {
    if (
      bookingDetails?.pickupDestinationObj &&
      bookingDetails?.dropDestinationObj &&
      bookingDetails?.acceptedCoordinates
    ) {
      if (realtimeStage === "Pickup") {
        setCenter({
          lat:
            (parseFloat(bookingDetails.acceptedCoordinates.latitude) +
              bookingDetails.pickupDestinationObj.latitude) /
            2,
          lng:
            (parseFloat(bookingDetails.acceptedCoordinates.longitude) +
              bookingDetails.pickupDestinationObj.longitude) /
            2,
        });

        setMarkers([
          {
            icon: PickupPoint,
            position: {
              lat: parseFloat(bookingDetails.acceptedCoordinates.latitude),
              lng: parseFloat(bookingDetails.acceptedCoordinates.longitude),
            },
            key: "pickup",
          },
          {
            icon: DropPoint,
            position: {
              lat: bookingDetails.pickupDestinationObj.latitude,
              lng: bookingDetails.pickupDestinationObj.longitude,
            },
            key: "drop",
          },
        ]);
      } else if (realtimeStage === "Drop") {
        setCenter({
          lat:
            (bookingDetails.pickupDestinationObj.latitude +
              bookingDetails.dropDestinationObj.latitude) /
            2,
          lng:
            (bookingDetails.pickupDestinationObj.longitude +
              bookingDetails.dropDestinationObj.longitude) /
            2,
        });

        setMarkers([
          {
            icon: PickupPoint,
            position: {
              lat: bookingDetails.pickupDestinationObj.latitude,
              lng: bookingDetails.pickupDestinationObj.longitude,
            },
            key: "pickup",
          },
          {
            icon: DropPoint,
            position: {
              lat: bookingDetails.dropDestinationObj.latitude,
              lng: bookingDetails.dropDestinationObj.longitude,
            },
            key: "drop",
          },
        ]);
      }
      // setCenter({
      //   lat:
      //     (bookingDetails.pickupDestinationObj.latitude +
      //       bookingDetails.dropDestinationObj.latitude) /
      //     2,
      //   lng:
      //     (bookingDetails.pickupDestinationObj.longitude +
      //       bookingDetails.dropDestinationObj.longitude) /
      //     2,
      // });
      // setMarkers([
      //   {
      //     icon: PickupPoint,
      //     position: {
      //       lat: bookingDetails.pickupDestinationObj.latitude,
      //       lng: bookingDetails.pickupDestinationObj.longitude,
      //     },
      //     key: "pickup",
      //   },
      //   {
      //     icon: DropPoint,
      //     position: {
      //       lat: bookingDetails.dropDestinationObj.latitude,
      //       lng: bookingDetails.dropDestinationObj.longitude,
      //     },
      //     key: "drop",
      //   },
      // ]);
    }
  }, [bookingDetails, realtimeStage]);
  console.log(bookingDetails, center, "$$$$$$$$$$$$$$");

  useEffect(() => {
    if (
      bookingDetails?.pickupDestinationObj &&
      bookingDetails?.dropDestinationObj &&
      bookingDetails?.acceptedCoordinates
    ) {
      onSnapshot(doc(db, "bookings", bookingId), (data) => {
        let currentStage = data.data().stageId;
        setRealTimeStage(data.data().stageId);
        onSnapshot(doc(db, "riders", bookingDetails.rider.id), (doc) => {
          if (currentStage === "Pickup") {
            let currentDistanceCovered = geolib.getPreciseDistance(
              {
                latitude: doc.data().latitude,
                longitude: doc.data().longitude,
              },
              {
                latitude: bookingDetails?.pickupDestinationObj?.latitude,
                longitude: bookingDetails?.pickupDestinationObj?.longitude,
              }
            );
            let liveDistanceCoveredInKm = currentDistanceCovered / 1000;
            let totalDistance =
              geolib.getPreciseDistance(
                {
                  latitude: parseFloat(
                    bookingDetails.acceptedCoordinates.latitude
                  ),
                  longitude: parseFloat(
                    bookingDetails.acceptedCoordinates.longitude
                  ),
                },
                {
                  latitude: bookingDetails?.pickupDestinationObj?.latitude,
                  longitude: bookingDetails?.pickupDestinationObj?.longitude,
                }
              ) / 1000;
            console.log(
              liveDistanceCoveredInKm,
              totalDistance,
              "&&&&&&&&&&&&&&&&&&&&&&&&&"
            );
            setPercentageOffset(
              ((totalDistance - liveDistanceCoveredInKm) / totalDistance) * 100
            );
          } else if (currentStage === "Drop") {
            let currentDistanceCovered = geolib.getPreciseDistance(
              {
                latitude: doc.data().latitude,
                longitude: doc.data().longitude,
              },
              {
                latitude: bookingDetails?.dropDestinationObj?.latitude,
                longitude: bookingDetails?.dropDestinationObj?.longitude,
              }
            );
            let liveDistanceCoveredInKm = currentDistanceCovered / 1000;
            let totalDistance =
              geolib.getPreciseDistance(
                {
                  latitude: parseFloat(
                    bookingDetails.pickupDestinationObj.latitude
                  ),
                  longitude: parseFloat(
                    bookingDetails.pickupDestinationObj.longitude
                  ),
                },
                {
                  latitude: bookingDetails?.dropDestinationObj?.latitude,
                  longitude: bookingDetails?.dropDestinationObj?.longitude,
                }
              ) / 1000;
            setPercentageOffset(
              ((totalDistance - liveDistanceCoveredInKm) / totalDistance) * 100
            );
          } else if (currentStage === "Completed") {
            navigate(`/orderDelivered/${bookingId}`);
          }
        });
      });
    }
  }, [bookingDetails]);

  return (
    <LoadScript
      googleMapsApiKey={apiKey}
      libraries={libraries}
      loadingElement={<NinjaLoader />}
    >
      <Box className="h-100 d-flex flex-column">
        <Helmet>
          <meta name="theme-color" content="#002236" />
        </Helmet>
        <Box className="header_notification">
          <Toaster toastOptions={{ duration: 4000 }} />
          <img src={back} alt="bell" onClick={() => navigate(-1)} />
          <Typography className="notname">On Delivery</Typography>
          <Link to="/notification">
            <img src={bellicon} alt="bell" />
          </Link>
        </Box>
        <Box className="header_offset d-flex flex-column justify-content-end flex-fill">
          <div className="fillMap">
            <OnDeliveyMap
              isDraggable={false}
              type="Distance"
              centerBetweenPoints={center}
              markers={markers}
              percentageOffset={percentageOffset}
            />
            {/* {bookingDetails?.pickupDestinationObj &&
            bookingDetails?.dropDestinationObj ? (
              <>
                <Marker
                  icon={PickupPoint}
                  position={{
                    lat: bookingDetails.pickupDestinationObj.latitude,
                    lng: bookingDetails.pickupDestinationObj.longitude,
                  }}
                />
                <Marker
                  icon={DropPoint}
                  position={{
                    lat: bookingDetails.dropDestinationObj.latitude,
                    lng: bookingDetails.dropDestinationObj.longitude,
                  }}
                />
              </>
            ) : null} */}
          </div>
          <Box className="openedBS">
            <Box className="d-flex align-items-center rider">
              <img src={avtar} alt="avtar" className="aavtarr user-image" />
              <div className="riderName flex-fill">
                <strong>{ridername}</strong>
                <div className="realTimeStage">{realtimeStage}</div>
                <b>Rating 4.58</b>
              </div>
              <div className="d-flex">
                <Button
                  style={{ marginRight: "4px" }}
                  href={"tel:+91" + riderph}
                >
                  <img src={call_circle} alt="avtar" className="" />
                </Button>
                <button style={{ marginLeft: "4px" }}>
                  <img src={msg_circle} alt="avtar" className="" />
                </button>
              </div>
            </Box>
            {/* <Box className="d-flex justify-content-end">
              <strong
                style={{
                  color: "#E3552E",
                  fontSize: "14px",
                  marginTop: "10px",
                  width: "88px",
                  textAlign: "center",
                }}
              >
                Resend
              </strong>
            </Box> */}

            {/* <Box style={{ marginTop: "16px" }}>
                            <Typography className="workingdays">Give tipp</Typography>
                            <Box className="bs-working" style={{ gridTemplateRows: 'auto' }}>
                                <label className="work-container tipp" for='50'>
                                    <input id="50" type="radio" name="tipp" value="50" />
                                    <span className='mark'>₹50</span>
                                </label>
                                <label className="work-container tipp" for='100'>
                                    <input id="100" type="radio" name="tipp" value="100" />
                                    <span className='mark'>₹100</span>
                                </label>
                                <label className="work-container tipp" for='150'>
                                    <input id="150" type="radio" name="tipp" value="150" />
                                    <span className='mark'>₹150</span>
                                </label>
                                <label className="work-container tipp" for='200'>
                                    <input id="200" type="radio" name="tipp" value="200" />
                                    <span className='mark'>₹200</span>
                                </label>
                            </Box>
                        </Box> */}

            <Box
              className="imginputss"
              style={{ background: "#F9F9F9", border: "0" }}
            >
              <img src={box} alt="pnuser" />
              <div className="inputHolder" style={{ height: "43px" }}>
                <div className="singleLineText">{bookingnumber}</div>
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                    transform: "none",
                  }}
                >
                  Order number
                </label>
              </div>
              <Typography className="changeName">Show Details</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "40px",
              }}
            >
              <Box
                className="accepted"
                sx={{
                  minHeight: "10px",
                  width: "10px",
                  background: "#E3552E",
                  borderRadius: "50%",
                }}
              ></Box>

              <Slider
                sx={{
                  '& input[type="range"]': {
                    WebkitAppearance: "slider-vertical",
                  },
                  ".MuiSlider-rail": {
                    backgroundColor: "#D9D9D9",
                  },
                  ".MuiSlider-track": {
                    backgroundColor: "#E3552E",
                  },
                  ".MuiSlider-thumb": {
                    width: "45px",
                    height: "45px",
                    background: `url(${ninja_head}) no-repeat center / contain`,
                    boxShadow: "none",
                  },
                  ".MuiSlider-mark": {
                    display: "none",
                  },
                  "	.MuiSlider-markLabel": {
                    background: "#FCEEEA",
                    color: "#E3552E",
                    transform: "rotate(180deg)",
                    padding: "5px",
                    fontSize: "12px",
                    borderRadius: "15px",
                    minWidth: "60px",
                    textAlign: "center",
                    left: "-15px",
                  },
                  ".MuiSlider-markLabel:empty": {
                    display: "none",
                  },
                  ".MuiSlider-thumb:before": {
                    content: `'On Time'`,
                    width: "60px",
                    boxShadow: "none",
                    color: "#E3552E",
                    background: "#FCEEEA",
                    padding: "5px 10px",
                    fontSize: "12px",
                    borderRadius: "40px",
                    textAlign: "center",
                    height: "auto",
                    bottom: "-25px",
                  },
                  borderRadius: "0px",
                  marginRight: "0px",
                }}
                // marks={marks}
                size="small"
                min={0}
                max={200}
                value={
                  realtimeStage === "Pickup"
                    ? percentageOffset
                    : percentageOffset + 100
                }
                valueLabelDisplay="auto"
              />
              <Box
                className="picked"
                sx={{
                  minHeight: "10px",
                  width: "10px",
                }}
              ></Box>
              <Box
                className="dropped"
                sx={{
                  height: "10px",
                  width: "10px",
                  background: "#002236",
                  borderRadius: "2px",
                }}
              ></Box>
            </Box>

            <Box
              className="imginputss"
              style={{ background: "#F9F9F9", border: "0" }}
            >
              <div
                className="inputHolder"
                style={{ height: "43px", marginLeft: "0" }}
              >
                <div className="singleLineText">{pickupmob}</div>
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                    transform: "none",
                  }}
                >
                  Phone Number
                </label>
              </div>
              <div
                className="inputHolder"
                style={{
                  height: "43px",
                  marginLeft: "auto",
                  width: "100px",
                  flex: "none",
                }}
              >
                <div className="singleLineText">{pickupOtp}</div>
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                    transform: "none",
                  }}
                >
                  Pickup OTP
                </label>
              </div>
              <img src={share} alt="pnuser" />
            </Box>
            <Box
              className="imginputss"
              style={{ background: "#F9F9F9", border: "0" }}
            >
              <div
                className="inputHolder"
                style={{ height: "43px", marginLeft: "0" }}
              >
                <div className="singleLineText">{dropmob}</div>
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                    transform: "none",
                  }}
                >
                  Phone Number
                </label>
              </div>
              <div
                className="inputHolder"
                style={{
                  height: "43px",
                  marginLeft: "auto",
                  width: "100px",
                  flex: "none",
                }}
              >
                <div className="singleLineText">{dropOtp}</div>
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                    transform: "none",
                  }}
                >
                  Drop OTP
                </label>
              </div>
              <img src={share} alt="pnuser" />
            </Box>
          </Box>
        </Box>
      </Box>
    </LoadScript>
  );
};

export default OnDelivery;
