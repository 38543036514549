import React, { useState, useMemo, useEffect, useReducer } from "react";
// import {getUser} from '../store/store'
import axios from "./axios.Config";

import reducer from "../reducers/userReducer";
export const MainContext = React.createContext();

const initialState = {
  loading: false,
  dashboard: false,
  isError: false,
  userInfo: null,
  userId: null,
  mobile: null,
  isError: false,
  name: null,
};

export default function MainContextWrapper({ children, value }) {
  const [globalState, setGlobalState] = useState(value);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pickupDetails, setPickupDetails] = useState(null);
  const [pickupLocation, setPickupLocation] = useState(null);
  const [dropLocation, setDropLocation] = useState(null);
  const [businessMapMarkerPosition, setBusinessMapMarkerPosition] = useState(null);
  const [businessAddDetails, setBusinessAddDetails] = useState(null);
  const [businessNIDetails, setBusinessNIDetails] = useState(null);
  const [dropDetails, setDropDetails] = useState(null);
  const [instructionDetails, setInstructionDetails] = useState(null);
  const [editAddress, setEditAddress] = useState(null);
  const [couponDetails, setCouponDetails] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [newAddressList , setNewAddressList] = useState ([]);
  const [selectedPackageType, setSelectedPackageType] = useState("Your");
  const [itemImage , setItemImage] =useState();
  const [cityId , setCityId] = useState(localStorage.getItem("cityId"))
  const [userPreference , setUserPreference] = useState(localStorage.getItem("businessPreference"))
  const [cityName , setCityName] = useState(localStorage.getItem("cityName"))

  const getUser = async () => {
    const token = localStorage.getItem("token");
    dispatch({ type: "SET_LOADING" });

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const userData = await axios.get("/users/get/user");
      console.log(userData.data);
      dispatch({ type: "USER_DATA", payload: userData.data });
    } catch (err) {
      dispatch({ type: "API_ERROR" });
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  const payLoad = useMemo(() => {
    return { ...globalState, setGlobalState };
  });
  return (
    <MainContext.Provider
      value={{
        payLoad,
        ...state,
        getUser,
        setPickupDetails,
        setBusinessAddDetails,
        setBusinessNIDetails,
        setDropDetails,
        setInstructionDetails,
        setEditAddress,
        pickupDetails,
        businessAddDetails,
        businessNIDetails,
        dropDetails,
        instructionDetails,
        editAddress,
        couponDetails,
        setCouponDetails,
        dispatch,
        setPickupLocation,
        pickupLocation,
        setDropLocation,
        dropLocation,
        setBusinessMapMarkerPosition,
        businessMapMarkerPosition,
        selectedAddress,
        setSelectedAddress,
        newAddressList,
        setNewAddressList,
        selectedPackageType,
        setSelectedPackageType,
        setItemImage,
        itemImage,
        cityId,
        setCityId,
        userPreference,
        setUserPreference,
        cityName,
        setCityName
      }}
    >
      {children}
    </MainContext.Provider>
  );
}
