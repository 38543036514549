import React, { useState, useEffect, useContext } from "react";
import "../../assets/css/instruction.css";
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../config/axios.Config";
import { MainContext } from "../../config/context.Config";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { toast, Toaster } from "react-hot-toast";
import fraigle from "../../assets/images/fraigle.svg";
import delimgg from "../../assets/images/deleteIcon.svg";
import NotificationIcon from "../../assets/images/NotificationIcon.svg";
import ArrowLeftIcon from "../../assets/images/ArrowLeftIcon.svg";
import fragile_warn from "../../assets/images/illegal-items.svg";
import deliveryboy from "../../assets/images/delivery-boy.svg";
import box from "../../assets/images/landmark_add.svg";
import voicerecord from "../../assets/images/voicerecord.svg";
const Instruction = () => {
  const { setInstructionDetails, instructionDetails , setSelectedPackageType , selectedPackageType , setItemImage , itemImage} = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImage1, setSelectedImage1] = useState();
  const [selectedImagee, setSelectedImagee] = useState();
  const [selectedImagee1, setSelectedImagee1] = useState();
  const [selectedImagee2, setSelectedImagee2] = useState();
  const [selectedImagee3, setSelectedImagee3] = useState();
  const [frazile, setFrazile] = useState();
  const [packageType, setPackageType] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [messageBox, setMessageBox] = useState();
  const [packageTypesIds, setPackageTypesIds] = useState();
  const [packageTypeClassName, setPackageTypeClassName] = useState(true);
  const [packageId, setPackageId] = useState();
  const [msg, setMsg] = useState();
  const [active, setActive] = useState();
  //api calling for pakage type
  const locationPath = useLocation();
  const type = locationPath.pathname.split("/")[2];
  const items = Array.from({ length: 8 });
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const slug = useQuery().get("businessUrl");
  // common useEffect
  useEffect(() => {
    if (type === "Edit") {
      setMsg("Edited");
    } else {
      setMsg("Added");
    }
  }, []);

  useEffect(() => {
    if (slug) {
      axios
        .get(`/business/get/businessByUrl/${slug}`)
        .then((res) => {
          setPackageTypesIds(res.data.result.packageTypeIds);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [slug]);

  useEffect(() => {
    if (slug) {
      axios
        .post(`/packageTypes/get/ByIds`, {
          ids: packageTypesIds,
        })
        .then((res) => {
          setPackageType(res.data.result);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(`/packageTypes/get`)
        .then((res) => {
          console.log(res.data.result);
          setPackageType(res.data.result);
        })
        .catch((err) => console.log(err));
    }
  }, [slug, packageTypesIds]);

  console.log(instructionDetails);

  useEffect(() => {
    if (instructionDetails) {
      setMessageBox(instructionDetails.message);
      setFrazile(instructionDetails.fragile);
      setPackageId(instructionDetails.id);
      setSelectedPackage(instructionDetails.id)
    }
  }, [instructionDetails]);

  const instruction = {
    id: selectedPackage,
    message: messageBox,
    fragile: frazile,
    itemImg:itemImage,
    name:selectedPackageType,
    msg: `Instruction ${msg} successfully`,
  };
  console.log(loading);
  // set instruction details in context
  const updateInstruction = async () => {
    if (slug) {
      if (selectedPackage == null) {
        toast.error("Please select a package type");
      } else {
        setInstructionDetails(instruction);
        navigate(`/businessorder?businessUrl=${slug}`);
      }
    } else {
      if (selectedPackage == null) {
        toast.error("Please select a package type");
      } else {
        setInstructionDetails(instruction);
        navigate("/dashboard");
      }
    }
  };
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const imageChange1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage1(e.target.files[0]);
    }
  };
  const imageChangee = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImagee(e.target.files[0]);
    }
  };
  const imageChangee1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImagee1(e.target.files[0]);
    }
  };
  const imageChangee2 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImagee2(e.target.files[0]);
    }
  };
  const imageChangee3 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImagee3(e.target.files[0]);
    }
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage();
  };
  const removeSelectedImage1 = () => {
    setSelectedImage1();
  };
  const removeSelectedImagee = () => {
    setSelectedImagee();
  };
  const removeSelectedImagee1 = () => {
    setSelectedImagee1();
  };
  const removeSelectedImagee2 = () => {
    setSelectedImagee2();
  };
  const removeSelectedImagee3 = () => {
    setSelectedImagee3();
  };
  const navigate = useNavigate();

  // const abcd = () => {
  //     setActive(data);
  // }
  return (
    <Box className="h-100">
      <Helmet>
        <meta name="theme-color" content="#002236" />
      </Helmet>
      <Toaster toastOptions={{ duration: 4000 }} />
      <Box className="header_notification">
        <img
          src={ArrowLeftIcon}
          onClick={() => navigate(-1)}
          alt="Arrow Left Icon"
        />
        <Typography sx={{ fontWeight: "600 !important" }} className="notname">
          {type} Instruction
        </Typography>
        <Link to="/notification">
          <img src={NotificationIcon} alt="notifications" />
        </Link>
      </Box>
      <Box className="header_offset d-flex flex-column h-100">
        <div className="flex-fill" style={{overflow: 'auto'}}>
        <Box className="toplay">
          <Typography className="i-package">Package Type</Typography>
        </Box>
        <Swiper
          spaceBetween={10} resistanceRatio={0}
          breakpoints={{
            576: {
              width: 576,
              slidesPerView: 4.2,
            },
            768: {
              width: 768,
              slidesPerView: 5.5,
            },
          }}
          slidesPerView={4.2}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          className="i-swipe-package hor_space w-100"
        >
          {packageType.map((data) => {
            return (
              <SwiperSlide className="i-swiper-itemss" key={data.id}>
                <Box
                  className={`i-package-imgg-lay ${(active || packageId) == data.id && "activeee"
                    }`}
                  onClick={() => {
                    setActive(data.id);
                    setSelectedPackage(data.id);
                    setSelectedPackageType(data.type);
                    setItemImage(data.icon);
                  }}
                >
                  <img
                    src={data.icon}
                    alt="pnlogo"
                    className="i-package-imgg"
                  />
                </Box>
                <Typography className="i-package-item-name">
                  {data.type}
                </Typography>
              </SwiperSlide>
            );
          })}

          {packageType.length === 0 ? (
            <>
              {items.map(() => (
                <SwiperSlide className="i-swiper-itemss">
                  <Box className="i-package-imgg-lay content-loader"></Box>
                  <Typography className="i-package-item-name pkg_name_loader content-loader"></Typography>
                </SwiperSlide>
              ))}
            </>
          ) : (
            <></>
          )}
        </Swiper>

        <Box className="toplay">
          <textarea
            id="filled-multiline-static"
            placeholder={`Instructions for ${selectedPackageType} delivery`}
            rows="6"
            cols="50"
            className="textarea-instruction"
            value={messageBox}
            onChange={(e) => setMessageBox(e.target.value)}
          ></textarea>
        </Box>
        <Box className="toplay">
          <Box className="i-df">
            <Box className="i-df">
              <img src={fraigle} alt="pnlogo" className="i-df-image" />
              <Typography className="i-fragile">Fragile</Typography>
            </Box>
            {/* <FormControlLabel
                                sx={{
                                    display: 'block',
                                }}
                                control={
                                    <Switch
                                       checked={frazile ? frazile : loading}
                                        onChange={() => { setLoading(!loading) }}
                                        name="loading"
                                        color="primary"

                                    />
                                }
                            /> */}
            <label style={{ marginBottom: 0 }} className="switch">
              <input
                type="checkbox"
                name="loading"
                onChange={() => {
                  setFrazile(!frazile);
                }}
                checked={frazile}
              />
              <span className="slider round"></span>
            </label>
          </Box>
        </Box>
        <Box className="i-mb"></Box>

        <Box
          className="hor_space"
          style={{ marginTop: "16px", marginBottom: "16px" }}
        >
          <Typography className="workingdays">
            Things to keep in mind
          </Typography>
          <Box
            className="d-flex childdesign"
            style={{
              marginBottom: "0",
              borderRadius: "16px 16px 0 0",
              marginTop: "16px",
            }}
          >
            <img
              src={deliveryboy}
              alt=""
              className="locicon"
              style={{ height: "32px" }}
            />
            <Typography className="" style={{ fontSize: "14px" }}>
              Packages should be convinient to be carried on a two wheeler.
            </Typography>
          </Box>
          <Box
            className="d-flex childdesign"
            style={{ marginBottom: "0", borderRadius: "0" }}
          >
            <img
              src={box}
              alt=""
              className="locicon"
              style={{ height: "32px" }}
            />
            <Typography className="" style={{ fontSize: "14px" }}>
              Keep the items packed and ready for pick up.
            </Typography>
          </Box>
          <Box
            className="d-flex childdesign"
            style={{ marginBottom: "0", borderRadius: "0 0 16px 16px" }}
          >
            <img
              src={fragile_warn}
              alt=""
              className="locicon"
              style={{ height: "32px" }}
            />
            <Typography className="" style={{ fontSize: "14px" }}>
              Avoid sending illegal, high value items.
            </Typography>
          </Box>
        </Box>
        </div>
        {/* <Box className="phorizontal">
                        <Typography sx={{marginTop:3}} className='i-package'>Add photo instructions</Typography>

                        <Box className="i-capture-box">

                            <Box className="i-main-boxx">
                                <input
                                    accept="image/*"
                                    type="file"
                                    onChange={imageChange}
                                    className="i-imagee-inputt"
                                />
                                {selectedImage && (
                                    <Box className='i-imgg-resize'>
                                        <img
                                            src={URL.createObjectURL(selectedImage)}
                                            alt="Thumb"
                                            className='i-imgg-resizee'
                                        />
                                        <img src={delimgg} alt="pnlogo" className="i-del-iconn" onClick={removeSelectedImage} />
                                    </Box>
                                )}
                            </Box>
                            <Box className="i-main-boxx">
                                <input
                                    accept="image/*"
                                    type="file"
                                    onChange={imageChange1}
                                    className="i-imagee-inputt"
                                />
                                {selectedImage1 && (
                                    <Box className='i-imgg-resize'>
                                        <img
                                            src={URL.createObjectURL(selectedImage1)}
                                            alt="Thumb"
                                            className='i-imgg-resizee'
                                        />
                                        <img src={delimgg} alt="pnlogo" className="i-del-iconn" onClick={removeSelectedImage1} />
                                    </Box>
                                )}
                            </Box>
                            <Box className="i-main-boxx">
                                <input
                                    accept="image/*"
                                    type="file"
                                    onChange={imageChangee}
                                    className="i-imagee-inputt"
                                />
                                {selectedImagee && (
                                    <Box className='i-imgg-resize'>
                                        <img
                                            src={URL.createObjectURL(selectedImagee)}
                                            alt="Thumb"
                                            className='i-imgg-resizee'
                                        />
                                        <img src={delimgg} alt="pnlogo" className="i-del-iconn" onClick={removeSelectedImagee} />
                                    </Box>
                                )}
                            </Box>
                            <Box className="i-main-boxx">
                                <input
                                    accept="image/*"
                                    type="file"
                                    onChange={imageChangee1}
                                    className="i-imagee-inputt"
                                />
                                {selectedImagee1 && (
                                    <Box className='i-imgg-resize'>
                                        <img
                                            src={URL.createObjectURL(selectedImagee1)}
                                            alt="Thumb"
                                            className='i-imgg-resizee'
                                        />
                                        <img src={delimgg} alt="pnlogo" className="i-del-iconn" onClick={removeSelectedImagee1} />
                                    </Box>
                                )}
                            </Box>
                            <Box className="i-main-boxx">
                                <input
                                    accept="image/*"
                                    type="file"
                                    onChange={imageChangee2}
                                    className="i-imagee-inputt"
                                />
                                {selectedImagee2 && (
                                    <Box className='i-imgg-resize'>
                                        <img
                                            src={URL.createObjectURL(selectedImagee2)}
                                            alt="Thumb"
                                            className='i-imgg-resizee'
                                        />
                                        <img src={delimgg} alt="pnlogo" className="i-del-iconn" onClick={removeSelectedImagee2} />
                                    </Box>
                                )}
                            </Box>
                            <Box className="i-main-boxx">
                                <input
                                    accept="image/*"
                                    type="file"
                                    onChange={imageChangee3}
                                    className="i-imagee-inputt"
                                />
                                {selectedImagee3 && (
                                    <Box className='i-imgg-resize'>
                                        <img
                                            src={URL.createObjectURL(selectedImagee3)}
                                            alt="Thumb"
                                            className='i-imgg-resizee'
                                        />
                                        <img src={delimgg} alt="pnlogo" className="i-del-iconn" onClick={removeSelectedImagee3} />
                                    </Box>
                                )}
                            </Box>
                        </Box>

                    </Box> */}
        {/* <Box className="i-mb"></Box> */}
        <Box className="phorizontal mt-auto">
          {/* <Typography className='i-package'>Add voice instructions</Typography>
                        <Box className="i-voice-box-main">
                            <Box className="i-voice-box">
                                <img src={voicerecord} alt="pnlogo" className="i-v-add-inst" />
                            </Box>
                            <Typography className='i-add-voice'>Add New Voice Instruction</Typography>
                        </Box> */}

          <Button
            sx={{ textTransform: "none" }}
            className="add-button"
            onClick={updateInstruction}
          >
            Save Instruction
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Instruction;
