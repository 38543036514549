import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor'; // For image cropping
import defaultAvatar from '../../assets/images/aavtar.jpg'; // Default avatar image
import '../../assets/css/profile.css'; // Style sheet

const ProfileForm = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [preview, setPreview] = useState(null);

  const editorRef = useRef(null);
  const imageInputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreview(null);
    }
  };

  const handleImageClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      setPreview(canvas.toDataURL());
    }
  };

  return (
    <div className="profile-form-container">
      <form className="profile-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="image">Profile Photo:</label>
          <div className="image-container" onClick={handleImageClick}>
            {image ? (
              <AvatarEditor
                ref={editorRef}
                image={URL.createObjectURL(image)}
                width={250}
                height={250}
                border={0}
                borderRadius={125}
                color={[255, 255, 255, 0.6]} // Transparent background
                scale={1}
                rotate={0}
                onImageReady={() => setEditor(editorRef.current)}
              />
            ) : (
              <img src={defaultAvatar} alt="Default Avatar" />
            )}
          </div>
          {preview && <img src={preview} alt="Preview" />}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={imageInputRef}
            style={{ display: 'none' }}
          />
        </div>
        {image && (
          <button type="button" onClick={handleSave}>
            Save
          </button>
        )}
      </form>
    </div>
  );
};

export default ProfileForm;
// 


