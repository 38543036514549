import React from 'react';
import ReactDom from 'react-dom/client';
import App from './app';



const root = ReactDom.createRoot(document.getElementById("root"));
root.render(<App />);
if ("serviceWorker" in navigator)
{
    navigator.serviceWorker.register("service-worker.js");
}
