import React, { useState, useEffect, useContext, useRef } from "react";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import axios from "../../config/axios.Config";
import "../../assets/css/Business/businessorder.css";
import { GoogleMap } from "@react-google-maps/api";
import { Link, useNavigate, useLocation } from "react-router-dom";
import NinjaLoader from "../../components/NinjaLoader";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { libraries, apiKey } from "../../config/map.config";
import Map from "../../components/Map";
// mui imports start
import NotificationIcon from "../../assets/images/NotificationIcon.svg";
import ArrowLeftIcon from "../../assets/images/ArrowLeftIcon.svg";
import rarrow from "../../assets/images/greaterthanIcon.svg";
import arrowClockwise from "../../assets/images/arrow-counter-clockwise.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Typography, Box, Button } from "@mui/material";
// mui import end
// image import start
import coupon from "../../assets/images/business/coupon.svg";
import mapp from "../../assets/images/business/gmap.jpg";
import markerIcon from "../../assets/images/round-pushpin.svg";
import wwhatsapp from "../../assets/images/business/whatsappicon.png";
import location from "../../assets/images/locationIcon.svg";
import call from "../../assets/images/callicon.svg";
import email from "../../assets/images/business/email.svg";
import flag from "../../assets/images/business/flag.svg";
import invoice from "../../assets/images/business/invoice.svg";
import inst from "../../assets/images/business/inst.svg";
import ReactWhatsapp from "react-whatsapp";
import { MainContext } from "../../config/context.Config";
import PaymentDetails from "../customer/PaymentDetail";
import { Helmet } from "react-helmet";
// image import end
const Businessorder = () =>
{
  const navigate = useNavigate();
  const {
    setInstructionDetails,
    instructionDetails,
    setPickupLocation,
    pickupLocation,
    setDropLocation,
    businessAddDetails,
    setBusinessAddDetails,
    dropLocation,
  } = useContext(MainContext);
  const [dateTimeValue, setDateTimeValue] = useState(dayjs(new Date().toISOString()));
  const [openDateTime, setOpenDateTime] = useState(false);
  const [instructionAdded, setInstructionAdded] = useState(instructionDetails);
  const [pickAddress, setPickAddress] = useState();
  const [businessInfo, setBusinessInfo] = useState(null);
  const [dropAddress, setDropAddress] = useState();
  // const[searchResult,setSearchResult]=useState();
  const [instruction, setInstruction] = useState();
  const [mapMarkerPosition, setmapMarkerPosition] = useState();
  const [bookingButtonClass, setBookingButtonClass] = useState(false);
  const [scheduleBooking, setScheduleBooking] = useState();
  const [address, setAddress] = useState(pickupLocation || "");
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const mapRef = useRef(null);
  const [center, setCenter] = useState();
  const handleMapLoad = (map) =>
  {
    mapRef.current = map;
  };
  useEffect(() =>
  {
    setPickAddress(sessionStorage.getItem("pickupAddress"));
    setDropAddress(sessionStorage.getItem("dropAddress"));
    setInstruction(sessionStorage.getItem("instruction"));
  }, []);
  useEffect(() =>
  {
    setPickupLocation(address);
  }, [address]);

  function useQuery()
  {
    return new URLSearchParams(useLocation().search);
  }
  const slug = useQuery().get("businessUrl");

  const locationPath = useLocation();

  useEffect(() =>
  {
    if (businessInfo && businessAddDetails && instructionAdded)
    {
      setBookingButtonClass(true);
    }
  }, [businessInfo, businessAddDetails, instructionAdded]);

  useEffect(() =>
  {
    axios
      .get(`/business/get/businessByUrl/${slug}`)
      .then((res) =>
      {
        setBusinessInfo(res.data.result);
        setCenter({
          lat: parseFloat(res.data.result?.addressObj?.latitude),
          lng: parseFloat(res.data.result?.addressObj?.longitude),
        });
      })
      .catch((err) =>
      {
        console.log(err);
      });
  }, [slug]);

  console.log("llllllllllll", parseFloat(businessInfo?.latitude));
  console.log("llllllllllll", center);

  // function onPlaceChanged() {
  //   if (searchResult != null) {
  //     const place = searchResult.getPlace();
  //     setmapMarkerPosition({
  //       lat: place.geometry.location.lat(),
  //       lng: place.geometry.location.lng(),
  //     });
  //   } else {
  //     alert("Please enter text");
  //   }
  // }

  // function onLoad(autocomplete) {
  //   setSearchResult(autocomplete);
  // }

  // let state = {
  //   pickupDetails: businessInfo,
  //   dropDetails: businessAddDetails,
  //   instructionDetails: instructionAdded,
  //   scheduleBooking: scheduleBooking,
  // };
  // sessionStorage.setItem("paymentDetails", JSON.stringify(state));

  const handleClearBusinessData = () =>
  {
    setBusinessAddDetails(null);
    setInstructionDetails(null);
    setInstructionAdded(null);
    setScheduleBooking(null);
    setBookingButtonClass(false);
    sessionStorage.removeItem("paymentDetails");
  };

  const handleClickBookNow = () =>
  {
    if (bookingButtonClass)
    {
      let state = {
        pickupDetails: businessInfo,
        dropDetails: businessAddDetails,
        instructionDetails: instructionAdded,
        scheduleBooking: scheduleBooking || JSON.parse(sessionStorage.getItem("paymentDetails"))?.scheduleBooking,
        isBusiness: true,
        businessSlug: slug
      };
      sessionStorage.setItem("paymentDetails", JSON.stringify(state));
      navigate("/payments");
    } else
    {
      return null;
    }
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={apiKey}
        libraries={libraries}
        loadingElement={<NinjaLoader />}
      >
        <Box className="header_notification">
          <Helmet>
            <meta name="theme-color" content="#002236" />
          </Helmet>
          <img
            src={ArrowLeftIcon}
            onClick={() => navigate(-1)}
            alt="Arrow Left Icon"
          />
          <Typography className="notname">Get Your Order Picked Up</Typography>
          <Link to="/notification">
            <img src={NotificationIcon} alt="notifications" />
          </Link>
        </Box>

        <Box className="main">
          {/* <Box className="backgroundmap" style={{ backgroundImage: `url(${mapp})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover' }} /> */}

          <Box
            style={{
              position: "relative",
              top: "55px",
              height: "50%",
              width: "100vw",
            }}
          >
            {/* <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
          <input
            type="text"
            placeholder="Search"
            className="search-location"
          />
        </Autocomplete> */}
            {/* <Map
              setmapMarkerPosition={setmapMarkerPosition}
              setAddress={setAddress}
              mapMarkerPosition={mapMarkerPosition}
              type={"business"}
            /> */}
            <div className="map-container">
              <div className={`marker`}>
                <img
                  src={markerIcon}
                  width={40}
                  height={40}
                  alt="Marker Icon"
                />
              </div>
              <GoogleMap
                onLoad={handleMapLoad}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
                ref={mapRef}
                gestureHandling="greedy"
                options={{
                  draggable: false,
                  disableDefaultUI: true,
                  clickableIcons: false,
                }}
              ></GoogleMap>
            </div>
          </Box>
          <div className="business-order-container">
            <Box className="bottomdesign">
              <Typography className="paddress">Pickup Adress</Typography>

              <Box className="dcontain">
                <img src={location} alt="location" className="locicon" />
                {businessInfo ? (
                  <Box>
                    <Typography className="locationhead">
                      {businessInfo?.name || businessInfo?.addressObj?.name}
                    </Typography>
                    <Typography className="locationtitle">
                      {businessInfo.addressObj.address}
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography className="locationhead">
                      Please Add Pick Address
                    </Typography>
                  </Box>
                )}
              </Box>
              <Button
                className="reactWhatsapp"
                href={"tel:+91" + businessInfo?.addressObj?.mobile}
              >
                <Box className="childdesignnnn">
                  <img src={call} alt="location" className="locicon" />
                  <Box className="flex-fill">
                    <Typography className="locationtitle">
                      Phone number
                    </Typography>
                    <Typography className="locationhead">
                      {businessInfo?.addressObj?.mobile}
                    </Typography>
                  </Box>
                  <img src={rarrow} alt="location" className="pickboxicon" />
                </Box>
              </Button>


              <ReactWhatsapp
                number={businessInfo?.whatsapp || ""}
                message={`Hi there, I would like to place an order via your Pickup Ninja business page.`}
                className="reactWhatsapp"
              >
                <Box className="childdesignnnn">
                  <img src={wwhatsapp} alt="location" className="locicon" />
                  <Box className="flex-fill" style={{ textAlign: 'left' }}>
                    <Typography className="locationtitle">
                      WhatsApp number
                    </Typography>
                    <Typography className="locationhead">
                      {businessInfo?.whatsapp}
                    </Typography>
                  </Box>
                  <img src={rarrow} alt="location" className="pickboxicon" />
                </Box>
              </ReactWhatsapp>

              <div onClick={handleClearBusinessData} className="d-flex justify-content-between">
                <Typography className="paddress">Drop Address</Typography>
                <img src={arrowClockwise} alt="location" className="pickboxicon" />
              </div>
              <Link to={`/address/Saved?businessUrl=${slug}`}>
                <Box className="childdesignnnn">
                  <img src={flag} alt="location" className="locicon" />
                  <Box className="flex-fill">
                    {businessAddDetails ? (
                      <>
                        <Typography className="pickboxtitle ff_Cabin">
                          {businessAddDetails.alias}
                        </Typography>
                        <Typography className="pickboxcon ff_Cabin">
                          {businessAddDetails.address}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography className="locationhead">
                          Where to go???
                        </Typography>
                        <Typography className="locationtitle">
                          The place of drop
                        </Typography>
                      </>
                    )}
                  </Box>
                  <img src={rarrow} alt="location" className="pickboxicon" />
                </Box>
              </Link>
              <Box className="borderbottomm" />
              <Link to={`/instruction/Create?businessUrl=${slug}`}>
                <Box className="childdesignnnn">
                  <img src={inst} alt="location" className="locicon" />
                  {instructionAdded ? (
                    <Box className="flex-fill">
                      <Typography className="pickboxtitle">
                        {instructionAdded.msg}
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <Box className="flex-fill">
                        <Typography className="locationhead">
                          Add instructions
                        </Typography>
                        <Typography className="locationtitle locationtitleee">
                          Add a detailed description to the order
                        </Typography>
                      </Box>
                    </>
                  )}
                  <img src={rarrow} alt="location" className="pickboxicon" />
                </Box>
              </Link>
              <Box className="booknowbox">
                <div
                  className={
                    bookingButtonClass
                      ? "booknowbuttonnsuccess flex-fill"
                      : "booknowbuttonn flex-fill"
                  }
                  onClick={() => handleClickBookNow()}
                >
                  <Box className="ff_Cabin">Book Now</Box>
                </div>

                {/* <div
                  className={
                    bookingButtonClass
                      ? "booklaterbuttonnsuccess flex-fill"
                      : "booklaterbuttonn flex-fill"
                  }
                >
                  <div className={bookingButtonClass ? "date-time1" : "date-time"}>
                    <input
                      disabled={!bookingButtonClass}
                      className="customized-date"
                      type="datetime-local"
                      min={new Date().toISOString().substring(0, 16)}
                      max={new Date(new Date().setDate(new Date().getDate() + 4)).toISOString().substring(0, 16)}
                      onChange={(e) => setScheduleBooking(e.target.value)}
                    />
                    Book Later
                  </div>
                </div> */}
                <div
                className={
                  bookingButtonClass
                    ? "booklaterbuttonnsuccess flex-fill"
                    : "booklaterbuttonn flex-fill"
                }
              >
                <div
                  className={bookingButtonClass ? "date-time1" : "date-time"}
                  onClick={() => setOpenDateTime(true)}
                >
                  Book Later
                </div>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  // label="For mobile"
                  value={dateTimeValue}
                  onChange={(newValue) => {
                    setDateTimeValue(newValue);
                    setScheduleBooking(newValue);
                  }}                 
                  renderInput={(params) => {
                    return (
                      <TextField {...params} style={{ display: "none" }} />
                    );
                  }}
                  minDate={new Date().toISOString().substring(0, 10)}
                  maxDate={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0, 10)}
                  open={openDateTime}
                  onClose={() => setOpenDateTime(false)}
                  onAccept={() => handleClickBookNow()}
                />
              </LocalizationProvider>
                
              </Box>
              <Box className="borderbottomm" />
              {/* {businessInfo && businessAddDetails && instructionAdded && (
                <Box className="phorizontal mt-auto">
                  <Button
                    sx={{ textTransform: "none" }}
                    className="add-button"
                    onClick={handleClearBusinessData}
                  >
                    Clear Data
                  </Button>
                </Box>
              )} */}
            </Box>

            {/* {businessInfo && businessAddDetails && instructionAdded && (
              <PaymentDetails business={slug} />
            )} */}
            {/* <Box className="phorizontal">
              <Box className="paymentslip">
                <Box className="paymentsliptop">
                  <img src={invoice} alt="location" className="invoice" />
                  <Box>
                    <Typography className="locationtitle">
                      Total Payment
                    </Typography>
                    <Typography className="locationhead">992,96 ₹</Typography>
                  </Box>
                </Box>
                <Typography className="paymentdetails">
                  Payment Details
                </Typography>
                <Typography className="bill">
                  <Typography className="bill">8 items</Typography>_ _ _ _ _ _ _
                  _ _ _ _ _ _ _ _ _
                  <Typography className="bill"> ₹742,96</Typography>
                </Typography>
                <Typography className="bill">
                  <Typography className="bill">Delivery, 10 km</Typography>_ _ _
                  _ _ _ _ _ _ _ _ _
                  <Typography className="bill"> ₹250,00</Typography>
                </Typography>
                <Typography className="bill">
                  <Typography className="billcost">Total cost</Typography>_ _ _
                  _ _ _ _ _ _ _ _ _ _ _ _
                  <Typography className="bill"> ₹742,96</Typography>
                </Typography>
                <Typography className="hidebill">Hide details</Typography>
              </Box>
              <Box className="childdesignnnn">
                <img src={coupon} alt="location" className="invoice" />
                <Box>
                  <Typography className="locationtitle">
                    Coupon Number
                  </Typography>
                  <Typography className="locationhead">
                    Apply your coupon
                  </Typography>
                </Box>
                <Link to="/coupon/add" className="applybuttonnnnn">
                  {" "}
                  <Button
                    onClick={() => navigate("/coupon/add")}
                    variant="contained"
                  >
                    Apply
                  </Button>
                </Link>
              </Box>
              <Link to="/menu">
                <Box className="proceedpayy">
                  <Typography className="tcost">
                    992,96 ₹
                    <Typography className="tcostt">
                      Approximate price
                    </Typography>
                  </Typography>
                  <Typography className="ppayment">Pay now</Typography>
                </Box>
              </Link>
              <Box className="mborderspace" />
            </Box> */}
          </div>
        </Box>
      </LoadScript>
    </>
  );
};

export default Businessorder;
