import React, { useState, useContext, useEffect } from "react";
import "../../assets/css/addAddress.css";
import axios from "../../config/axios.Config";
import { Helmet } from "react-helmet";
import Map from "../../components/Map";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { libraries, apiKey } from "../../config/map.config";
import { Toaster, toast } from "react-hot-toast";
import { MainContext } from "../../config/context.Config";
// images
//import * as Yup from "yup";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import cross from "../../assets/images/cross.png";
import location from "../../assets/images/loc_add.svg";
import call from "../../assets/images/phone_add.svg";
import user from "../../assets/images/user_add.svg";
import box from "../../assets/images/landmark_add.svg";
import NinjaLoader from "../../components/NinjaLoader";
// import { ErrorMessage, Form, Formik } from "formik";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import TextError from "../formik/TextError";
//import TextError from "../formik/TextError";
const AddAddress = () => {
  const navigate = useNavigate();
  const {
    userId,
    mobile: businessMobile,
    editAddress,
    setEditAddress,
    setPickupDetails,
    setDropDetails,
    setBusinessAddDetails,
    setBusinessNIDetails,
  } = useContext(MainContext);
  const [alias, setAlias] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [landMark, setLandMark] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState();
  const [mapMarkerPosition, setmapMarkerPosition] = useState();
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [fieldValidation, setFieldValidation] = useState({
    aliasVal: false,
    addressVal: false,
    mobileVal: false,
    nameVal: false,
  });
  const [saveButtonClicked, setSaveButtonClicked] = useState(null);
  // const [initialValues, setInitialValues] = useState({
  //   location: "",
  //   mobile: "",
  //   name: "",
  //   landmark: "",
  //   saveAs: "",
  // });
  const type = window.location.pathname.split("/")[2];

  // const validationSchema = Yup.object({
  //   location: Yup.string().trim().required("Enter Address required"),
  //   mobile: Yup.number()
  //     .typeError("Invalid phone number")
  //     .positive("Phone number must be positive")
  //     .integer("Phone number must be an integer")
  //     .required("Phone number is required")
  //     .test(
  //       "max-length",
  //       "Phone number must be exactly 10 digits",
  //       (value) => `${value}`.length === 10
  //     ),
  //   name: Yup.string().trim().required("Contact name is required"),
  //   saveAs: Yup.string().trim().required("Save As is required"),
  // });
  useEffect(() => {
    if (saveButtonClicked) {
      setFieldValidation((prevValidation) => ({
        ...prevValidation,
        addressVal: address === "",
        mobileVal: mobile === "",
        nameVal: name === "",
        aliasVal: alias === "",
      }));
    }
  }, [address, mobile, name, alias, saveButtonClicked]);
  useEffect(() => {
    if (editAddress) {
      setAlias(editAddress.alias);
      setAddress(editAddress.address);
      setMobile(editAddress.mobile);
      setName(editAddress.name);
      setLandMark(editAddress.landMark);
      setmapMarkerPosition({
        lat: editAddress.latitude,
        lng: editAddress.longitude,
      });
    }
  }, []);

  useEffect(() => {
    if (!editAddress) {
      navigator.geolocation.getCurrentPosition((position) => {
        setmapMarkerPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, [editAddress]);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const addressName = useQuery().get("addressType");
  const addressBusiness = useQuery().get("registerBusiness");
  const addressBusinessEdit = useQuery().get("businessEditAddress");
  const addressBusinessUrl = useQuery().get("businessUrl");

  const locationPath = useLocation();

  useEffect(() => {
    if (addressBusiness) {
      setMobile(businessMobile.substring(3));
    }
  }, [addressBusiness, businessMobile]);

  if (addressBusinessEdit || addressBusiness) {
    window.onpopstate = () => {
      navigate("/dashboard", {
        state: { switchAccount: true, displayy: "upperlayerone" },
      });
    };
  }

  const handelAddress = () => {
    setSaveButtonClicked(Date.now());

    if (address !== "" && mobile !== "" && name !== "") {
      let id;
      if (editAddress) {
        id = editAddress.id;
      }

      const data = {
        alias: alias || (addressBusiness ? "Business" : ""),
        name: name,
        mobile: mobile.toString(),
        longitude: mapMarkerPosition?.lng || 85.8245,
        latitude: mapMarkerPosition?.lat || 20.2961,
        address: address,
        landMark: landMark,
        userId: userId,
      };
      sessionStorage.setItem("registerBusiness", JSON.stringify(data));
      console.log(type);
      if (type === "Edit") {
        axios
          .put(`/destinations/update/${id}`, data)
          .then((res) => {
            toast.success("Address Edited successfully");
            if (addressName === "Pickup") {
              setPickupDetails(data);
            } else {
              setDropDetails(data);
            }
            navigate("/dashboard");
          })
          .catch((err) => {
            toast.error("Address unable to edit");
          });
      } else if (type === "Add") {
        if (addressBusiness) {
          toast.success("Address added successfully");
          setBusinessAddDetails(data);
          setBusinessNIDetails({
            busName: locationPath?.state?.buName,
            busImage: locationPath?.state?.buImage,
            businessPhone: mobile,
            mapMarkerPosition: locationPath?.state?.mapMarkerPosition,
          });
          return navigate("/dashboard", {
            state: { switchAccount: true, displayy: "upperlayerone" },
          });
        }
        setLoading(true);
        axios
          .post("/destinations/add", data)
          .then((res) => {
            setLoading(false);
            if (addressBusinessEdit) {
              toast.success("Address added successfully");
              setBusinessAddDetails({ ...data, id: res.data.result });
              return navigate(
                "/register/business?businessEditAddress=businessEditAddress",
                {
                  state: {
                    isEditing: true,
                    isAddressEditing: true,
                    businessPhone: mobile,
                  },
                }
              );
            } else if (addressBusinessUrl) {
              setBusinessAddDetails({ ...data, id: res.data.result });
              return navigate(
                `/businessorder?businessUrl=${addressBusinessUrl}`
              );
            } else {
              toast.success("Address added successfully");
              if (addressName === "Pickup") {
                setPickupDetails({ ...data, id: res.data.result });
              } else {
                setDropDetails({ ...data, id: res.data.result });
              }
              navigate("/dashboard");
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data);
          });
      }
    } else {
      setFieldValidation((prevValidation) => ({
        ...prevValidation,
        addressVal: address === "",
        mobileVal: mobile === "",
        nameVal: name === "",
        aliasVal: alias === "",
      }));
    }
  };

  const handleContainerClick = (e) => {
    if (e.target.classList.contains("ppaddress-bottom")) {
      return;
    } else if (e.target.classList.length === 0) {
      // setOpenBottomSheet(false);
    }
  };
  // set Address Edit
  const handlePlaceSelect2 = (place) => {
    setLandMark(place.formatted_address);
  };
  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      setmapMarkerPosition({
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      });
    } else {
      console.log("removed");
      alert("Please enter text");
    }
  }

  const handleMapAddress = () => {
    const mapInput = document.getElementById("map-address");
    return (mapInput.value = "");
  };

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  const handleBack = () => {
    if (editAddress) {
      setEditAddress(null);
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      {/* <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values) => handelAddress(values)}
      >
        {(formik) => {
          return ( */}
      {/* <Form> */}
      <LoadScript
        googleMapsApiKey={apiKey}
        libraries={libraries}
        loadingElement={<NinjaLoader />}
      >
        <div className="mainContainer" onClick={(e) => handleContainerClick(e)}>
          <Helmet>
            <meta name="theme-color" content="#002236" />
          </Helmet>
          <Box className="header_notification">
            <Toaster toastOptions={{ duration: 4000 }} />
            <img src={back} alt="bell" onClick={handleBack} />
            <Typography className="notname">{type} Address</Typography>
            <Link to="/notification">
              <img src={bellicon} alt="bell" />
            </Link>
          </Box>

          <Box className="main">
            <Box
              style={{
                position: "absolute",
                top: "0",
                height: "100%",
                width: "100%",
              }}
            >
              <Autocomplete
                onPlaceChanged={onPlaceChanged}
                onLoad={onLoad}
                className="searchBox"
              >
                <div className="input_wrap">
                  <input
                    id="map-address"
                    type="text"
                    placeholder="Search"
                    className="search-location"
                  />
                  <img onClick={handleMapAddress} src={cross} alt="cross" />
                </div>
              </Autocomplete>
              <Map
                setmapMarkerPosition={setmapMarkerPosition}
                setAddress={() => {}}
                mapMarkerPosition={mapMarkerPosition}
                type={type}
                isDraggable={true}
              />
            </Box>
            <div className="addAddressContainer">
              <Typography
                onClick={() => setOpenBottomSheet(true)}
                className="ppaddress-bottom"
              >
                {type} Address{" "}
              </Typography>
            </div>
            <BottomSheet
              defaultSnap={({ maxHeight }) => maxHeight / 2}
              snapPoints={({ minHeight }) => minHeight}
              expandOnContentDrag={true}
              open={openBottomSheet}
              // close={openBottomSheet}
              onDismiss={() => setOpenBottomSheet(false)}
            >
              <div className="addess-sheet" style={{ overflow: "auto" }}>
                <Box className="bottomdesign">
                  <Typography
                    className="ppaddress"
                    //onClick={() => setOpenBottomSheet(false)}
                  >
                    {type} Address{" "}
                  </Typography>
                  <form>
                    <Box className="childdesign imginputss flex-fill align-items-start">
                      <img src={location} alt="location" className="locicon" />
                      <div className="inputHolder" style={{ marginLeft: "0" }}>
                        <textarea
                          placeholder="&nbsp;"
                          className=""
                          type="text"
                          name="location"
                          rows={3}
                          value={address}
                          style={{
                            resize: "none",
                          }}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <label>Address</label>
                      </div>
                    </Box>
                    {fieldValidation.addressVal && (
                      <TextError msg="Address is Required" />
                    )}
                    <Box className="childdesign imginputss flex-fill">
                      <img src={call} alt="location" className="locicon" />
                      <div className="inputHolder" style={{ marginLeft: "0" }}>
                        <input
                          placeholder="&nbsp;"
                          type="tel"
                          maxLength={10}
                          name="mobile"
                          className=""
                          value={mobile}
                          onChange={(e) =>
                            setMobile(e.target.value.replace(/\D/, ""))
                          }
                          required
                        />
                        <label>Contact number</label>
                      </div>
                    </Box>
                    {fieldValidation.mobileVal && (
                      <TextError msg="Phone Number is Required" />
                    )}
                    <Box className="childdesign imginputss flex-fill">
                      <img src={user} alt="location" className="locicon" />
                      <div className="inputHolder" style={{ marginLeft: "0" }}>
                        <input
                          placeholder="&nbsp;"
                          className=""
                          type="text"
                          name="name"
                          value={name}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label>Contact name</label>
                      </div>
                    </Box>
                    {fieldValidation.nameVal && (
                      <TextError msg="Name is Required" />
                    )}
                    <Typography className="ppaddress">
                      Landmark<span>&nbsp;(Optional)</span>
                    </Typography>
                    <Box className="childdesign imginputss flex-fill">
                      <img
                        src={box}
                        alt="location"
                        className="locicon"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <div className="inputHolder" style={{ marginLeft: "0" }}>
                        <input
                          className=""
                          type="text"
                          name="landmark"
                          placeholder="&nbsp;"
                          value={landMark}
                          onChange={(e) => setLandMark(e.target.value)}
                        />
                        <label>Location</label>
                      </div>
                    </Box>
                    { !addressBusiness &&
                      <Box className="childdesign imginputss flex-fill">
                        <img src={user} alt="location" className="locicon" />
                        <div
                          className="inputHolder"
                          style={{ marginLeft: "0" }}
                        >
                          <input
                            placeholder="&nbsp;"
                            className=""
                            type="text"
                            name="name"
                            value={alias}
                            onChange={(e) => setAlias(e.target.value)}
                          />
                          <label>Save As</label>
                        </div>
                      </Box>
                    }{" "}
                    {(!addressBusiness && fieldValidation.aliasVal) && (
                      <TextError msg="Save As is Required" />
                    )}
                    <Button
                      variant="contained"
                      disableElevation
                      className={`continueebtnn ${loading ? "content-loader" : ""}`}
                      onClick={() => handelAddress()}
                      disabled={loading}
                    >
                      {type === "Edit" ? "Update" : "Add"} Address
                    </Button>
                    {/* </Link> */}
                  </form>
                  {/* <ErrorMessage name="saveAs" component={TextError} /> */}
                  {/* <Link to="/address/pickup"> */}{" "}
                  {/* <Button
                  sx={{ textTransform: "none", fontSize: 16 }}
                  variant="contained"
                  disableElevation
                  className="continueebtnn"
                  type="submit"
                >
                  {type} Address
                </Button> */}
                  {/* </Link> */}
                </Box>
              </div>
            </BottomSheet>
          </Box>
        </div>
      </LoadScript>
      {/* </Form>
          );
        }}
      </Formik> */}
    </div>
  );
};
export default AddAddress;
