import React, { useEffect, useState, useContext, memo } from "react";
import "../../assets/css/paymentDetail.css";
import { v4 as uuidv4 } from "uuid";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { unit, sin, cos, atan2, sqrt, lgamma, log } from "mathjs";
import axios from "../../config/axios.Config";
import { MainContext } from "../../config/context.Config";
import { Helmet } from "react-helmet";
// mui
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationIconNone from "@mui/icons-material/NotificationsNone";
import { Typography, Box, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiKey } from "../../config/map.config";
// images
import coupon from "../../assets/images/coupon.svg";
import clockIcon2 from "../../assets/images/clockIcon2.svg";
import invoice from "../../assets/images/invoiceIcon.svg";
import loader from "../../assets/images/loader.gif";
import logoIcon from "../../assets/images/appstore.png";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import * as geolib from "geolib";
import { toast } from "react-hot-toast";
import Moment from "react-moment";
import moment from "moment";
import { useRef } from "react";
import NinjaLoader from "../../components/NinjaLoader";
const GST_RATE = 0.00;
const PaymentDetails = () =>
{
  const {
    couponDetails,
    instructionDetails,
    pickupDetails,
    dropDetails,
    userId,
    mobile,
    cityId,
  } = useContext(MainContext);
  //const { state } = useLocation();
  const navigate = useNavigate();
  const locationPath = useLocation();
  // const [isBusiness, setBusiness] = useState(false);
  // const [business, setBusinessSlug] = useState();
  const [rateDetail, setRateDetail] = useState([]); //rate details from rate api
  const [pickupData, setPickupData] = useState();
  const [scheduleBooking, setScheduleBooking] = useState();
  const datepickerRef = useRef(null);
  const [dropData, setDropData] = useState();
  const [totalFee, setTotalFee] = useState(0);
  const [totalFeeDiscount, setTotalFeeDiscount] = useState(undefined);
  const [totalCost, setTotalCost] = useState(0);
  const [loaderObject, setLoaderObject] = useState(false);
  const [activeCoupon, setActiveCoupon] = useState(false);
  const [netTotalPayment, setNetTotalPayment] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [displaydistance, setDisplayDistance] = useState(null);
  const [totalprice, setTotalPrice] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [gstPercentage, setGstPercentage] = useState(0);
  const [orderId,setOrderId] = useState("")
  // const [lat1, setLat1] = useState(0);
  // const [lat2, setLat2] = useState(0);
  // const [lng1, setLng1] = useState(0);
  // const [lng2, setLng2] = useState(0);
  const [distance, setDistance] = useState(0);
  // const [paymentState, setPaymentState] = useState({});
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [baseprice, setBasePrice] = useState(0);
  //Rate Api

  // ========

  // useEffect(() => {
  //   setPaymentState(JSON.parse(sessionStorage.getItem("paymentDetails")));
  // }, []);
  let paymentState = JSON.parse(sessionStorage.getItem("paymentDetails"));
  const isBusiness = paymentState?.isBusiness;
  const business = paymentState?.businessSlug;
  // useEffect(() => {
  //   setBusiness(paymentState?.isBusiness);
  //   setBusinessSlug(paymentState?.businessSlug)
  // }, [paymentState?.isBusiness,paymentState?.businessSlug]);
  console.log("cccccccccccccccccccccccc", couponDetails);
  console.log(instructionDetails);
  console.log(dropDetails);
  console.log(userId);
  useEffect(() =>
  {
    axios
      .get("/rates/get")
      .then((res) =>
      {
        setRateDetail(res.data.result[0]);
      })
      .catch((err) =>
      {
        console.log(err);
      });
  }, []);
  // const degreesToRadians = (degrees) => {
  //   return degrees * (Math.PI / 180);
  // };

  const handleDatePickerImageClick = () =>
  {
    datepickerRef.current.showPicker();
  };

  useEffect(() =>
  {
    axios
      .get("/gst/get")
      .then((res) =>
      {
        const gst = res.data.result.gst / 100;
        setGstPercentage(gst);
      })
      .catch((err) =>
      {
        console.log(err);
      });
  }, []);

  useEffect(() =>
  {
    if (
      paymentState?.pickupDetails &&
      paymentState?.dropDetails &&
      paymentState?.instructionDetails
    )
    {
      // let config = {
      //   method: "get",
      //   url: `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${pickupDetails.latitude},${pickupDetails.longitude}&destinations=${dropDetails.latitude},${dropDetails.longitude}&key=${apiKey}`,
      //   headers: {},
      // };

      // axios(config)
      //   .then((res) => {
      //     if (res.data.status === "OK") {
      //       const route = res.data.routes[0];
      //       const leg = route.legs[0];
      //       const meters = leg.distance.value;
      //       const kilometers = meters / 1000;

      //       setDistance(kilometers.toFixed(2));
      //     } else {
      //       console.log("Error:", res.data.status);
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("Error:", error);
      //   });
      // let calculatedDistance =
      //   geolib.getPreciseDistance(
      //     {
      //       latitude: business
      //         ? paymentState.pickupDetails.addressObj.latitude
      //         : paymentState.pickupDetails.latitude,
      //       longitude: business
      //         ? paymentState.pickupDetails.addressObj.longitude
      //         : paymentState.pickupDetails.longitude,
      //     },
      //     {
      //       latitude: paymentState.dropDetails.latitude,
      //       longitude: paymentState.dropDetails.longitude,
      //     }
      //   ) / 1000;
      // setDistance(distance);
      // console.log("mapdistance before payment0==========",(Math.ceil(distance)));
    }
  }, [paymentState]);

  useEffect(() =>
  {
    if (rateDetail)
    {
      if (distance <= rateDetail.basicDistance)
      {
        let totalCost =
          parseInt(rateDetail.basicRate) + parseInt(rateDetail.specialPay);
        setTotalFee(Math.ceil(totalCost));
        applyCoupon(totalCost);
      } else
      {
        let extraDistance = distance - rateDetail.basicDistance;
        let extraFee = extraDistance * parseInt(rateDetail.distanceRate);
        let totalCost = (
          parseInt(rateDetail.basicRate) +
          parseInt(rateDetail.specialPay) +
          extraFee
        ).toFixed(2);
        setTotalFee(Math.ceil(totalCost));
        applyCoupon(totalCost);
      }
      setLoaderObject(true);
    }
  }, [rateDetail, state, distance]);

  const applyCoupon = (totalCost) =>
  {
    if (state)
    {
      console.log("total cost====", totalCost, state);
      if (state.type === "percentage")
      {
        setTotalCost(Math.ceil(totalCost));
        let netCouponDiscount = (totalCost * state.discount) / 100;
        setCouponDiscount(Math.floor(netCouponDiscount));
        let totalPayment = totalCost - netCouponDiscount;
        totalPayment = Math.ceil(totalPayment.toFixed(2));
        const percentage = totalPayment * gstPercentage;
        setGstAmount(percentage);
        const newTotal = totalPayment + percentage;
        setFinalAmount(newTotal);
        setTotalPrice(Math.ceil(totalPayment.toFixed(2)));
      } else
      {
        setTotalCost(Math.ceil(totalCost));
        let netCouponDiscount = state.discount;
        setCouponDiscount(Math.floor(netCouponDiscount));
        let totalPayment = totalCost - netCouponDiscount;
        totalPayment = Math.ceil(totalPayment.toFixed(2));
        const percentage = totalPayment * gstPercentage;
        setGstAmount(percentage);
        const newTotal = totalPayment + percentage;
        setFinalAmount(newTotal);
        setTotalPrice(Math.ceil(totalPayment.toFixed(2)));
      }
    } else
    {
      setTotalCost(Math.ceil(totalCost));
      let totalPayment = Math.ceil(totalCost);
      const percentage = totalPayment * gstPercentage;
      setGstAmount(percentage);
      const newTotal = totalPayment + percentage;
      setFinalAmount(newTotal);
      setTotalPrice(Math.ceil(totalCost));
    }
  };

  // const applyCoupon =() => {
  //     if(couponDetail.discount!=0){
  //         console.log("t1",totalFee , "t2",couponDetail.discount);
  //      setTotalCost(totalFee-parseInt(couponDetail.discount))
  //     }else{
  //         console.log("t3")
  //         setTotalCost(totalFee)
  //     }
  // }

  //    Distance calculation according to data
  // if (pickupData && dropData) {

  // }

  // function distance(lat1, lng1, lat2, lng2) {
  //   const radius = 6371; // Earth's radius in kilometers
  //   // Convert latitude and longitude coordinates to radians
  //   const radLat1 = unit(lat1, "deg").toNumber("rad");
  //   const radLon1 = unit(lng1, "deg").toNumber("rad");
  //   const radLat2 = unit(lat2, "deg").toNumber("rad");
  //   const radLon2 = unit(lng2, "deg").toNumber("rad");
  //   // Calculate the differences between the latitudes and longitudes
  //   const dLat = radLat2 - radLat1;
  //   const dLon = radLon2 - radLon1;
  //   // Apply the Haversine formula
  //   const a =
  //     sin(dLat / 2) ** 2 + cos(radLat1) * cos(radLat2) * sin(dLon / 2) ** 2;
  //   const c = 2 * atan2(sqrt(a), sqrt(1 - a));
  //   // Return the distance between the two points in kilometers
  //   return radius * c;
  // }
  // distanceKM is to store total distance between pick and drop
  // const distanceInKm = distance(lat1, lng1, lat2, lng2);
  // distance is store upto 2 decimal places
  //let distance = distanceInKm.toFixed(2);
  //  let distance = 10;

  // this button triggers for payment gateway
  const loadScript = (src) =>
  {
    return new Promise((resovle) =>
    {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () =>
      {
        resovle(true);
      };

      script.onerror = () =>
      {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (amount) =>
  {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res)
    {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      currency: "INR",
      order_id: localStorage.getItem("orderId"),
      amount: amount * 100,
      name: "Pickup Ninja",
      description: localStorage.getItem("bookingId"),
      image: logoIcon,

      handler: function (response)
      {
        // alert(response.razorpay_payment_id);
        // alert("Payment Successfully");
        let isSuccessful = false;
        let status = "failure";
        if (response.razorpay_payment_id)
        {
          isSuccessful = true;
          status = "success";
        }
        const values = {
          bookingId: localStorage.getItem("bookingId"),
          transactionId: response.razorpay_payment_id,
          razorpay_signature : response.razorpay_signature,
          razorpay_order_id : localStorage.getItem("orderId"),
          amount: amount,
          distance: displaydistance,
          type: "online",

          isSuccessful,
          status,
        };
        axios
          .post(`transactions/add`, values)
          .then((res) =>
          {
            if (res.status === 200)
            {
              setLoading(false);
              if (paymentState?.scheduleBooking)
              {
                navigate("/schedule-booking", {
                  state: {
                    bookingNumber: res.data.result,
                    scheduleBooking: paymentState?.scheduleBooking.toString() || "",
                  },
                });
              } else
              {
                navigate("/findingNinja");
              }
              toast.success("Booking Created");
            } else
            {
              toast.error("Error while Creating Booking....");
            }
          })
          .catch((e) =>
          {
            setLoading(false);
            console.log(e);
            toast.error("Error while Creating Booking....");
            alert("Error while Creating Booking....");
          });
      },
      prefill: {
        name: "",
        contact: mobile,
      },
      theme: {
        color: "#002236",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response){
      alert(response.error.description);
    });
    paymentObject.open();
  };
  // if (loaderObject) {

  const handleCreateBooking = () =>
  {
    setLoading(true);
    axios
      .post("bookings/add", {
        cityId: cityId,
        pickupDestinationId: business
          ? paymentState.pickupDetails.addressId
          : paymentState?.pickupDetails.id,
        dropDestinationId: paymentState?.dropDetails.id,
        userId: userId,
        gst: gstPercentage * 100,
        scheduleBooking: new Date(paymentState?.scheduleBooking).getTime(),
        packageTypeId: paymentState?.instructionDetails.id,
        isFragile: paymentState?.instructionDetails.fragile,
        message: paymentState?.instructionDetails.message,
        distance: displaydistance,
        amount: finalAmount,
        couponId: state?.id,
        discount: state?.type !== "percentage" ? state?.discount : null,
        discountPercentage: state?.type === "percentage" ? state?.discount : null
      })
      .then((res) =>
      {
        if (res.status === 200)
        {
          let state = {
            bookingId: res.data.result,
          };
          setOrderId(res.data.orderId)
          localStorage.setItem("orderId", res.data.orderId);
          localStorage.setItem("bookingId", res.data.result);
          displayRazorpay(parseFloat(finalAmount));
          // displayCod(parseFloat(finalAmount));

          // console.log('Razor Pay Window open')
        } else
        {
          setLoading(false);
          toast.error("Error while Creating Booking....");
        }
      });
  };

  function generateRandomString()
  {
    const randomString = uuidv4().replace(/-/g, "");
    const prefixedString = `cod_${randomString.slice(0, 16)}`;

    return prefixedString;
  }

  const randomString = generateRandomString();

  async function displayCod(amount)
  {
    const values = {
      bookingId: localStorage.getItem("bookingId"),
      transactionId: randomString,
      amount: amount,
      distance: distance,
      type: "cod",
      isSuccessful: true,
      status: "success",
    };
    axios
      .post(`transactions/add`, values)
      .then((res) =>
      {
        if (res.status === 200)
        {
          setLoading(false);
          if (paymentState?.scheduleBooking)
          {
            navigate("/schedule-booking", {
              state: {
                bookingNumber: res.data.result,
                scheduleBooking: paymentState?.scheduleBooking.toString() || "",
              },
            });
          } else
          {
            navigate("/findingNinja");
          }
          toast.success("Booking Created");
        } else
        {
          toast.error("Error while Creating Booking....");
        }
      })
      .catch((e) =>
      {
        setLoading(false);
        console.log(e);
        toast.error("Error while Creating Booking....");
        alert("Error while Creating Booking....");
      });
  }

  const handleCoupon = () =>
  {
    if (business)
    {
      navigate(`/coupon/add?businessCoupon=${business}`);
    } else
    {
      navigate(`/coupon/add`);
    }
  };

  const handleRemoveCoupon = () =>
  {
    if (business)
    {
      navigate(
        `/businessorder?businessUrl=${business}`,
        { state: null },
        { replace: true }
      );
    } else
    {
      navigate({ state: null }, { replace: true });
    }
  };
  const paymentDetailsString = sessionStorage.getItem("paymentDetails");

  const handlePaymentBack = () =>
  {
    if (isBusiness)
    {
      navigate(`/businessorder?businessUrl=${business}`);
    } else
    {
      navigate("/dashboard");
    }
  };

  // Parse the JSON string into an object
  const paymentDetails = JSON.parse(paymentDetailsString);

  // Access the pickupDetails latitude
  const pickLat = business
    ? paymentDetails?.pickupDetails?.addressObj?.latitude
    : paymentDetails?.pickupDetails?.latitude;
  // console.log("picklatertyuiopoijhghjk",pickLat)
  const pickLong = business
    ? paymentDetails?.pickupDetails?.addressObj?.longitude
    : paymentDetails?.pickupDetails?.longitude;
  const dropLat = paymentDetails?.dropDetails?.latitude;
  const dropLong = paymentDetails?.dropDetails?.longitude;

  // const data = {
  //   pickLat: pickLat,
  //   pickLong: pickLong,
  //   dropLat: dropLat,
  //   dropLong: dropLong
  // };
  // http://localhost:5000/pickupninjaapp/us-central1/appv2/api/map/getDirection?userlat=20.2997&userlong=85.8173&pickLat=20.2788&pickLong=85.7947
  useEffect(() =>
  {
    axios
      .get(
        `map/getDirection?startLat=${pickLat}&startLong=${pickLong}&endLat=${dropLat}&endLong=${dropLong}`
      )
      .then((res) =>
      {
        console.log(
          "distance from directions api",
          res.data?.data?.routes[0]?.legs[0]?.distance?.text?.split(" ")[0]
        );
        console.log(
          "price is=========",
          res.data?.data?.routes[0]?.legs[0]?.distance?.price
        );
        setDistance(
          Math.ceil(
            res.data?.data?.routes[0]?.legs[0]?.distance?.text?.split(" ")[0]
          )
        );
        setDisplayDistance(
          res.data?.data?.routes[0]?.legs[0]?.distance?.text?.split(" ")[0]
        );
        let totalPayment = res.data?.data?.routes[0]?.legs[0]?.distance?.price;
        const percentage = totalPayment * gstPercentage;
        setGstAmount(percentage);
        const newTotal = totalPayment + percentage;
        setFinalAmount(newTotal);
        setTotalPrice(res.data?.data?.routes[0]?.legs[0]?.distance?.price);
        setBasePrice(res.data?.data?.routes[0]?.legs[0]?.distance?.price);
      })
      .catch((err) =>
      {
        console.log("distance API error", err);
      });
  }, []);

  useEffect(() =>
  {
    if (displaydistance === 0)
    {
      toast.error("Some thing went wrong");
      navigate("/");
    }
  }, [displaydistance, navigate]);

  const options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: "true",
    hour: "2-digit",
    minute: "2-digit",
  };

  const sDate =
    (scheduleBooking &&
      new Date(scheduleBooking)?.toLocaleString("en-IN", options)) ||
    (paymentState?.scheduleBooking &&
      new Date(paymentState?.scheduleBooking)?.toLocaleString(
        "en-IN",
        options
      ));

  if (isNaN(finalAmount))
  {
    return <NinjaLoader />;
  }
  return (
    <>
      <Box className={`h-100 d-flex flex-column ${loading ? "layered" : ""} `}>
        <Helmet>
          <meta name="theme-color" content="#002236" />
        </Helmet>
        <Box className="header_notification">
          {/* <ArrowBackIosIcon onClick={() => navigate(-1)} /> */}
          <div onClick={handlePaymentBack}>
            <img src={back} alt="bell" />
          </div>
          <Typography className="notname">Payment</Typography>
          <Link to="/notification">
            <img src={bellicon} alt="bell" />
          </Link>
        </Box>
        {sDate ? (
          <div
            className="header_offset"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5px",
            }}
          >
            <Typography sx={{ paddingX: 4 }} className="top-item">
              Schedule booking on {sDate}
            </Typography>
            {/* <div style={{ marginLeft: "5px" }}>
              <ExpandMoreIcon>
                <input
                  type="datetime-local"
                  ref={datepickerRef}
                  style={{ display: "none" }}
                  onChange={(e) => setScheduleBooking(e.target.value)}
                />
              </ExpandMoreIcon>
            </div> */}
          </div>
        ) : (
          <></>
        )}
        <Box
          className="flex-fill"
          style={sDate ? { paddingTop: "3px" } : { paddingTop: "60px" }}
        >
          <Box className="phorizontal">
            <Box className="paymentslip">
              <Box className="paymentsliptop">
                <img src={invoice} alt="location" className="invoice" />
                <Box>
                  <Typography className="locationtitle">To Pay</Typography>
                  <Typography className="locationhead">
                    ₹{finalAmount}
                  </Typography>
                </Box>
              </Box>
              <Typography className="paymentdetails">
                Payment Details
              </Typography>
              {/* <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography className="locationtitle">Total Distance</Typography>
              <Typography
                style={{
                  flex: "1",
                  borderBottom: "2px dashed #ccc",
                  margin: "0 10px",
                }}
              ></Typography>
              <Typography className="locationhead">{distance}KM</Typography>
            </Box> */}
              <Box style={{ display: "flex", alignItems: "center" }}>
                <div className="bill">
                  <Typography className="billcost">
                    Delivery, {displaydistance} km
                  </Typography>
                </div>
                <Typography
                  style={{
                    flex: "1",
                    borderBottom: "2px dashed #ccc",
                    margin: "0 10px",
                  }}
                ></Typography>
                <Typography style={{ fontWeight: "700" }}>
                  {/* ₹{totalFee} */}₹{totalCost}
                </Typography>
              </Box>
              {couponDetails ? (
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <div className="bill">
                    <Typography className="billcost">
                      Coupon Discount
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      flex: "1",
                      borderBottom: "2px dashed #ccc",
                      margin: "0 10px",
                    }}
                  ></Typography>
                  <Typography>₹{couponDetails.coupon.discount}</Typography>
                </Box>
              ) : state ? (
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <div className="bill">
                    <Typography className="billcost"> Discount</Typography>
                  </div>
                  <Typography
                    style={{
                      flex: "1",
                      borderBottom: "2px dashed #ccc",
                      margin: "0 10px",
                    }}
                  ></Typography>
                  <Typography sx={{ color: "red" }}>
                    - ₹{couponDiscount}
                  </Typography>
                </Box>
              ) : null}
              <Box style={{ display: "flex", alignItems: "center" }}>
                <div className="bill">
                  <Typography className="billcost">Total</Typography>
                </div>
                <Typography
                  style={{
                    flex: "1",
                    borderBottom: "2px dashed #ccc",
                    margin: "0 10px",
                  }}
                ></Typography>
                <Typography style={{ fontWeight: "700" }}>
                  {/* ₹{totalFee} */}₹{finalAmount}
                </Typography>
              </Box>
              {!!gstPercentage && <Box style={{ display: "flex", alignItems: "center" }}>
                <div className="bill">
                  <Typography className="billcost">GST (5%)</Typography>
                </div>
                <Typography
                  style={{
                    flex: "1",
                    borderBottom: "2px dashed #ccc",
                    margin: "0 10px",
                  }}
                ></Typography>
                <Typography style={{ fontWeight: "700" }}>
                  {/* ₹{totalFee} */}₹{gstAmount}
                </Typography>
              </Box>}
              {/* <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography className="bill">
                <Typography className="billcost">Total Cost</Typography>
              </Typography>
              <Typography
                style={{
                  flex: "1",
                  borderBottom: "2px dashed #ccc",
                  margin: "0 10px",
                }}
              ></Typography>
              <Typography>₹{totalFeeDiscount || totalFee}</Typography>
            </Box> */}
              {/* <Typography className="hidebill">Hide details</Typography> */}
            </Box>
            {state ? (
              <Box className="p-childdesign">
                <img src={coupon} alt="location" className="invoice" />
                <Box>
                  <Typography className="" style={{ fontWeight: "700" }}>
                    {state.code}
                  </Typography>
                  <Typography
                    className=""
                    style={{
                      color: "#9d9e9e",
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    {/* {state.description} */}
                    Coupon code
                  </Typography>
                </Box>
                {/* <Link to="/apply/coupon"> */}
                <div
                  variant="contained"
                  className="removeButton changeName"
                  onClick={handleRemoveCoupon}
                >
                  Remove
                </div>
                {/* </Link> */}
              </Box>
            ) : (
              <Box className="p-childdesign">
                <img src={coupon} alt="location" className="invoice" />
                <Box>
                  {/* <Typography className="locationtitle">
                    Coupon Number
                  </Typography> */}
                  <Typography className="locationhead">
                    Apply your coupon
                  </Typography>
                </Box>

                <Button
                  variant=""
                  sx={{ color: "white" }}
                  className="applybutton"
                  onClick={handleCoupon}
                >
                  Apply
                </Button>
              </Box>
            )}
          </Box>
          <Box className="borderbottom"></Box>
          {/* <Box className="phorizontal">
          <Typography className="payment_terms">
            Payment Terms and Conditions
          </Typography>
          <ol className="payment_terms_list">
            <li>
              Share it with your customers on WhatsApp or another messanger
            </li>
            <li>
              Your customers can call or write you to place ordersand settle
              payment directly{" "}
            </li>
            <li>
              They can get their orders picked by entering the drop location
            </li>
            <li>Delivery fee is paid by customer on Ninja</li>
          </ol>
        </Box> */}
        </Box>

        {/* <Box className="proceedpay">
          <div
            className={`w-100 d-flex payDetailsBtn ${
              loading ? "content-loader" : ""
            } `}
          >
            <div
              className="tcost"
              style={{ backgroundColor: `${business ? "#ee775a" : ""}` }}
            >
              ₹{finalAmount}
              <Typography className="tcostt">Total Cost</Typography>
            </div>
            <button
              onClick={() => handleCreateBooking()}
              className="ppayment"
              style={{ backgroundColor: `${business ? "#e3552e" : ""}` }}
              disabled={loading}
            >
              Book
            </button>
          </div>
        </Box> */}
        <Box className="proceedpay">
          <div
            className={`w-100 d-flex payDetailsBtn ${loading ? "content-loader" : ""
              } `}
          >
            <div
              className="tcost"
              style={{ backgroundColor: `${(business && !loading) ? "#ee775a" : ""}` }}
            >
              ₹{finalAmount}
              <Typography className="tcostt">Total Cost</Typography>
            </div>
            <button
              onClick={() => handleCreateBooking()}
              className="ppayment"
              style={{ backgroundColor: `${(business && !loading) ? "#e3552e" : ""}` }}
              disabled={loading}
            >
              Proceed Payment
            </button>
          </div>
        </Box>
      </Box>
    </>
  );
  // }
  // else {
  //     return (
  //         <div className="phorizontal">
  //             <img src={loader} alt="loader" className="loader_img" />
  //         </div>
  //     )
  // }
};
export default PaymentDetails;
