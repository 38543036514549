import React, { useState, useContext, useEffect } from "react";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../../config/context.Config";
import "../../assets/css/recaptcha.css";
import {
  RecaptchaVerifier,
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithPhoneNumber,
} from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { onAuthStateChanged } from "../../config/firebase.Config";
import { ButtonBlue } from "../../components/UsableElements";
import logo from "../../assets/images/logowithname.svg";
import { OTPInput } from "../../components/OTPInput";
import loader from "../../assets/images/loader.gif";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const FireBaseAuth = () => {
  const { getUser } = useContext(MainContext);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState();
  const [btnactiveee, setbtnactiveee] = useState("lpbutton-boxinactive");
  const [activity, setactivity] = useState(false);
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const [formattedPhonenumber, setFormattedPhonenumber] = useState("");
  const auth = getAuth();

  console.log(
    {
      apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
      authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
      databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
      projectId: `${process.env.REACT_APP_PROJECT_ID}`,
      storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
      messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
      appId: `${process.env.REACT_APP_APP_ID}`,
      measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`,
    },
    auth,
    "====================="
  );

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       navigate("/dashboard");
  //     }
  //   });
  // }, []);

  // useEffect(()=>{
  //     onAuthStateChanged((user) => {
  //         if (user) {
  //           setInterval(() => {
  //             user.getIdToken(true)
  //               .then((token) => {
  //                 console.log("Refreshed token:", token);
  //                 localStorage.setItem("token", token);

  //               })
  //               .catch((error) => {
  //                 console.error("Error refreshing token:", error);
  //               });
  //           }, 300000);
  //         }
  //       });
  // })

  function onCaptchaVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );
    }
  }

  // Function for sending OTPs.......
  function sendVerificationOTP() {
    if (isCheckedTerms) { 
      if (activity) {
        setLoading(true);
        onCaptchaVerify();
        setFormattedPhonenumber(
          document.getElementsByClassName("form-control")[0].value
        );
        const appVerifier = window.recaptchaVerifier;
        const formatPh = "+" + ph;
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
            return signInWithPhoneNumber(auth, formatPh, appVerifier)
              .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setShowOTP(true);
                setLoading(false);
                toast.success("OTP sent successfully !");
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
              });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            toast.error(errorCode + " " + errorMessage);
            setLoading(false);
          });
      }
    } else {
      toast.error("Accept our terms and conditions first");
    }
  }

  function onOTPVerify() {
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        res.user.getIdToken().then((token) => {
          localStorage.removeItem("token");
          localStorage.setItem("token", token);
          setUser(res.user);
          getUser();
        });
      })
      .catch((err) => {
        toast.error("Incorrect Otp!");
      });
  }
  function numberValidation() {
    if (ph.length === 0) {
      toast.error("Please enter your mobile number !");
    } else if (ph.length < 12) {
      toast.error("Please enter a valid number !");
    } else {
      sendVerificationOTP();
    }
  }

  const handleactivebtn = () => {
    setbtnactiveee(
      btnactiveee === "lpbutton-boxinactive"
        ? "lpbutton-boxactive"
        : "lpbutton-boxinactive"
    );
    setactivity(!activity);
    setIsCheckedTerms(!isCheckedTerms);
  };

  useEffect(() => {
    if (loading) {
      setbtnactiveee("lpbutton-boxinactive");
    }
  }, [loading]);

  return (
    <>
      <section className="h-100 bg-emerald  h-screen">
        <Helmet>
          <meta name="theme-color" content="#fff" />
        </Helmet>
        <Toaster toastOptions={{ className: "ff_Cabin", duration: 4000 }} />
        <div id="recaptcha-container"></div>
        {/* {user ? (
              navigate("/dashboard")
            ) : ( */}
        <div className="d-flex align-items-center flex-column h-100">
          <img
            className="element_center"
            src={logo}
            alt="Pickup ninja logo"
          ></img>
          <Box className="phorizontal d-flex align-items-stretch flex-column flex-fill w-100">
            {showOTP ? (
              <>
                <Typography className="text_blue_fs18_700 text_center text_center ff_Cabin">
                  Enter OTP
                </Typography>
                <Box className="lo-log-box">
                  <Typography className="text_gray_fs14_700 text_center ff_Cabin">
                    We have sent a verification code to number
                    <br /> {formattedPhonenumber}
                  </Typography>
                </Box>
                <Box className="lo-otp-box">
                  <OTPInput setOtp={setOtp} />
                </Box>

                <Box className="mt-auto text-center">
                  <Typography
                    className="lo-text-head lo-text-headd lo-tdu ff_Cabin"
                    onClick={sendVerificationOTP}
                    sx={{ marginTop: "10px" }}
                  >
                    Send verification code again
                  </Typography>
                </Box>
                <Button
                  className="lo-lpbutton-box ff_Cabin"
                  onClick={onOTPVerify}
                >
                  Login
                  {/* or Register */}
                </Button>
              </>
            ) : (
              <>
                <Typography className="ff_Cabin text_blue_fs18_700 text_center mg_top10">
                  Login
                  {/* or register */}
                </Typography>

                <PhoneInput country={"in"} value={ph} onChange={setPh} />

                <Typography className="ff_Cabin text_gray_fs14_700 text_center">
                  OTP will be sent to this number
                </Typography>

                <Box className="d-flex justify-content-center align-items-center mt-auto">
                  <Checkbox
                    style={{ color: "#E3552E", padding: "9px 9px 9px 0" }}
                    onClick={handleactivebtn}
                  />
                  <Typography className="text_blue_fs14_700 ff_Cabin">
                    I agree with the{" "}
                    <Link
                      to="/TermsAndConditions"
                      style={{
                        color: "black",
                        borderBottom: "2px solid black",
                      }}
                    >
                      terms & conditions
                    </Link>
                  </Typography>
                </Box>
                <Button
                  className={btnactiveee}
                  onClick={() => numberValidation()}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "#E3552E",
                      }}
                      size={22}
                    />
                  ) : (
                    "Send verification code"
                  )}
                </Button>
              </>
            )}
          </Box>
        </div>
        {/* )} */}
      </section>
    </>
  );
};
export default FireBaseAuth;
