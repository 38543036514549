import React, { useState, useRef, useContext, useEffect } from "react";
import "../../assets/css/Business/registerBusiness.css";
import { useNavigate, useLocation } from "react-router-dom";
// mui imports start
import {
  Typography,
  Box,
  // TextField,
  InputBase,
  // MenuItem,
  Button,
  Modal,
} from "@mui/material";
import { Toaster, toast } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Map from "../../components/Map";
import axios from "../../config/axios.Config";
import { LoadScript } from "@react-google-maps/api";
import { libraries, apiKey } from "../../config/map.config";
// import { MyTimePicker } from "../../components/DateTimePicker";
// import pnlogo from "../../assets/images/logowithname.svg";
// import cuser from "../../assets/images/business/avtarIcon.svg";
import unavailable from "../../assets/images/business/close-red.svg";
import available from "../../assets/images/business/check.svg";
import delimgg from "../../assets/images/deleteIcon.svg";
import machine from "../../assets/images/business/businessName.svg";
import call from "../../assets/images/callicon.svg";
import location from "../../assets/images/locationIcon.svg";
import { MainContext } from "../../config/context.Config";
import whatsapp from "../../assets/images/business/whatsappicon.png";
import business from "../../assets/images/business/handshakeIcon.svg";
import chain from "../../assets/images/business/linkLink.png";
// import { ButtonRed } from "../../components/UsableElements";
import { Helmet } from "react-helmet";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "94%",
  bgcolor: "background.paper",
  border: "2px solid #E3552E",
  boxShadow: 24,
  borderRadius: "16px",
  padding: "16px",
  height: "78vh",
};
// image import end
const businessType = [
  {
    value: "Restaurant",
    label: "Restaurant",
  },
  {
    value: "Medicine",
    label: "Medicine",
  },
  {
    value: "Education",
    label: "Education",
  },
  {
    value: "Others",
    label: "Others",
  },
];
const workday = [
  { day: "Mon", id: 1 },
  { day: "Tue", id: 2 },
  { day: "Wed", id: 3 },
  { day: "Thu", id: 4 },
  { day: "Fri", id: 5 },
  { day: "Sat", id: 6 },
  { day: "Sun", id: 0 },
];

const initialState = {
  businessName: "",
  mobile: "",
  whatsapp: "",
  startTime: "",
  endTime: "",
  address: "",
  about: "",
};
const RegisterBusiness = ({
  displayy,
  editBusiness,
  setSwitchAccount,
  setDisplayy,
  setEditBusiness,
}) => {
  const spanRef = useRef(null);
  const { userId, businessAddDetails, businessNIDetails , cityId } =
    useContext(MainContext);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(editBusiness || false);
  const [businessEditData, setBusinessEditData] = React.useState("");
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [stateValue, setStateValue] = useState(initialState);
  const [selectedImage, setSelectedImage] = useState(
    businessNIDetails?.busImage || ""
  );
  // const [time, setTime] = useState("10:00");
  const [businessImage, setBusinessImage] = useState("");
  const [isImageEditing, setIsImageEditing] = useState(false);
  const [businessDays, setBusinessDays] = useState([]);
  const [mapMarkerPosition, setmapMarkerPosition] = useState();
  const [selectedType, setSelectedType] = useState("Restaurant");
  const [newBusinessAddress, setBusinessAddress] = useState("");
  const [newBusinessName, setBusinessName] = useState(
    businessNIDetails?.busName || ""
  );
  const [businessAdd, setBusinessAdd] = useState(businessAddDetails);
  const [packageType, setPackageType] = useState([]);
  const [active, setActive] = useState("aavtarlaybox");
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [url, setUrl] = useState("");
  const [suggestionUrl, setSuggestionUrl] = useState("");
  const [urlAvailable, setUrlAvailable] = useState(null);
  const [suggsionOn, setSuggsionOn] = useState(false);
  const [businessUrl, setBusinessUrl] = useState(
    businessNIDetails?.busName
      ?.toLowerCase()
      ?.trim()
      ?.replace(/[^\w\s-]/g, "")
      ?.replace(/[\s_-]+/g, "-")
      ?.replace(/^-+|-+$/g, "") || null
  );
  const fixedUrl = "app.pickup.ninja/";

  const locationPath = useLocation();

  useEffect(() => {
    setIsEditing(locationPath?.state?.isEditing);
  }, [locationPath?.state?.isEditing]);

  useEffect(() => {
    if (!isEditing) {
      setStateValue({
        mobile: businessNIDetails?.businessPhone,
      });
      // setMobile(businessMobile.substring(3));
    }
  }, [isEditing, businessNIDetails?.businessPhone]);

  useEffect(() => {
    if (isEditing) {
      async function getBusinessDetails() {
        try {
          const {
            data: { result },
          } = await axios.get(`/business/get/${userId}`);
          setBusinessEditData(result);
          // setBusinessName(result.name);
          setSelectedType(result.type);
          if (result.avatar) {
            setIsImageEditing(true);
          }
          setSelectedPackage(result.packageTypeIds);
          setBusinessDays(result.businessDays);
          setBusinessImage(result.avatar);

          if (businessNIDetails) {
            setStateValue({ businessName: businessNIDetails?.busName });
          }
          if (!locationPath?.state?.isAddressEditing) {
            setBusinessAdd({ address: result.addressObj.address });
          } else {
            setBusinessAdd(businessAddDetails);
          }
          setStateValue({
            businessName: result?.name || result?.addressObj?.name,
            mobile: result.addressObj.mobile,
            whatsapp: result.whatsapp,
            startTime: result.startTime,
            endTime: result.endTime,
            address: result.address,
            about: result.about,
          });
          setBusinessUrl(result.url);
        } catch (error) {
          console.log(error);
        }
      }
      getBusinessDetails();
    }
  }, [userId, isEditing]);

  const handelInputChange = (e) => {
    const { name, value } = e.target;
    setStateValue({
      ...stateValue,
      [name]: value,
    });
  };

  useEffect(() => {
    setStateValue({
      businessName: businessNIDetails?.busName,
    });
  }, [businessNIDetails?.busName]);

  const handleBusinessUrl = (event) => {
    setBusinessUrl(
      event.target.value
        ?.toLowerCase()
        ?.trim()
        ?.replace(/[^\w\s-]/g, "")
        ?.replace(/[\s_-]+/g, "-")
        ?.replace(/^-+|-+$/g, "")
    );
  };
  const handleBusinessUrlField = (event) => {
    console.log(event.target.textContent);
    setSuggsionOn(true);
    setBusinessUrl(
      event.target.textContent
        ?.toLowerCase()
        ?.trim()
        ?.replace(/[^\w\s-]/g, "")
        ?.replace(/[\s_-]+/g, "-")
        ?.replace(/^-+|-+$/g, "")
    );
    if (spanRef.current) {
      spanRef.current.focus();
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(spanRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  useEffect(() => {
    setmapMarkerPosition(
      JSON.parse(sessionStorage.getItem("registerBusiness"))
    );
  }, []);

  useEffect(() => {
    async function getBusinessDetails() {
      try {
        const { data } = await axios.get(
          `/business/get/suggestion/${businessUrl}`
        );
        console.log(data.result);
        if (!data.result.available) {
          // toast.error("Business URL not available");
          setUrlAvailable(data.result.available);
          setSuggestionUrl(data.result.suggestion);
        } else {
          setUrlAvailable(data.result.available);
          setSuggestionUrl(
            businessUrl
              .toLowerCase()
              .trim()
              .replace(/[^\w\s-]/g, "")
              .replace(/[\s_-]+/g, "-")
              .replace(/^-+|-+$/g, "")
          );
        }
      } catch (error) {}
    }
    getBusinessDetails();
  }, [businessUrl]);

  const handleBusinessDays = (e) => {
    if (businessDays.includes(e.target.value)) {
      setBusinessDays(businessDays.filter((b) => b !== e.target.value));
    } else {
      setBusinessDays([...businessDays, e.target.value]);
    }
  };

  useEffect(() => {
    axios
      .get(`/packageTypes/get`)
      .then((res) => {
        console.log(res.data.result);
        setPackageType(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("registerBusiness");
    };
  }, []);

  const PostBusinessValue = {
    whatsapp: stateValue.whatsapp,
    address: businessAdd?.address || "",
    longitude: mapMarkerPosition?.longitude || 85.8245,
    latitude: mapMarkerPosition?.latitude || 20.2961,
    mobile: stateValue.mobile,

    cityId:cityId,

    type: selectedType,
    userId: userId,
    url: suggestionUrl,
    name: stateValue.businessName || newBusinessName,
    packageTypeIds: selectedPackage,
    startTime: stateValue.startTime,
    endTime: stateValue.endTime,
    about: stateValue.about,
    businessDays: businessDays,
    avatarFile: selectedImage,
  };
  console.log(PostBusinessValue);
  const form = new FormData();
  if (selectedImage) {
    form.append("avatarFile", selectedImage);
  }
  form.append("whatsapp", PostBusinessValue.whatsapp);
  form.append("address", PostBusinessValue.address);
  form.append("longitude", PostBusinessValue.longitude);
  form.append("latitude", PostBusinessValue.latitude);
  form.append("mobile", PostBusinessValue.mobile);
  form.append("cityId", PostBusinessValue.cityId);
  form.append("userId", PostBusinessValue.userId);
  form.append("type", PostBusinessValue.type);
  if (!editBusiness) {
    form.append("url", PostBusinessValue.url);
  }
  form.append("name", PostBusinessValue.name);
  form.append("packageTypeIds", PostBusinessValue.packageTypeIds);
  form.append("startTime", PostBusinessValue.startTime);
  form.append("endTime", PostBusinessValue.endTime);
  form.append("about", PostBusinessValue.about);
  form.append("businessDays", PostBusinessValue.businessDays);
  const submitData = () => {
    if (editBusiness) {
      axios
        .put(`business/update/${businessEditData.id}`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          console.log("updated Successfully");
          setSwitchAccount(true);
          setDisplayy("upperlayerone");
          setEditBusiness(false);
          // navigate("/")
          navigate("/business");
        })
        .catch((err) => {
          toast.error(err.response.data);
        });
    } else {
      axios
        .post(`business/add`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          toast.success("Business Created");
          console.log("Added Successfully");
          navigate("/business");
        })
        .catch((err) => {
          toast.error(err.response.data);
        });
    }
  };
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files[0]);
      setSelectedImage(e.target.files[0]);
    }
  };
  const removeSelectedImage = () => {
    setIsImageEditing(false);
    setSelectedImage();
  };
  const handleSelectedPackage = (id) => {
    if (selectedPackage.includes(id)) {
      setSelectedPackage(selectedPackage.filter((p) => p !== id));
    } else {
      setSelectedPackage([...selectedPackage, id]);
    }
  };
  return (
    <Box className="container">
      <Helmet>
        <meta name="theme-color" content="#002236" />
      </Helmet>
      <Toaster toastOptions={{ duration: 4000 }} />
      {isEditing || editBusiness ? (
        <Typography className="Create hor_space">Edit account</Typography>
      ) : (
        <Typography className="Create hor_space">Create account</Typography>
      )}
      {/* <Box className="uimage">
        <img src={cuser} alt="pnuser" className="pnuser" name="logo" />
      </Box> */}
      <Box className="uimage">
        {!isImageEditing && (
          <input
            accept="image/*"
            type="file"
            onChange={imageChange}
            className="business-image"
          />
        )}
        {(selectedImage || isImageEditing) && (
          <Box className="business-img">
            <img
              src={
                isImageEditing
                  ? businessImage
                  : URL.createObjectURL(selectedImage)
              }
              alt="Thumb"
              className="business-img__round"
            />
            <img
              src={delimgg}
              alt="pnlogo"
              className="del-icon"
              onClick={removeSelectedImage}
            />
          </Box>
        )}
        {/* {isEditing && (
          <Box className="business-img">
            <img
              src={businessImage}
              alt="Thumb"
              className="business-img__round"
            />
            <img
              src={delimgg}
              alt="pnlogo"
              className="del-icon"
              onClick={() => setBusinessImage("")}
            />
          </Box>
        )} */}
      </Box>
      <Box className="hor_space">
        <Box className="imginputss">
          <img src={machine} alt="pnuser" />
          <div className="inputHolder">
            <input
              type="text"
              name="businessName"
              placeholder="&nbsp;"
              // value={stateValue.businessName || newBusinessName}
              value={stateValue.businessName}
              onChange={handelInputChange}
              onInput={editBusiness ? () => {} : handleBusinessUrl}
            />

            <label>Company Name</label>
          </div>
        </Box>
        <Box className="imginputss">
          <img src={location} alt="pnuser" />
          {businessAdd ? (
            <>
              <div
                className="inputHolder"
                style={{ height: "43px" }}
                onClick={() =>
                  navigate(
                    `/destination/Add?businessEditAddress=businessEditAddress`,
                    {
                      state: {
                        isEditing: true,
                      },
                    }
                  )
                }
              >
                <div className="singleLineText">{businessAdd.alias || ""}</div>
                <div className="singleLineText">{businessAdd?.address}</div>
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                    transform: "none",
                  }}
                >
                  Location
                </label>
              </div>
            </>
          ) : (
            <div
              className="inputHolder"
              style={{ height: "43px" }}
              onClick={() =>
                displayy === "upperlayerone"
                  ? navigate(
                      `/destination/Add?registerBusiness=businessAddress`,
                      {
                        state: {
                          buName: stateValue.businessName,
                          buImage: selectedImage,
                          mapMarkerPosition,
                        },
                      }
                    )
                  : () => {}
              }
            >
              <label>Location</label>
            </div>
          )}
        </Box>
        <Box className="imginputss">
          <img src={call} alt="pnuser" />
          <div className="inputHolder">
            <input
              type="tel"
              name="mobile"
              placeholder="&nbsp;"
              value={stateValue.mobile}
              onChange={handelInputChange}
            />
            <label>Phone number for customers</label>
          </div>
        </Box>
        <Box className="imginputss">
          <img src={whatsapp} alt="pnuser" />
          <div className="inputHolder">
            <input
              type="tel"
              name="whatsapp"
              placeholder="&nbsp;"
              value={stateValue.whatsapp}
              onChange={handelInputChange}
            />
            <label>WhatsApp number</label>
          </div>
        </Box>

        <Box className="imginputss">
          <img src={business} alt="pnuser" />
          <div className="inputHolder">
            <select
              id="standard-select-currency"
              // select
              variant="standard"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              {businessType.map((option) => (
                <option key={option.value} value={option.value} name="type">
                  {option.label}
                </option>
              ))}
            </select>
            <label>Business Type</label>
          </div>
        </Box>
      </Box>

      <div className="hor_space" style={{ marginTop: "30px" }}>
        <Typography className="i-package">Package Type</Typography>
      </div>
      <Swiper
        resistanceRatio={0}
        spaceBetween={10}
        breakpoints={{
          320: {
            width: 320,
            slidesPerView: 3,
          },
          576: {
            width: 576,
            slidesPerView: 4,
          },
          768: {
            width: 768,
            slidesPerView: 4,
          },
        }}
        slidesPerView={4}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        className="i-swipe-package hor_space"
      >
        {packageType.map((data) => {
          return (
            <SwiperSlide className="i-swiper-itemss" key={data.id}>
              <Box
                className={`i-package-imgg-lay ${
                  selectedPackage.find((p) => p === data.id) && "activeee"
                } `}
              >
                <img
                  src={data.icon}
                  alt="pnlogo"
                  className="i-package-imgg"
                  onClick={() => {
                    setActive(data);
                    handleSelectedPackage(data.id);
                  }}
                />
              </Box>
              <Typography className="i-package-item-name">
                {data.type}
              </Typography>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Box className="hor_space" style={{ marginTop: "30px" }}>
        <Typography className="workingdays">Working days</Typography>

        <Box className="bs-working">
          {workday.map((items, i) => {
            return (
              <label
                key={items.id}
                className="work-container"
                htmlFor={items.id}
              >
                <input
                  id={items.id}
                  type="checkbox"
                  name="businessDays"
                  onChange={handleBusinessDays}
                  value={items.id}
                />
                <span
                  className={`mark ${
                    businessDays.find((d) => d == items.id) && "work-mark"
                  }`}
                >
                  {items.day}
                </span>
              </label>
            );
          })}
        </Box>
      </Box>

      <Box className="hor_space" style={{ marginTop: "30px" }}>
        <Typography className="workingdays">Working hours</Typography>

        <Box
          className="d-flex justify-content-between"
          style={{ marginTop: "16px" }}
        >
          <Box
            className="imginputss flex-fill"
            style={{ marginRight: "6px", marginTop: "0", marginBottom: "0" }}
          >
            <div className="inputHolder" style={{ marginLeft: "0" }}>
              <input
                type="time"
                name="startTime"
                placeholder="&nbsp;"
                onChange={handelInputChange}
                value={stateValue.startTime}
                style={{ textTransform: "lowercase" }}
              />
              <label>From</label>
            </div>
            {/* <MyTimePicker/> */}
            {/* <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} /> */}
          </Box>

          <Box
            className="imginputss flex-fill"
            style={{ marginLeft: "6px", marginTop: "0", marginBottom: "0" }}
          >
            <div className="inputHolder" style={{ marginLeft: "0" }}>
              <input
                type="time"
                name="endTime"
                placeholder="&nbsp;"
                onChange={handelInputChange}
                value={stateValue.endTime}
                style={{ textTransform: "lowercase" }}
              />
              <label>Till</label>
            </div>
            {/* <MyTimePicker/> */}
            {/* <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} /> */}
          </Box>
        </Box>
      </Box>

      {/* <TextField id="filled-multiline-static" label="Tell about your business" multiline rows={4} name="about" onChange={handelInputChange} variant="filled" className='textareaa' /> */}

      <Box className="hor_space" style={{ marginTop: "30px" }}>
        <textarea
          placeholder="Tell about your business"
          rows="4"
          cols="50"
          name="about"
          value={stateValue?.about}
          className="textarea-instruction"
          onChange={handelInputChange}
        ></textarea>
      </Box>

      <Box className="hor_space">
        <Box
          className="imginputss"
          style={{ position: "relative", marginBottom: "5px" }}
        >
          {!editBusiness && (
            <div className="linkAvailabilityStatus">
              {urlAvailable && <img src={available} alt="link" />}
              {!urlAvailable && <img src={unavailable} alt="link" />}
            </div>
          )}
          <img src={chain} alt="link" />
          <div className="inputHolder">
            <div className="rgty">
              {/* <input
              type="text"
              name="link"
              placeholder=""
              value={fixedUrl}
              readOnly
              className="pe-0"
              style={{ paddingRight: "0", width: "138px" }}
            /> */}
              <span>{fixedUrl}</span>
              {isEditing || editBusiness ? (
                <span>{businessUrl}</span>
              ) : (
                <span
                  contentEditable={true}
                  ref={spanRef}
                  onKeyUp={setSuggsionOn}
                  suppressContentEditableWarning={true}
                  onClick={handleBusinessUrlField}
                >
                  {businessUrl}
                </span>
              )}
              {/* <input
              type="text"
              name="link"
              placeholder=""
              onKeyUp={setSuggsionOn}
              onInput={handleBusinessUrlField}
              value={businessUrl}
              disabled={isEditing || editBusiness}
              className="ps-0"
            /> */}
            </div>

            <label>Link for customers</label>
          </div>
        </Box>
      </Box>
      {!editBusiness && suggestionUrl && suggsionOn && (
        <Box className="hor_space d-flex availableLinks">
          <label>Available Links :</label> <span>{suggestionUrl}</span>
        </Box>
      )}
      {!editBusiness && (
        <Box className="hor_space" style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            disableElevation
            disabled={!urlAvailable}
            className="continueebtnnn"
            onClick={() => {
              submitData();
            }}
          >
            {`Create Business`}
          </Button>
        </Box>
      )}
      {editBusiness && (
        <Box className="hor_space" style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            disableElevation
            className="continueebtnnn"
            onClick={() => {
              submitData();
            }}
          >
            {`Update Business`}
          </Button>
        </Box>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box style={{ overflow: "hidden", height: "300px", width: "100%" }}>
            <LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
              <Map
                setmapMarkerPosition={setmapMarkerPosition}
                setAddress={setBusinessAddress}
                mapMarkerPosition={mapMarkerPosition}
                markerPosition={"30%"}
              />
            </LoadScript>
          </Box>
          <Box className="imginputss">
            {/* <img src={location} alt="pnuser" /> */}
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              placeholder="Add Your Business Address"
              multiline
              rows={3}
              value={newBusinessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
              name="address"
            />
          </Box>
          <Button
            className="button-modal btn_red"
            name="Add"
            onClick={() => setOpen(false)}
          >
            Add
          </Button>
          {/* <ButtonRed name="Add" onClick={()=> setOpen(false)}/> */}
          <Box></Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RegisterBusiness;
