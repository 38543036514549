const userReducer = (state , action)=>{
  switch(action.type)   {
    case "SET_LOADING":
        return {
            ...state,
            loading:true,
        }
    case "USER_DATA":{
        const userData = action.payload
        return{
            ...state,
            loading:true,
            dashboard:true,
            userInfo : userData,
            userId: userData.result._id,
            mobile: userData.result.mobile,
            name: userData.result.name,
            avatar:userData.result.avatar
        }
    }
    case "API_ERROR":{
        return{
            ...state,
            loading:false,
            isError:true
        }
    }
  }
}
export default userReducer;