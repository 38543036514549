import React, { useState, useEffect, useContext } from "react";
import "../../assets/css/orderHistory.css";
import { Box, Typography, Button } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../config/axios.Config";
import { MainContext } from "../../config/context.Config";
import { BottomSheet } from "react-spring-bottom-sheet";

//image..
import locationIcon from "../../assets/images/locationIcon.svg";
import logo from "../../assets/images/appstore.png";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import NinjaLoader from "../../components/NinjaLoader";

const LocationDetails = (props) => {
    const navigate = useNavigate();
    const [allCity, setAllCity] = useState()
    const [loading , setLoading] = useState(false)
    const {
        setCityId,
        setCityName
      } = useContext(MainContext);

    // set city id
    const addCity=(cityId , cityName)=>{
        localStorage.setItem("cityId", cityId);
        localStorage.setItem("cityName", cityName);
        setCityId(cityId)
        setCityName(cityName)
        navigate('/dashboard')
    }
   
    //get city
    const getCity = () => {
        axios
            .get('/cities/get')
            .then((res) => {
            setAllCity(res.data.result)
            setLoading(true)
            })
            .catch((err) => {
            console.log("error in city list",err)
        })
    }
    useEffect(() => {
        getCity();
    }, [])

    //render city
    const renderCity = () => {
        if (allCity){
           return allCity.map((items)=>{
                return (
                    <Box className="tab-item" key={items.id}>
                    <img src={locationIcon} alt="status" />
                    <Box>
                        <Typography className="buttom-item">
                            {items.city}
                        </Typography>
                    </Box>
                    <Button className="button-pick" onClick={()=>addCity(items.id , items.city)}>Select</Button>
                </Box>
                )
            })
        }else{
            return(
                <NinjaLoader/>
            )
        }
    }

    return (
        <Box>
            <Box className='header_notification'>
                <img src={back} alt="bell" onClick={() => navigate(-1)} />
                <Typography className="notname">City</Typography>
                <Link to="/notification">
                    <img src={bellicon} alt="bell" />
                </Link>
            </Box>
            <Box className="header_offset phorizontal">
              {renderCity()}
            </Box>
        </Box>
    )
};
export default LocationDetails;
