import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import { Toaster } from "react-hot-toast";
import Rating from '@mui/material/Rating';
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import no_rider from "../../assets/images/no-rider.png";
import { Helmet } from 'react-helmet';


const PageNotFound = () => {
    const [activeTab, setActiveTab] = useState("picked");
    const navigate = useNavigate();
    const goHome = () => {
        navigate('/dashboard');
      };
    return (
        <Box className="h-100">
            <Helmet>
                <meta name="theme-color" content="#002236" />
            </Helmet>
            <Box className="header_notification" style={{ boxShadow: 'none' }}>
                <Toaster toastOptions={{ duration: 4000 }} />
                <img src={back} alt="bell" onClick={() => navigate(-1)} />
                <Typography className="notname"></Typography>
                <Link to="/notification">
                    <img src={bellicon} alt="bell" />
                </Link>
            </Box>
            <Box className="header_offset h-100 d-flex flex-column">
                <Box className="ulupper" style={{ height: '300px', paddingBottom: '0', overflow: 'hidden', position: 'relative' }}>
                    <Box className="blobOne"></Box>
                    <Box className="blobTwo"></Box>
                    <img src={no_rider} alt="" style={{ maxWidth: '100%', position: 'relative', bottom: '-85px' }} />
                </Box>

                <Box className="phorizontal" style={{ marginTop: '16px' }}>
                    <Typography className="workingdays" style={{ textAlign: 'center' }}>Page not found</Typography>
                    <Typography style={{ textAlign: 'center', fontSize: '14px' }}>
                        Please check your url
                    </Typography>
                </Box>



                <Box className="hor_space mt-auto">
                    <Button sx={{ textTransform: "none" }} className="continueebtnn"  onClick={goHome}>
                       Go To Homepage
                    </Button>
                </Box>

            </Box>
        </Box>
    );
}

export default PageNotFound;


