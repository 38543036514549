import markerIcon from "../assets/images/round-pushpin.svg";
//import markerPointer from "../assets/images/markerIcon2.png";
import React, { useState, useRef, useEffect } from "react";
import { GoogleMap } from "@react-google-maps/api";
import "../assets/css/Map.css";

import relocateIcon from "../assets/images/aim.png";
const Map = ({
  setmapMarkerPosition,
  setAddress,
  mapMarkerPosition,
  type,
  markerPosition,
  isDraggable,
}) => {
  const [center, setCenter] = useState(
    mapMarkerPosition || { lat: 20.2961, lng: 85.8245 }
  ); // set initial center
  const [currentLocation, setCurrentLocation] = useState(null);

  // const [locationPermission, setLocationPermission] = useState(false);
  //   const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  //   const [homePosition, setHomePosition] = useState({ lat: 0, lng: 0 });
  const mapRef = useRef(null);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     if (type === "Add") {
  //       setCenter({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //       setmapMarkerPosition({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //     }
  //     setCurrentLocation({
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     });
  //     const geocoder = new window.google.maps.Geocoder();
  //     geocoder.geocode(
  //       {
  //         location: {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         },
  //       },
  //       (results, status) => {
  //         if (status === "OK") {
  //           if (results[0]) {
  //             setAddress(results[0].formatted_address);
  //           } else {
  //             setAddress("");
  //           }
  //         } else {
  //           console.error(`Geocoder failed: ${status}`);
  //         }
  //       }
  //     );
  //   });
  // }, [type]);

  // useEffect(() => {
  //   // Check for Geolocation support
  //   if (!navigator.geolocation) {
  //     console.log("Geolocation is not supported by your browser.");
  //     return;
  //   }

  //   // Check for initial permission status
  //   navigator.permissions
  //     .query({ name: "geolocation" })
  //     .then((permissionStatus) => {
  //       setLocationPermission(permissionStatus.state === "granted");

  //       // Watch for permission changes
  //       permissionStatus.onchange = () => {
  //         setLocationPermission(permissionStatus.state === "granted");
  //       };
  //     });

  //   // Fetch initial location
  // }, []);

  useEffect(() => {
    setCenter(mapMarkerPosition);
  }, [mapMarkerPosition]);

  useEffect(() => {
    // if (locationPermission) {
    navigator.geolocation.getCurrentPosition((position) => {
      if (type === "Add") {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setmapMarkerPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
          (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                setAddress(results[0].formatted_address);
              } else {
                setAddress("");
              }
            } else {
              console.error(`Geocoder failed: ${status}`);
            }
          }
        );
      }
    });
    // } else {
    //   console.error("Error getting current location:");
    // }
  }, [type]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  //   const markers = [
  //     {
  //       id: 1,
  //       status: "home",
  //       location: {
  //         lat: homePosition.lat,
  //         lng: homePosition.lng,
  //       },
  //       image: {
  //         url: markerPointer,
  //         scaledSize: {
  //           width: 15,
  //           height: 15,
  //         },
  //       },
  //     },
  //     {
  //       id: 2,
  //       status: "selected",
  //       location: {
  //         lat: mapCenter.lat,
  //         lng: mapCenter.lng,
  //       },
  //       image: {
  //         url: markerIcon,
  //         scaledSize: {
  //           width: 20,
  //           height: 40,
  //         },
  //       },
  //     },
  //   ];
  const handleMapLoad = (map) => {
    mapRef.current = map;
  };
  //   const prevCenterRef = useRef(center);
  //   const handleCenterChanged = () => {
  //     if (mapRef.current) {
  //       const map = mapRef.current;
  //       const center = map.getCenter();
  //       const newCenter = { lat: center.lat(), lng: center.lng() };

  //       if (
  //         newCenter.lat !== prevCenterRef.current.lat ||
  //         newCenter.lng !== prevCenterRef.current.lng
  //       ) {
  //         setMapCenter(newCenter);
  //         setmapMarkerPosition(newCenter);
  //         prevCenterRef.current = newCenter;
  //       }
  //     }
  //   };

  const handleMapDragEnd = () => {
    setCenter({
      lat: mapRef?.current?.getCenter()?.lat() || 20.2961,
      lng: mapRef?.current?.getCenter()?.lng() || 85.8245,
    });
    setmapMarkerPosition({
      lat: mapRef?.current?.getCenter()?.lat() || 20.2961,
      lng: mapRef?.current?.getCenter()?.lng() || 85.8245,
    });
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        location: {
          lat: mapRef?.current?.getCenter()?.lat() || 20.2961,
          lng: mapRef?.current?.getCenter()?.lng() || 85.8245,
        },
      },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setAddress(results[0].formatted_address);
          } else {
            setAddress("");
          }
        } else {
          console.error(`Geocoder failed: ${status}`);
        }
      }
    );
  };

  const handelRecenter = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setmapMarkerPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
          (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                setAddress(results[0].formatted_address);
              } else {
                setAddress("");
              }
            } else {
              console.error(`Geocoder failed: ${status}`);
            }
          }
        );
      });
    }
  };

  console.log(currentLocation, center, "===========");
  return (
    <div className="map-container">
      {isDraggable ? (
        <div className={`marker ${markerPosition && "marker-new"}`}>
          <img src={markerIcon} width={40} height={40} alt="Marker Icon" />
        </div>
      ) : null}
      <GoogleMap
        onLoad={handleMapLoad}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
        ref={mapRef}
        gestureHandling="greedy"
        options={{
          draggable: isDraggable,
          gestureHandling: "greedy",
          disableDefaultUI: true,
          clickableIcons: false,
        }}
        onDragEnd={handleMapDragEnd}
      >
        {/* <Marker
        position={center}
        icon={{ url: markerIcon, scaledSize: { width: 40, height: 40 } }}
      /> */}
        {/* {currentLocation && (
          <Marker
            position={currentLocation}
            icon={{ url: markerPointer, scaledSize: { width: 30, height: 30 } }}
          />
        )} */}
        <button className="recenter" onClick={() => handelRecenter()}>
          <img src={relocateIcon} className="relocateIcon" alt="relocateIcon" />
        </button>
      </GoogleMap>
    </div>
  );
};

export default Map;
