import React, { useState, useEffect } from "react";
import "../../assets/css/paymentHistory.css";
import { Box, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../config/axios.Config";
import { Helmet } from 'react-helmet';
import { MainContext } from "../../config/context.Config";
//import images
import walletIcon from "../../assets/images/walletIcon.svg";
import paymentIcon from "../../assets/images/paymentIcon.svg";
import addIcon from "../../assets/images/addicon.svg";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import { useContext } from "react";
import Moment from 'react-moment';
const PaymentHistory = () => {
  const navigate = useNavigate();

  const { userId } = useContext(MainContext);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Api calling
  useEffect(() => {
    console.log(userId);
    axios
      .get(`/transactions/get/${userId}`)
      .then((res) => {
        setTransactionDetails(res.data.result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  // rendering all transactions
  const renderTransactions = () => {
  
      if (isLoading) {

        return <Box style={{display:"flex",justifyContent: 'center',alignItems: 'center',height:"100vh"}}><Typography style={{fontSize:"18px",fontWeight:"600"}}> Loading . . .</Typography></Box>
      }
      else if (transactionDetails && transactionDetails.length > 0){
      return transactionDetails.map((items, index) => {
        return (
          <Box className="phtab-item" key={index}>
            <img src={paymentIcon} alt="boxIcon" />
            <Box>
              <Typography className="phtop-item">Send Package</Typography>
              <Typography className="phbuttom-item">
                {items.bookingNumber}
              </Typography>
            </Box>
            <Box className="phright-tab">
              <Typography className="phbuttom-item">- {items.amount} ₹</Typography>
              <Typography className="phtop-item"> <Moment format="DD MMMM YYYY" unix>{items.created / 1000}</Moment></Typography>
            </Box>
          </Box>
        );
      });
    }
    else {
      return <Box style={{display:"flex",justifyContent: 'center',
      alignItems: 'center',height:"100vh"}}>
        <Typography style={{fontSize:"18px",fontWeight:"600"}}>No payments yet . . .</Typography>
      </Box>;
    }
    
  };
  return (
    <Box>
      <Helmet>
                <meta name="theme-color" content="#002236" />
            </Helmet>
      <Box className="header_notification">
        {/* <ArrowBackIosIcon onClick={() => navigate(-1)} /> */}
        <img src={back} alt="bell" onClick={() => navigate(-1)} />
        <Typography className="notname">Payment History</Typography>
        <Link to="/notification">
          {/* <NotificationsNoneIcon className='d-change-colour' /> */}
          <img src={bellicon} alt="bell" />
        </Link>
      </Box>
      
            <Box className="phtab-item-top">
            <Box className="phorizontal">{renderTransactions()}</Box>
            </Box>
     
    </Box>
  );
};
export default PaymentHistory;
