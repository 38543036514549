import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/coupon.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationIcon from "../../assets/images/NotificationIcon.svg";
import { Helmet } from "react-helmet";
import back from "../../assets/images/chevron-left.svg";
import { Box, Typography, TextField, Button, Alert } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../config/axios.Config";
import { MainContext } from "../../config/context.Config";
import Moment from "react-moment";
const Coupon = () => {
  const { setCouponDetails } = useContext(MainContext);
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState([]);
  const [selectedCouponCode, setSelectedCouponCode] = useState("");
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const slug = useQuery().get("businessCoupon");
  useEffect(() => {
    axios
      .get("/coupons/get/byCity/C2ZrqFhrQdZKeJrw8JiA")
      .then((res) => {
        setCoupons(res.data.result);
        console.log(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const selectedCouponVerify = (data) => {
    axios
      .get(`/coupons/verify/${data}`)
      .then((res) => {
        if (slug && res.data.result) {
          return navigate(`/businessorder?businessUrl=${slug}`, {
            state: res.data.result.coupon,
          });
        } else if (res.data.result) {
          return navigate("/payments", { state: res.data.result.coupon });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectedCoupon = (data) => {
    // let couponObj = {
    //   id: data.id,
    //   code: data.code,
    //   msg: "Coupon applied",
    //   discount: data.discount,
    // };
    // setCouponDetails(couponObj);
    console.log(data, "----------------");
    setSelectedCouponCode(data);

    // navigate("/payments");
  };
  console.log(coupons);
  return (
    <Box>
      <Helmet>
        <meta name="theme-color" content="#002236" />
      </Helmet>
      <Box className="header_notification">
        {/* <ArrowBackIosIcon onClick={() => navigate(-1)} /> */}
        <img src={back} alt="bell" onClick={() => navigate(-1)} />
        <Typography className="notname">Apply Coupon</Typography>
        <Link to="/notification">
          <img src={NotificationIcon} alt="notifications" />
        </Link>
      </Box>

      <Box className="header_offset">
        <Box className="hor_space stickyBox">
          <Box className="acapplybox">
            <Typography className="acapplycode">
              Enter your coupon code
            </Typography>

            <Box
              className="imginputss flex-fill"
              style={{
                marginLeft: "6px",
                marginTop: "0",
                marginBottom: "0",
                background: "#fff",
                border: "0",
              }}
            >
              <div className="inputHolder" style={{ marginLeft: "0" }}>
                <input
                  id="outlined-start-adornment"
                  placeholder="&nbsp;"
                  value={selectedCouponCode.code}
                  onChange={(e) =>
                    setSelectedCouponCode({
                      ...selectedCouponCode,
                      code: e.target.value,
                    })
                  }
                />
                <label>Coupon Code</label>
              </div>
            </Box>
            {/* <input id="outlined-start-adornment" placeholder="Enter your coupon code" className="acapplyinput" value={selectedCouponCode.code}
              onChange={(e) => setSelectedCouponCode({
                ...selectedCouponCode,
                code: e.target.value,
              })
              } /> */}

            <Box className="separator">
              <div className="dots"></div>
            </Box>
            <Button
              className="acapplybutton"
              onClick={() => selectedCouponVerify(selectedCouponCode.code)}
            >
              Apply Coupon
            </Button>
          </Box>

          <Typography className="workingdays">Available Coupons</Typography>
        </Box>
        <div className="couponContainer">
          {coupons.length > 0 &&
            coupons.map((items) => {
              return (
                <Box key={items.id}>
                  <Box className="phorizontal">
                    <Box className="acdiscountapply">
                      <Box className="acdiscountapplycouponcodee">
                        <Typography className="acmarginleft">
                          {items.code}
                        </Typography>
                      </Box>
                      <Button
                        className="acdiscountapplybutton"
                        onClick={() => selectedCoupon(items)}
                      >
                        Apply
                      </Button>
                    </Box>
                    <Typography className="acdiscountdesc">
                      {items.description}
                    </Typography>
                    <Typography className="acdiscountdescription">
                      Offer valid till{" "}
                      <Moment format="Do MMMM YYYY, h:mm a">
                        {items.endTime}
                      </Moment>
                    </Typography>
                  </Box>
                  <Box className="acmarginy"></Box>
                </Box>
              );
            })}
        </div>
        {coupons.length === 0 && (
          <div className="no-coupon-container">
            <Typography className="no-coupon">No Coupons Available</Typography>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Coupon;
