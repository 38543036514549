import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Helmet } from 'react-helmet';
import back from "../../assets/images/chevron-left.svg"
import bellicon from "../../assets/images/bell-black.svg";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  // window.history.pushState(null, null, window.location.href);
  // window.onpopstate = function () {
  //   window.history.go(1);
  // };
  return (
    <Box className="h-100">
      <Helmet>
        <meta name="theme-color" content="#002236" />
      </Helmet>
      <Box className="header_notification">
        <img src={back} alt="bell" onClick={() => navigate(-1)} />
        <Typography className="notname">Terms & Conditions </Typography>
        <Box style={{ visibility: 'hidden' }}>
          <img src={bellicon} alt="bell" />
        </Box>
      </Box>
    </Box>
  )

};

export default TermsAndConditions;
