import React from "react";
import NinjaLoaderComponent from "../assets/images/NinjaLoader.gif";
import "../assets/css/NinjaLoader.css";
const NinjaLoader = () => {
  return (
    <div className="loaderContainer">
      <div className="mainLoader">
        <img src={NinjaLoaderComponent} alt="Loading..." />
      </div>
    </div>
  );
};

export default NinjaLoader;
