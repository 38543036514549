import markerIcon from "../assets/images/round-pushpin.svg";
//import markerPointer from "../assets/images/markerIcon2.png";
import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import "../assets/css/Map.css";
import NinjaHead from "../assets/images/ninjahead.png";
import relocateIcon from "../assets/images/aim.png";
const OnDeliveyMap = ({
  setmapMarkerPosition,
  setAddress,
  mapMarkerPosition,
  type,
  markerPosition,
  isDraggable,
  centerBetweenPoints,
  markers,
  percentageOffset,
}) => {
  const [center, setCenter] = useState(
    mapMarkerPosition || { lat: 20.2961, lng: 85.8245 }
  ); // set initial center

  const [polyLinePath, setPolyLinePath] = useState(null);
  const [intermediatePoint, setIntermediatePoint] = useState(null);
  // const [locationPermission, setLocationPermission] = useState(false);
  //   const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  //   const [homePosition, setHomePosition] = useState({ lat: 0, lng: 0 });
  const mapRef = useRef(null);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     if (type === "Add") {
  //       setCenter({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //       setmapMarkerPosition({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //     }
  //     setCurrentLocation({
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     });
  //     const geocoder = new window.google.maps.Geocoder();
  //     geocoder.geocode(
  //       {
  //         location: {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         },
  //       },
  //       (results, status) => {
  //         if (status === "OK") {
  //           if (results[0]) {
  //             setAddress(results[0].formatted_address);
  //           } else {
  //             setAddress("");
  //           }
  //         } else {
  //           console.error(`Geocoder failed: ${status}`);
  //         }
  //       }
  //     );
  //   });
  // }, [type]);

  // useEffect(() => {
  //   // Check for Geolocation support
  //   if (!navigator.geolocation) {
  //     console.log("Geolocation is not supported by your browser.");
  //     return;
  //   }

  //   // Check for initial permission status
  //   navigator.permissions
  //     .query({ name: "geolocation" })
  //     .then((permissionStatus) => {
  //       setLocationPermission(permissionStatus.state === "granted");

  //       // Watch for permission changes
  //       permissionStatus.onchange = () => {
  //         setLocationPermission(permissionStatus.state === "granted");
  //       };
  //     });

  //   // Fetch initial location
  // }, []);

  useEffect(() => {
    setCenter(mapMarkerPosition);
  }, [mapMarkerPosition]);

  useEffect(() => {
    // if (locationPermission) {
    navigator.geolocation.getCurrentPosition((position) => {
      if (type === "Distance") {
        setCenter(centerBetweenPoints);
      }
    });
    // } else {
    //   console.error("Error getting current location:");
    // }
  }, [type, centerBetweenPoints]);

  useEffect(() => {
    if (markers?.length > 0) {
      setPolyLinePath([markers[0]?.position, markers[1]?.position]);
    }
  }, [markers]);

  const containerStyle = {
    width: "100%",
    height: "400px",
  };
  //   const markers = [
  //     {
  //       id: 1,
  //       status: "home",
  //       location: {
  //         lat: homePosition.lat,
  //         lng: homePosition.lng,
  //       },
  //       image: {
  //         url: markerPointer,
  //         scaledSize: {
  //           width: 15,
  //           height: 15,
  //         },
  //       },
  //     },
  //     {
  //       id: 2,
  //       status: "selected",
  //       location: {
  //         lat: mapCenter.lat,
  //         lng: mapCenter.lng,
  //       },
  //       image: {
  //         url: markerIcon,
  //         scaledSize: {
  //           width: 20,
  //           height: 40,
  //         },
  //       },
  //     },
  //   ];
  const handleMapLoad = (map) => {
    mapRef.current = map;
  };
  //   const prevCenterRef = useRef(center);
  //   const handleCenterChanged = () => {
  //     if (mapRef.current) {
  //       const map = mapRef.current;
  //       const center = map.getCenter();
  //       const newCenter = { lat: center.lat(), lng: center.lng() };

  //       if (
  //         newCenter.lat !== prevCenterRef.current.lat ||
  //         newCenter.lng !== prevCenterRef.current.lng
  //       ) {
  //         setMapCenter(newCenter);
  //         setmapMarkerPosition(newCenter);
  //         prevCenterRef.current = newCenter;
  //       }
  //     }
  //   };

  const handleMapDragEnd = () => {
    setCenter({
      lat: mapRef?.current?.getCenter()?.lat() || 20.2961,
      lng: mapRef?.current?.getCenter()?.lng() || 85.8245,
    });
    setmapMarkerPosition({
      lat: mapRef?.current?.getCenter()?.lat() || 20.2961,
      lng: mapRef?.current?.getCenter()?.lng() || 85.8245,
    });
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        location: {
          lat: mapRef?.current?.getCenter()?.lat() || 20.2961,
          lng: mapRef?.current?.getCenter()?.lng() || 85.8245,
        },
      },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setAddress(results[0].formatted_address);
          } else {
            setAddress("");
          }
        } else {
          console.error(`Geocoder failed: ${status}`);
        }
      }
    );
  };
  console.log(polyLinePath?.length, "$$$$$$$$$$$$$$$$$$");

  const handelRecenter = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        setmapMarkerPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
          (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                setAddress(results[0].formatted_address);
              } else {
                setAddress("");
              }
            } else {
              console.error(`Geocoder failed: ${status}`);
            }
          }
        );
      });
    }
  };
  useEffect(() => {
    if (mapRef.current && polyLinePath?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      polyLinePath.forEach((point) => bounds.extend(point));
      const padding = 70; // Adjust the padding value as needed
      mapRef.current.fitBounds(bounds, padding);
    }
  }, [polyLinePath]);

  console.log(window.google, markers, "$$$$$$$$$$$$$$");

  const calculatePositionOnPolyline = (path, percentage) => {
    console.log(path, "path");
    const totalDistance =
      window.google.maps.geometry.spherical.computeLength(path);
    const targetDistance = (percentage / 100) * totalDistance;

    let accumulatedDistance = 0;
    let currentPosition = null;

    for (let i = 1; i < path.length; i++) {
      const prevPoint = path[i - 1];
      const currentPoint = path[i];
      const segmentDistance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          prevPoint,
          currentPoint
        );

      if (accumulatedDistance + segmentDistance >= targetDistance) {
        const remainingDistance = targetDistance - accumulatedDistance;
        const heading = window.google.maps.geometry.spherical.computeHeading(
          prevPoint,
          currentPoint
        );
        currentPosition = window.google.maps.geometry.spherical.computeOffset(
          prevPoint,
          remainingDistance,
          heading
        );
        break;
      }

      accumulatedDistance += segmentDistance;
    }

    return currentPosition;
  };

  console.log(percentageOffset, "===========================");

  useEffect(() => {
    if (markers?.length > 0 && polyLinePath?.length > 0) {
      setIntermediatePoint(
        calculatePositionOnPolyline(polyLinePath, percentageOffset)
      );
    }
  }, [markers, polyLinePath, percentageOffset]);

  return (
    <div className="map-container">
      {isDraggable ? (
        <div className={`marker ${markerPosition && "marker-new"}`}>
          <img src={markerIcon} width={40} height={40} alt="Marker Icon" />
        </div>
      ) : null}
      <GoogleMap
        onLoad={handleMapLoad}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        ref={mapRef}
        gestureHandling="greedy"
        options={{
          draggable: isDraggable,
          gestureHandling: "greedy",
          disableDefaultUI: true,
          clickableIcons: false,
        }}
        onDragEnd={handleMapDragEnd}
      >
        {type === "Distance" && markers.length > 0
          ? markers.map((items) => {
              return (
                <Marker
                  position={items.position}
                  icon={items.icon}
                  key={items.id}
                />
              );
            })
          : null}
        {type === "Distance" && markers.length > 0 ? (
          <>
            <Polyline
              path={polyLinePath}
              geodesic={true}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
              }}
            />
            {intermediatePoint && (
              <Marker position={intermediatePoint} icon={NinjaHead} />
            )}
          </>
        ) : null}
        {/* <Marker
        position={center}
        icon={{ url: markerIcon, scaledSize: { width: 40, height: 40 } }}
      /> */}
        {/* {currentLocation && (
          <Marker
            position={currentLocation}
            icon={{ url: markerPointer, scaledSize: { width: 30, height: 30 } }}
          />
        )} */}
        <button className="recenter" onClick={() => handelRecenter()}>
          <img src={relocateIcon} className="relocateIcon" alt="relocateIcon" />
        </button>
      </GoogleMap>
    </div>
  );
};

export default OnDeliveyMap;
