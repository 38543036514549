import React, { useState, useEffect, useContext } from "react";
import "../../assets/css/findingNinja.css";
import { Box, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../config/axios.Config";
import { toast, Toaster } from "react-hot-toast";
import { MainContext } from "../../config/context.Config";
import { app } from "../../config/firebase.Config";
import {
  doc,
  onSnapshot,
  collection,
  query,
  where,
  getFirestore,
  getDoc,
  orderBy,
} from "firebase/firestore";
//image..
import clock from "../../assets/images/hourglass.gif";
import logo from "../../assets/images/appstore.png";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import NinjaLoader from "../../components/NinjaLoader";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

const FindingNinja = () => {
  const { userId, setPickupDetails, setDropDetails, setInstructionDetails } =
    useContext(MainContext);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [bookingNumber, setBookingNumber] = useState();
  const [acceptedRider, setAcceptedRiderDetails] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const duration = 300000; //
  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds === 59) {
        setMinutes((prevMinutes) => prevMinutes + 1);
        setSeconds(0);
      } else {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      setPickupDetails("");
      setDropDetails("");
      setInstructionDetails("");
      navigate("/", { state: null }, { replace: true });
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate, setPickupDetails, setDropDetails, setInstructionDetails]);
  console.log(state, "===============================================");
  window.onpopstate = () => {
    setPickupDetails("");
    setDropDetails("");
    setInstructionDetails("");
    navigate("/", { state: null }, { replace: true });
  };

  const handleBackClick = () => {
    setPickupDetails("");
    setDropDetails("");
    setInstructionDetails("");

    navigate("/", { state: null }, { replace: true });
  };

  useEffect(() => {
    sessionStorage.removeItem("paymentDetails");
  }, []);

  useEffect(() => {
    axios
      .get(`/bookings/get/${userId}`)
      .then((res) => {
        res.data.result = res.data.result.sort((a, b) =>
          a.bookingNumber !== b.bookingNumber
            ? a.bookingNumber > b.bookingNumber
              ? -1
              : 1
            : 0
        );

        setBookingNumber(res.data.result[0].bookingNumber);
        getRiders(res.data.result[0]?.id);
        localStorage.setItem("bookingId", res.data.result[0]?.id);
      })
      .catch((err) => {
        toast.error("Some error occured");
      });
  }, []);

  const getRiders = async (currentBookingId) => {
    const db = getFirestore(app);
    const tasksQ = query(
      collection(db, "tasks"),
      where("bookingId", "==", currentBookingId),
      where("type", "==", "pickup")
    );
    let riderTasksArray = [];
    onSnapshot(tasksQ, (taskSnapshot) => {
      if (taskSnapshot.docs.length > 0) {
        const riderTasksQ = query(
          collection(db, "riderTasks"),
          where("taskId", "==", taskSnapshot.docs[0].id),
          where("status", "==", "accepted")
        );
        onSnapshot(riderTasksQ, (riderTasksSnapshot) => {
          if (riderTasksSnapshot.docs.length > 0) {
            navigate("/ondelivery");
            // const riderDocRef = doc(
            //   db,
            //   "riders",
            //   riderTasksSnapshot.docs[0].riderId
            // );
            // getDoc(riderDocRef).then((riderDocRefSnap) => {
            //   let riderTaskObj = {
            //     ...riderTasksSnapshot.docs[0].data(),
            //     ...riderDocRefSnap.data(),
            //   };
            //   riderTaskObj["_id"] = riderTasksSnapshot.docs[0].id;
            //   riderTaskObj["taskId"] = taskSnapshot.docs[0].id;
            //   riderTaskObj["distance"] = taskSnapshot.docs[0].data().distance;
            //   riderTaskObj["duration"] = taskSnapshot.docs[0].data().duration;
            //   riderTaskObj["otp"] = taskSnapshot.docs[0].data().otp;
            //   riderTaskObj["taskCreated"] = taskSnapshot.docs[0].data().created;
            //   riderTaskObj["rateId"] = taskSnapshot.docs[0].data().rateId;
            //   setAcceptedRiderDetails(riderTaskObj);
            // });
          }
        });
      }
    });
  };

  return (
    <Box className="h-100">
      <Helmet>
        <meta name="theme-color" content="#002236" />
      </Helmet>
      <Toaster toastOptions={{ duration: 4000 }} />
      <Box className="header_notification">
        <img src={back} alt="bell" onClick={handleBackClick} />
        <Typography className="notname">Finding Ninja</Typography>
        <Link to="/notification">
          <img src={bellicon} alt="bell" />
        </Link>
      </Box>
      <Box className="header_offset d-flex flex-column h-100">
        
          <Box className="finding-ninja">
            <Typography className="heading">Finding Ninja...</Typography>

            <Typography className="description">
              Ninja Courier will pick up your package soon.
            </Typography>

            <Typography className="bookingnumber">BookingNumber</Typography>
            <Typography className="bookingnumber">{bookingNumber}</Typography>

            <Box className="counter">
              <Box className=""></Box>
              {/* <Typography className="timer">06:34:19</Typography> */}
              <img src={clock} alt="" style={{ height: "110px" }} />
              <Box m={2}>
                <Typography variant="h2">
                  {`${minutes < 10 ? `0${minutes}` : minutes}:${
                    seconds < 10 ? `0${seconds}` : seconds
                  }`}
                </Typography>
              </Box>
            </Box>

            {/* <Typography
            className="cancelFinding"
            onClick={() => setOpenBottomSheet(true)}
          >
            Cancel
          </Typography> */}
          </Box>
        
      </Box>

      <BottomSheet
        defaultSnap={({ maxHeight }) => maxHeight / 5}
        snapPoints={({ minHeight }) => minHeight}
        expandOnContentDrag={true}
        open={openBottomSheet}
        close={openBottomSheet}
        onDismiss={() => setOpenBottomSheet(false)}
      >
        <div className="addess-sheet" style={{ overflow: "auto" }}>
          <Box className="bottomdesign">
            <Typography className="ppaddress">Choose the Reason</Typography>
            <Box className="chooseReason">
              <Typography>I don't want it anymore</Typography>
            </Box>
            <Box className="chooseReason">
              <Typography>The price is very expensive</Typography>
            </Box>
            <Box className="chooseReason">
              <Typography>I don't want to wait anymore</Typography>
            </Box>
            <Box className="chooseReason">
              <Typography>No reason</Typography>
            </Box>
            <Button
              variant="contained"
              disableElevation
              className="continueebtnn"
            >
              Cancel the task
            </Button>
          </Box>
        </div>
      </BottomSheet>
    </Box>
  );
};

export default FindingNinja;
