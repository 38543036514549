import React, { useState, useEffect, useContext } from "react";
import "../../assets/css/orderHistory.css";
import { Link, useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import axios from "../../config/axios.Config";
import { Toaster, toast } from "react-hot-toast";

import { MainContext } from "../../config/context.Config";
//import images...
import loader from "../../assets/images/loader.gif";
import boxIcon from "../../assets/images/boxIcon.svg";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import NinjaLoader from "../../components/NinjaLoader";
import Moment from "react-moment";

const OrderHistory = () => {
  const navigate = useNavigate();
  const { userId } = useContext(MainContext);
  // All states.................
  const [activeTab, setActiveTab] = useState("tab1");
  const [bookingHistory, setBookingHistory] = useState([]);
  const [loaderObject, setLoaderObject] = useState(true);

  // Here Booking history is divided in to two arrays one is complected and another is for active
  const [complectedProduct, activeProduct] = bookingHistory.reduce(
    (result, current) => {
      if (current.stageObj?.stage === "Completed" || current.stageObj?.stage === "Cancelled") {
        result[0].push(current);
      } else {
        result[1].push(current);
      }
      return result;
    },
    [[], []]
  );
  console.log(userId);
  // Api calling to get booking history
  useEffect(() => {
    axios
      .get(`/bookings/get/${userId}`)
      .then((res) => {
        setBookingHistory(res.data.result);
        setLoaderObject(false);
      })
      .catch((err) => {
        toast.error("Some error occured");
      });
  }, []);

  const handleClick = (event, tabName) => {
    setActiveTab(tabName);
  };

  const handleStatus = (data) => {
    if (data?.stageObj?.stage === "Booked") {
      if (new Date().getTime() > data?.scheduleBooking) {
        navigate("/findingNinja");
      } else if (data.created < data?.scheduleBooking) {
        navigate("/schedule-booking", { state: data });
      } else {
        navigate("/findingNinja");
      }
    } else if (data?.stageObj?.stage === "Completed") {
      navigate(`/orderDelivered/${data.id}`);
    } else {
      localStorage.setItem("bookingId", data.id);
      navigate(`/ondelivery`);
    }
  };
  console.log(activeProduct);

  const handleCompleteBooking = (data) => {
    if(data?.stageObj?.stage === "Completed"){
      navigate(`/orderDelivered/${data.id}`)
    }
  }

  // render start.....
  if (!loaderObject) {
    return (
      <Box className="h-100">
        <Helmet>
          <meta name="theme-color" content="#002236" />
        </Helmet>
        <Toaster toastOptions={{ duration: 4000 }} />
        <Box className="header_notification">
          {/* <ArrowBackIosIcon onClick={() => navigate(-1)} /> */}
          <img src={back} alt="bell" onClick={() => navigate(-1)} />
          <Typography className="notname">Orders</Typography>
          <Link to="/notification">
            {/* <NotificationsNoneIcon className='d-change-colour' /> */}
            <img src={bellicon} alt="bell" />
          </Link>
        </Box>
        <Box className="header_offset">
          <div className="tab">
            <button
              className={`tablinks ${activeTab === "tab1" ? "active" : ""}`}
              onClick={(e) => handleClick(e, "tab1")}
            >
              Active
            </button>
            <button
              className={`tablinks ${activeTab === "tab2" ? "active" : ""}`}
              onClick={(e) => handleClick(e, "tab2")}
            >
              Completed
            </button>
          </div>
          <Box className="phorizontal">
            <div
              className={`tabcontent ${activeTab === "tab1" ? "show" : ""}`}
              id="tab1"
            >
              {activeProduct.map((data) => {
                return (
                  <Box
                    onClick={() => handleStatus(data)}
                    key={data.id}
                    className="tab-item"
                  >
                    <img src={boxIcon} alt="status" />
                    <Box>
                      <Typography className="top-item">
                        Order from{" "}
                        <Moment format="MMMM DD" unix>
                          {data?.created / 1000}
                        </Moment>
                      </Typography>
                      <Typography className="buttom-item">
                        {data?.bookingNumber}
                      </Typography>
                    </Box>
                    <Button className="button-pick">
                      {data?.stageObj?.stage}
                    </Button>
                  </Box>
                );
              })}
            </div>
            <div
              className={`tabcontent ${activeTab === "tab2" ? "show" : ""}`}
              id="tab2"
            >
              {complectedProduct.map((data) => {
                return (
                  <div onClick={() => handleCompleteBooking(data)}>
                    <Box className="tab-item">
                      <img src={boxIcon} alt="status" />
                      <Box>
                        <Typography className="top-item">
                          Order from{" "}
                          <Moment format="MMMM DD" unix>
                            {data?.created / 1000}
                          </Moment>
                        </Typography>
                        <Typography className="buttom-item">
                          {" "}
                          {data?.bookingNumber}
                        </Typography>
                      </Box>
                      <Button className={`${data?.stageObj?.stage === "Cancelled" ? "button_pick" : "button-pick" }`}>
                        {data?.stageObj?.stage}
                      </Button>
                    </Box>
                  </div>
                );
              })}
            </div>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <div>
        <NinjaLoader />
      </div>
    );
  }
};
export default OrderHistory;
