import React, { useState, useEffect, useContext } from "react";
import "../../assets/css/Business/business.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "../../config/axios.Config";
import ReactWhatsapp from "react-whatsapp";
import { RWebShare } from "react-web-share";
import { toast, Toaster } from "react-hot-toast";
import { MainContext } from "../../config/context.Config";
// mui imports start
import {
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Modal,
  Chip,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// mui import end
// image import start
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import pnlogoo from "../../assets/images/business/pnlogoo.svg";
import call from "../../assets/images/callicon.svg";
import whatsapp from "../../assets/images/business/whatsappicon.png";
import cuser from "../../assets/images/business/cuser-white.png";
import Close from "../../assets/images/business/Close.svg";
import location from "../../assets/images/locationIcon.svg";
import rdot from "../../assets/images/business/rdot.svg";
import machine from "../../assets/images/business/businessName.svg";
import chain from "../../assets/images/business/linkLink.png";
import copy from "../../assets/images/business/copy.svg";
import wwhatsapp from "../../assets/images/business/wwhatsapp.svg";
import clock from "../../assets/images/clock.png";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { tConvert } from "../../util/FormatTime";
import NinjaLoader from "../../components/NinjaLoader";

// image import end

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  padding: "0 8px",
};

const Businessshare = ({ businessData }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { userId, setUserPreference } = useContext(MainContext);
  const [businessInfo, setBusinessInfo] = useState(businessData);

  const clipboardHandler = () => {
    toast.success("Copied successfully!");
    navigator.clipboard.writeText(`${process.env.REACT_APP_CUST_URL}${businessInfo.url}`);
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const type = useQuery().get("addressType");
  const locationPath = useLocation();
  const buPath = locationPath.pathname.split("/")[1];
  useEffect(() => {
    async function getBusinessDetails() {
      try {
        const businessData = await axios.get(`/business/get/${userId}`);
        setBusinessInfo(businessData.data.result);
      } catch (error) {
        // navigate("/pageNotFound");
        setBusinessInfo(false);
      }
    }
    getBusinessDetails();
  }, [userId]);

  window.onpopstate = () => {
    localStorage.removeItem("businessPreference")
    setUserPreference(false);
    navigate("/", { state: null }, { replace: true });
  };

  const handleEdit = () => {
    navigate("/dashboard", {
      state: {
        isEditing: true,
        switchAccount: true,
        displayy: "upperlayerone",
      },
    });
  };

  let businessReopen = "Closed today";
  const day = new Date().getDay();

  let nextDay = null;
  let ms = new Date().getTime() + 86400000;
  let tomorrow = new Date(ms);
  if (tomorrow.getDay() === 0) {
    nextDay = 0;
  } else {
    nextDay = day + 1;
  }

  if (businessInfo?.businessDays.includes(day.toString())) {
    businessReopen = <>Open today</>;
  } else if (businessInfo?.businessDays.includes(nextDay.toString())) {
    businessReopen = <>Reopen at {businessInfo?.startTime} AM, tomorrow</>;
  }

  const handleBusinessBack = ()  => {
    localStorage.removeItem("businessPreference")
    setUserPreference(false);
    navigate("/")
  }

  if (!businessInfo) {
    return <NinjaLoader />;
  }

  if (businessInfo) {
    return (
      <Box>
        <Helmet>
          <meta name="theme-color" content="#002236" />
        </Helmet>
        <Toaster toastOptions={{ duration: 4000 }} />
        <div className={`${buPath !== "business" && "m-bottom"}`}>
          {/* {type === "business" ? (
            <Box className="business-head">
              <img src={pnlogoo} alt="pnlogo" className="plogooo" />
              {!businessInfo.avatar && (
                <img src={cuser} alt="business logo" className="plogoooo" />
              )}
              {businessInfo.avatar && (
                <img
                  src={businessInfo.avatar}
                  alt="business logo"
                  className="plogoooo"
                />
              )}
            </Box>
          ) : ( */}
          <>
            {buPath === "business" ? (
              <div className="business-head" style={{marginBottom:"20px"}}>
                <Box className="header_notification" style={{boxShadow:"none"}}>
                    <img
                    src={back}
                    onClick={handleBusinessBack}
                    alt="Arrow Left Icon"
                  />
                  {/* <Typography sx={{ fontWeight: "600 !important" }} className="notname">
                    Business
                  </Typography> */}
                  <img src={pnlogoo} alt="location" />
                  <Link to="/notification">
                    <img src={bellicon} alt="notifications" />
                  </Link>
                </Box>
                {businessInfo.avatar && (
                  <>
                    {/* <Box
                      onClick={handleEdit}
                      sx={{
                        position: "absolute",
                        top: "9%",
                        left: "74%",
                        color: "white",
                      }}
                    >
                      <Chip
                        icon={<EditOutlinedIcon color="white" />}
                        label="Edit"
                        variant="outlined"
                        sx={{ color: "white" }}
                      />
                    </Box> */}
                    <img
                      src={businessInfo.avatar}
                      alt="pnlogo....."
                      className="plogoooo"
                      style={{position:"relative",top:"75px"}}
                    />
                  </>
                )}
                {!businessInfo.avatar && (
                  <>
                    <img src={cuser} style={{position:"relative",top:"75px"}} alt="pnlogo333" className="plogoooo" />
                    {/* <Box
                      onClick={handleEdit}
                      sx={{
                        position: "absolute",
                        top: "9%",
                        left: "74%",
                        color: "white",
                      }}
                    >
                      <Chip
                        icon={<EditOutlinedIcon color="white" />}
                        label="Edit"
                        variant="outlined"
                        sx={{ color: "white" }}
                      />
                    </Box> */}
                  </>
                )}
              </div>
            ) : (
              <>
                {businessInfo.avatar && (
                  <>
                    <Box className="imageCont">
                      {/* <Box
                        onClick={handleEdit}
                        sx={{
                          position: "absolute",
                          top: "9%",
                          left: "74%",
                          color: "white",
                        }}
                      >
                        <Chip
                          icon={<EditOutlinedIcon color="white" />}
                          label="Edit"
                          variant="outlined"
                          sx={{ color: "white" }}
                        />
                      </Box> */}
                      <img
                        src={businessInfo.avatar}
                        alt="pnlogo22"
                        className="plogoooo"
                      />
                    </Box>
                  </>
                )}
                {!businessInfo.avatar && (
                  <>
                    <img src={cuser} alt="pnlogo" className="plogoooo" />
                    {/* <Box
                      onClick={handleEdit}
                      sx={{
                        position: "absolute",
                        top: "9%",
                        left: "74%",
                        color: "white",
                      }}
                    >
                      <Chip
                        icon={<EditOutlinedIcon color="white" />}
                        label="Edit"
                        variant="outlined"
                        sx={{ color: "white" }}
                      />
                    </Box> */}
                  </>
                )}
              </>
            )}
          </>
          {/* )} */}
        </div>
        <Typography className="bsaccount">Business Account</Typography>
        <Box className="phorizontal">
          <Box className="dcontain">
            <img src={location} alt="location" className="locicon" />
            <Box>
              <Typography className="locationhead">
                {businessInfo?.name || businessInfo?.addressObj?.name}
              </Typography>
              <Typography className="locationtitle">
                {businessInfo.addressObj.address}
              </Typography>
              <Typography className="locationtitle dflexxx">
                {" "}
                <img src={rdot} alt="pnlogo" className="locicon" />
                {businessReopen}
              </Typography>
            </Box>
          </Box>
          <Box className="dcontain align-items-center">
            <img src={machine} alt="location" className="locicon" />
            <Box>
              <Typography className="locationhead">About business</Typography>
              <Typography className="locationtitle">
                {businessInfo.about}
              </Typography>
            </Box>
          </Box>
          <Box className="dcontain align-items-center">
            <img src={call} alt="location" className="locicon" />
            <Box>
              <Typography className="locationhead">Phone number</Typography>
              <Typography className="locationtitle">
                {businessInfo.addressObj.mobile}
              </Typography>
            </Box>
          </Box>
          <Box className="dcontain align-items-center">
            <img src={whatsapp} alt="location" className="locicon" />
            <Box>
              <Typography className="locationhead">WhatsApp number </Typography>
              <Typography className="locationtitle">
                {businessInfo.whatsapp}
              </Typography>
            </Box>
          </Box>
          <Box className="dcontain align-items-center">
            <img src={chain} alt="location" className="locicon" />
            <Box className="flex-fill">
              <Typography className="locationhead">
                {process.env.REACT_APP_CUST_URL}{businessInfo.url}
              </Typography>
              <Typography className="locationtitle">
                Link for customers
              </Typography>
            </Box>
            {/* <img src={contact} alt="location" className="lociconn" /> */}
            <Typography className="changeName" onClick={clipboardHandler} style={{alignSelf: 'center'}}>
            <img src={copy} alt="location" style={{"marginLeft": "10px"}} className="locicon" />
            </Typography>
          </Box>
          <Box className="dcontain align-items-center">
            <img src={clock} alt="location" className="locicon" />
            <Box>
              <Typography className="locationtitle">Working hours</Typography>
              <Typography className="locationhead">
                {tConvert(businessInfo.startTime)} -{" "}
                {tConvert(businessInfo.endTime)}
              </Typography>
            </Box>
          </Box>
          <div>
            <Typography className="i-package">Package Type</Typography>
            <Swiper resistanceRatio={0}
              spaceBetween={10}
              breakpoints={{
                576: {
                  width: 576,
                  slidesPerView: 4.2,
                },
                768: {
                  width: 768,
                  slidesPerView: 5.5,
                },
              }}
              slidesPerView={4.2}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              className="i-swipe-package"
            >
              {businessInfo?.packageTypes?.map((data) => {
                return (
                  <SwiperSlide className="i-swiper-itemss" key={data?.id}>
                    <Box className={`i-package-imgg-lay`}>
                      <img
                        src={data?.icon}
                        alt="pnlogo"
                        className="i-package-imgg"
                      />
                    </Box>
                    <Typography className="i-package-item-name">
                      {data?.type}
                    </Typography>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Box>
        <Box className="borderbottomm"></Box>
        <Box className="phorizontal">
          <Typography className="youracc">
            Your account is ready! How does it work?
          </Typography>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItem>
              <ListItemText>
                1. Share it with your customers on WhatsApp or another messanger{" "}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. Your customers can call or write you to place ordersand
                settle payment directly{" "}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. They can get their orders picked by entering the drop
                location{" "}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                4. Delivery fee is paid by customer on Ninja
              </ListItemText>
            </ListItem>
          </List>
          <Box className="dflexxxxx">
            {/* <ReactWhatsapp
              number={businessInfo.whatsapp}
              message={`Hi there, you can order ${businessInfo?.packageTypes
                .map((t) => t.type)
                .join(", ")} from https://app.pickup.ninja/${
                businessInfo?.url
              } and get it delivered at your door steps.`}
              className="chat-sellerr"
            >
              <img
                style={{ width: "36px", height: "36px", marginRight: 0 }}
                src={wwhatsapp}
                alt="location"
                className="locicon"
              />
            </ReactWhatsapp> */}
             <Box
              onClick={() =>{
                window.location.href=(`${process.env.REACT_APP_CUST_URL}businessorder?businessUrl=${businessInfo.url}`)
              }}
            >
              <Button className="callsellerr">Book Now</Button>
            </Box>
            <RWebShare
              data={{
                text: `Hi there, you can order ${businessInfo?.packageTypes
                  .map((t) => t.type)
                  .join(", ")} from ${process.env.REACT_APP_CUST_URL}${
                  businessInfo?.url
                } and get it delivered at your door steps.`,
                url: `${process.env.REACT_APP_CUST_URL}${businessInfo.url}`,
                title: "Pickup Ninja",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <Button className="callsellerr">Share the link</Button>
            </RWebShare>
          </Box>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box onClick={handleClose} className="button-close">
              <img src={Close} alt="close" />
            </Box>
            <Typography className="placedorder">
              Have you placed your order?
            </Typography>
            <Typography className="placedordercon">
              We hope that you already do it and it is ready for pick up
            </Typography>
            <Button className="mbuttonnm">
              Yes, continue get order pick up
            </Button>
            <Button className="mbuttonnm mbuttonnmmm">
              No, call saler place my order
            </Button>
          </Box>
        </Modal>
      </Box>
    );
  }
};

export default Businessshare;
