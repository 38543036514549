import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Toaster } from "react-hot-toast";
import Rating from "@mui/material/Rating";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import delivered from "../../assets/images/delivered.svg";
import location from "../../assets/images/locationIcon.svg";
import call from "../../assets/images/callicon.svg";
import user from "../../assets/images/user_add.svg";
import clock from "../../assets/images/clock.png";
import rupee from "../../assets/images/rupee.png";
import envelope from "../../assets/images/envelope-white.svg";
import avtar from "../../assets/images/aavtar.jpg";
import gal from "../../assets/images/img1.png";
import invoice from "../../assets/images/invoiceIcon.svg";
import { Helmet } from "react-helmet";
import axios from "../../config/axios.Config.jsx";
import Moment from "react-moment";
import NinjaLoader from "../../components/NinjaLoader";

const OrderDelivered = () => {
  const [activeTab, setActiveTab] = useState("picked");
  const [bookingDetails, setBookingDetails] = useState(null);
  const [riderDetails, setRiderDetails] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event, tabName) => {
    setActiveTab(tabName);
  };

  const { id } = useParams();

  useEffect(() => {
    async function getBusinessDetails() {
      try {
        const response = await axios.get(`bookings/get/bookingDetails/${id}`);
        setBookingDetails(response.data.result);
      } catch (error) {
        navigate("/pageNotFound")
        console.log(error);
      }
    }
    getBusinessDetails();
  }, []);
  useEffect(() => {
    async function getBusinessDetails() {
      try {
        const response = await axios.get(
          `/riders/get/${bookingDetails?.rider?.riderId}`
        );
        setRiderDetails(response.data.result);
      } catch (error) {
        console.log(error);
      }
    }
    getBusinessDetails();
  }, [bookingDetails?.rider?.riderId]);


  let bookingTotal = `₹${bookingDetails?.amount || 0}`

  if(bookingDetails?.discount) {
    bookingTotal = `₹${bookingDetails?.amount - bookingDetails?.discount}`
  }

  if (!bookingDetails) {
    return <NinjaLoader />;
  }

  return (
    <Box>
      <Helmet>
        <meta name="theme-color" content="#57C28A" />
      </Helmet>
      <Box
        className="header_notification"
        style={{ backgroundColor: "#57C28A", boxShadow: "none" }}
      >
        <Toaster toastOptions={{ duration: 4000 }} />
        <img src={back} alt="bell" onClick={() => navigate(-1)} />
        <Typography className="notname">Order Details</Typography>
        <Link to="/notification">
          <img src={bellicon} alt="bell" />
        </Link>
      </Box>
      <Box className="header_offset">
        <Box className="deliveryDetailsHeader">
          <h3>{bookingDetails?.bookingNumber}</h3>
          <div className="d-flex">
            <div className="flex-fill">
              {bookingDetails?.stageObj?.stage === "Booking" ? <h4>Order in progress</h4> : <h4>Successfully Delivered</h4>}
              <p>{bookingDetails?.dropDestinationObj?.address}</p>
            </div>
            <div
              className=""
              style={{ marginLeft: "-70px", marginBottom: "-3px" }}
            >
              <img src={delivered} alt="" />
            </div>
          </div>
        </Box>

        <Box className="phorizontal" style={{ marginTop: "16px" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="workingdays">
                Delivery Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="dcontain" style={{ marginTop: "16px" }}>
                <img src={location} alt="location" className="locicon" />
                <Box>
                  <Typography className="locationhead">
                    {bookingDetails?.dropDestinationObj?.name}
                  </Typography>
                  <Typography className="locationtitle">
                    {bookingDetails?.dropDestinationObj?.address}
                  </Typography>
                </Box>
              </Box>
              <Box className="dcontain">
                <img src={clock} alt="location" className="locicon" />
                <Box>
                  <Typography className="locationtitle">Delivery</Typography>
                  <Typography className="locationhead">
                    at{" "}
                    <Moment format="Do MMMM h:mm a">
                      {bookingDetails?.modified}
                    </Moment>
                  </Typography>
                </Box>
              </Box>
              <Box className="dcontain">
                <img src={call} alt="location" className="locicon" />
                <Box>
                  <Typography className="locationtitle">
                    Contact number
                  </Typography>
                  <Typography className="locationhead">
                    {bookingDetails?.dropDestinationObj?.mobile}
                  </Typography>
                </Box>
              </Box>
              <Box className="dcontain">
                <img src={user} alt="location" className="locicon" />
                <Box>
                  <Typography className="locationtitle">
                    Contact name
                  </Typography>
                  <Typography className="locationhead">
                    {bookingDetails?.dropDestinationObj?.name}
                  </Typography>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="phorizontal" style={{ marginTop: "16px" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="workingdays">
                Pickup Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="dcontain" style={{ marginTop: "16px" }}>
                <img src={location} alt="location" className="locicon" />
                <Box>
                  <Typography className="locationhead">
                    {bookingDetails?.pickupDestinationObj?.name}
                  </Typography>
                  <Typography className="locationtitle">
                    {bookingDetails?.pickupDestinationObj?.address}
                  </Typography>
                </Box>
              </Box>
              <Box className="dcontain">
                <img src={clock} alt="location" className="locicon" />
                <Box>
                  <Typography className="locationtitle">Delivery</Typography>
                  <Typography className="locationhead">
                    at{" "}
                    <Moment format="Do MMMM h:mm a">
                      {bookingDetails?.created}
                    </Moment>
                  </Typography>
                </Box>
              </Box>
              <Box className="dcontain">
                <img src={call} alt="location" className="locicon" />
                <Box>
                  <Typography className="locationtitle">
                    Contact number
                  </Typography>
                  <Typography className="locationhead">
                    {bookingDetails?.pickupDestinationObj?.mobile}
                  </Typography>
                </Box>
              </Box>
              <Box className="dcontain">
                <img src={user} alt="location" className="locicon" />
                <Box>
                  <Typography className="locationtitle">
                    Contact name
                  </Typography>
                  <Typography className="locationhead">
                    {bookingDetails?.pickupDestinationObj?.name}
                  </Typography>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* <Box className="emailSend">
          <Typography className="locationhead" style={{ color: "#fff" }}>
            Send Me Invoice at E-mail
          </Typography>
          <img src={envelope} alt="location" className="" />
        </Box> */}
        <Box className="dcontain align-items-center" style={{ height: "62px" }}>
          <img src={rupee} alt="location" className="locicon" />
          <Box className="flex-fill">
            <Typography className="locationtitle">Delivery cost</Typography>
          </Box>
          <Typography className="locationhead">
            ₹{bookingDetails?.amount - bookingDetails?.discount}
          </Typography>
        </Box>

        <Box className="mbottom"></Box>
        {riderDetails && <Box className="phorizontal" style={{ marginTop: "16px" }}>
          <Box style={{ marginTop: "16px" }}>
            <Typography className="workingdays">Ninja infromation</Typography>
            <Box
              className="d-flex align-items-center rider"
              style={{ marginTop: "16px" }}
            >
              <img
                src={riderDetails?.avatar ? riderDetails.avatar : avtar}
                alt="avtar"
                className="aavtarr user-image"
              />
              <div className="riderName flex-fill">
                <strong>{riderDetails?.name}</strong>
                <p className="d-flex" style={{ fontSize: "14px" }}>
                  <b className="d-flex">
                    Rating{" "}
                    <Rating
                      name="read-only"
                      value={5}
                      readOnly
                      precision={0.5}
                      size="small"
                      style={{ marginLeft: "10px" }}
                    />
                  </b>
                  (5)
                </p>
              </div>
            </Box>
          </Box>
        </Box>}

        <Box className="mbottom"></Box>

        {/* <Box className="phorizontal" style={{ marginTop: "16px" }}>
          <Box className="d-flex justify-content-between">
            <Typography className="workingdays">View Images</Typography>
            <Typography
              className=""
              style={{ color: "#E3552E", fontWeight: "700" }}
            >
              Show more
            </Typography>
          </Box>
        </Box>

        <div className="tab">
          <button
            className={`tablinks ${activeTab === "picked" ? "active" : ""}`}
            onClick={(e) => handleClick(e, "picked")}
          >
            Picked Up
          </button>
          <button
            className={`tablinks ${activeTab === "delivered" ? "active" : ""}`}
            onClick={(e) => handleClick(e, "delivered")}
          >
            Delivered
          </button>
        </div>
        <Box className="phorizontal">
          <div
            className={`tabcontent ${activeTab === "picked" ? "show" : ""}`}
            id="picked"
            style={{ marginTop: "16px" }}
          >
            <div className="imgGal">
              <div>
                <img src={gal} alt="" />
              </div>
              <div>
                <img src={gal} alt="" />
              </div>
              <div>
                <img src={gal} alt="" />
              </div>
              <div>
                <img src={gal} alt="" />
              </div>
              <div>
                <img src={gal} alt="" />
              </div>
              <div>
                <img src={gal} alt="" />
              </div>
            </div>
          </div>
          <div
            className={`tabcontent ${activeTab === "delivered" ? "show" : ""}`}
            id="delivered"
            style={{ marginTop: "16px" }}
          >
            <div className="imgGal">
              <div>
                <img src={gal} alt="" />
              </div>
              <div>
                <img src={gal} alt="" />
              </div>
              <div>
                <img src={gal} alt="" />
              </div>
              <div>
                <img src={gal} alt="" />
              </div>
            </div>
          </div>
        </Box> */}

        <Box className="mbottom"></Box>

        <Box className="phorizontal">
          <Box className="paymentslip">
            <Box className="paymentsliptop">
              <img src={invoice} alt="location" className="invoice" />
              <Box>
                <Typography className="locationtitle">Total Payment</Typography>
                <Typography className="locationhead">
                {bookingTotal}
                </Typography>
              </Box>
            </Box>
            <Typography className="paymentdetails">Payment Details</Typography>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box className="bill">
                <Typography className="">
                  Delivery, {bookingDetails?.distance} km
                </Typography>
              </Box>
              <Typography
                style={{
                  flex: "1",
                  borderBottom: "2px dashed #ccc",
                  margin: "0 10px",
                }}
              ></Typography>
              <Typography style={{ fontWeight: "700" }}>
                ₹{bookingDetails?.amount}
              </Typography>
            </Box>
            {bookingDetails?.discount && (
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box className="bill">
                  <Typography className="">Discount</Typography>
                </Box>
                <Typography
                  style={{
                    flex: "1",
                    borderBottom: "2px dashed #ccc",
                    margin: "0 10px",
                  }}
                ></Typography>
                <Typography style={{ fontWeight: "700" }}>
                  ₹{bookingDetails?.discount}
                </Typography>
              </Box>
            )}

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <Box className="bill">
                <Typography className="billcost">Total Cost</Typography>
              </Box>
              <Typography
                style={{
                  flex: "1",
                  borderBottom: "2px dashed #ccc",
                  margin: "0 10px",
                }}
              ></Typography>
              <Typography style={{ fontWeight: "700" }}>
                
                {bookingTotal}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="phorizontal" style={{ textAlign: "center" }}>
          <Typography className="workingdays" style={{ fontSize: "18px" }}>
            Rate Your Delivery
          </Typography>
          <Rating name="size-large" defaultValue={2} size="large" />
        </Box>

        <Box className="phorizontal" style={{ marginTop: "16px" }}>
          <textarea
            name=""
            id=""
            cols="30"
            rows="6"
            placeholder="Review"
            className="textarea-instruction"
          ></textarea>
        </Box>
        <Box className="hor_space">
          <Button
            sx={{ textTransform: "none", fontSize: 16 }}
            className="continueebtnn"
          >
            submit review
          </Button>
        </Box>

        {/* <Box className="phorizontal" style={{ marginTop: "16px" }}>
                    <Typography className="workingdays" style={{ textAlign: 'center' }}>Give tipp</Typography>
                    <Box className="bs-working" style={{ gridTemplateRows: 'auto' }}>
                        <label className="work-container tipp" for='50'>
                            <input id="50" type="radio" name="tipp" value="50" />
                            <span className='mark'>₹50</span>
                        </label>
                        <label className="work-container tipp" for='100'>
                            <input id="100" type="radio" name="tipp" value="100" />
                            <span className='mark'>₹100</span>
                        </label>
                        <label className="work-container tipp" for='150'>
                            <input id="150" type="radio" name="tipp" value="150" />
                            <span className='mark'>₹150</span>
                        </label>
                        <label className="work-container tipp" for='200'>
                            <input id="200" type="radio" name="tipp" value="200" />
                            <span className='mark'>₹200</span>
                        </label>
                    </Box>
                </Box> */}

        {/* <Box className="hor_space">
          <Button
            sx={{ textTransform: "none", fontSize: 16 }}
            className="continueebtnn"
          >
            Go to homepage
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default OrderDelivered;
