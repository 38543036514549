import React, { useState, useRef, useContext, useEffect } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import axios from "../../config/axios.Config";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import AvatarEditor from "react-avatar-editor"; // For image cropping
import defaultAvatar from "../../assets/images/business/cuser.svg"; // Default avatar image
import "../../assets/css/profile.css";
import delimgg from "../../assets/images/deleteIcon.svg";
import Close from "../../assets/images/business/Close.svg";
import LO from "../../assets/images/LO.svg";
import { toast, Toaster } from "react-hot-toast";
import { MainContext } from "../../config/context.Config";
import { OTPInput } from "../../components/OTPInput";
import { getAuth, signOut } from "firebase/auth";
import bellicon from "../../assets/images/bell-black.svg";
import back from "../../assets/images/chevron-left.svg";
import call from "../../assets/images/phone_add.svg";
import user from "../../assets/images/user_add.svg";
import support from "../../assets/images/support.png";
import rarrow from "../../assets/images/greaterthanIcon.svg";
import Resizer from "react-image-file-resizer";
const Profile = () => {
  const { userId, name, avatar, mobile } = useContext(MainContext);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editor, setEditor] = useState(null);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [userName, setUserName] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const editorRef = useRef(null);
  const imageInputRef = useRef(null);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const image = await resizeFile(file);
      const blob = new Blob([image], { type: "image/jpeg" });
      const imageFile = new File([blob], "resizedImage.jpg", {
        type: "image/jpeg",
      });
      setImage(imageFile);
      setPreview(null);
    }
  };

  useEffect(() => {
    setUserName(name);
    setSelectedImage(avatar);
  }, [name, avatar]);

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      setPreview(canvas.toDataURL());
    }
  };
  const handleImageClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const form = new FormData();
  form.append("name", userName);
  form.append("avatarFile", image);

  const updateUser = () => {
    axios
      .put(`/users/update/${userId}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        navigate("/dashboard");
      })
      .catch((err) => console.log(err));
  };

  function handelLogout() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
        toast.err(error);
      });
  }

  // function for delete Account
  function handelDelete() {
    axios
      .delete(`users/delete/${userId}`)
      .then(() => {
        localStorage.clear();
        navigate("/");
      })
      .catch(() => {
        toast.error("Unable to delete .....");
      });
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 335,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 5,
    padding: "10px",
  };

  //   const goToBusinessAccount =  () => {
  //     navigate("/business")
  //   }
  return (
    <>
      <Box className="h-100  d-flex flex-column">
        <Helmet>
          <meta name="theme-color" content="#002236" />
        </Helmet>
        <Toaster toastOptions={{ className: "ff_Cabin", duration: 4000 }} />
        {name || avatar ? (
          <Box className="header_notification">
            <Toaster toastOptions={{ duration: 4000 }} />
            <img src={back} alt="bell" onClick={() => navigate(-1)} />
            <Typography className="notname">Edit Profile</Typography>
            <Link to="/notification">
              <img src={bellicon} alt="bell" />
            </Link>
          </Box>
        ) : (
          <></>
        )}
        <Box className="flex-fill phorizontal d-flex flex-column align-items-stretch header_offset">
          <div>
            {avatar ? (
              <div className="image-container">
                <img
                  src={avatar}
                  width="200px"
                  height="200px"
                  alt="Default Avatar"
                  className="image"
                />
              </div>
            ) : (
              <>
                <Typography className="add_head ff_Cabin">
                  Welcome to Pickup ninja
                </Typography>
                <label className="d-block text-center ff_Cabin text_blue_fs18_700">
                  Add Profile Photo
                </label>
                <div className="image-container" onClick={handleImageClick}>
                  {image ? (
                    <AvatarEditor
                      ref={editorRef}
                      image={URL.createObjectURL(image)}
                      width={250}
                      height={250}
                      border={0}
                      borderRadius={125}
                      color={[255, 255, 255, 0.6]} // Transparent background
                      scale={1}
                      rotate={0}
                      onImageReady={() => setEditor(editorRef.current)}
                      onMouseUp={() => handleSave()}
                    />
                  ) : (
                    <img
                      src={defaultAvatar}
                      alt="Default Avatar"
                      className="image" style={{height: '225px', width: '225px'}}
                    />
                  )}
                  {/* {preview && <img src={preview} alt="Preview" />} */}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={imageInputRef}
                    style={{ display: "none" }}
                  />
                </div>
              </>
            )}
          </div>

          <Box
            className="imginputss"
            style={{ background: "#F9F9F9", border: "0" }}
          >
            <img src={user} alt="pnuser" />
            <div className="inputHolder">
              <input
                type="text"
                name="businessName"
                placeholder="&nbsp;"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <label>Name</label>
            </div>
            {avatar ? (
              <Typography className="changeName">Change</Typography>
            ) : (
              <></>
            )}
          </Box>

          <Box
            className="imginputss"
            style={{ background: "#F9F9F9", border: "0" }}
          >
            <img src={call} alt="pnuser" />
            <div className="inputHolder" style={{ height: "43px" }}>
              <div className="singleLineText">{mobile}</div>
              <label
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  transform: "none",
                }}
              >
                Registered Mobile
              </label>
            </div>
          </Box>

          {avatar ? (
            <Box
              className="imginputss"
              style={{ background: "#F9F9F9", border: "0" }}
            >
              <img src={support} alt="pnuser" />
              <div className="inputHolder" style={{ height: "43px" }}>
                <div className="singleLineText">Request Support</div>
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                    transform: "none",
                  }}
                >
                  Chat with our support staffs
                </label>
              </div>
              <img src={rarrow} alt="location" className="pickboxicon" />
            </Box>
          ) : (
            <></>
          )}

          {/* <input className="add_image_user ff_Cabin" placeholder='Enter your name' value={userName} onChange={(e) => setUserName(e.target.value)} /> */}
          {/* <Typography className="placedorder">
            {name && mobile}
          </Typography> */}
        </Box>
        <Box className="hor_space" style={{ paddingBottom: "20px" }}>
          <Button onClick={() => updateUser()} className="i_save_inst ff_Cabin">
            {name ? "update" : "Add"}
          </Button>
          <Box className="d-flex" style={{ marginTop: "10px" }}>
            <Button
              className="flex-fill logOutBtn"
              onClick={() => handelLogout()}
              style={{ marginRight: "5px" }}
            >
              Log Out
            </Button>
            <Button
              className="flex-fill deleteAccBtn"
              onClick={handleOpen}
              style={{ marginLeft: "5px" }}
              disabled
            >
              Delete Account
            </Button>
          </Box>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box onClick={handleClose} className="button-close">
            <img src={Close} alt="close" />
          </Box>
          <Typography className="placedorder">
            Are you sure you want to delete your account?
          </Typography>

          <Box className="d-flex">
            <Button
              sx={{
                marginTop: "10px !important",
                marginRight: "5px !important",
              }}
              className="deleteAccBtn flex-fill"
              onClick={() => handelDelete()}
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              className="logOutBtn flex-fill"
              sx={{
                marginTop: "10px !important",
                marginLeft: "5px !important",
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default Profile;
