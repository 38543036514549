import React, { useEffect, useState } from "react";
import Main from "./Main";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// files
import FireBaseAuth from "../pages/customer/FireBaseAuth";
import Dashboard from "../pages/customer/dashBoard";
import Profile from "../pages/customer/Profile";
import AddAddress from "../pages/customer/AddAddress";
import SavedAddress from "../pages/customer/SavedAddress";
import OrderHistory from "../pages/customer/OrderHistory";
import PaymentHistory from "../pages/customer/PaymentHistory";
import Notification from "../pages/customer/Notification";
import Instruction from "../pages/customer/Instruction";
import PaymentDetails from "../pages/customer/PaymentDetail";
import Coupon from "../pages/customer/Coupon";
import ResisterBusiness from "../pages/business/RegisterBusiness";
import Business from "../pages/business/Business";
import Businesssharetwo from "../pages/business/Businesssharetwo";
import Businessorder from "../pages/business/Businessorder";
import Test from "../pages/customer/test";
import { getAuth } from "firebase/auth";
import NinjaLoader from "../components/NinjaLoader";
import TermsAndConditions from "../pages/customer/TermsAndConditions";
import FindingNinja from "../pages/customer/findingNinja";
import OnDelivery from "../pages/customer/OnDelivery";
import OrderDelivered from "../pages/customer/OrderDelivered";
import NoRider from "../pages/customer/NoRider";
import PageNotFound from "../pages/customer/PageNotFound";
import LocationDetails from "../pages/customer/LocationDetails";
import ScheduledBooking from "../pages/customer/ScheduledBooking";

const Router = () => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((token) => {
            localStorage.setItem("token", token);
            setUser(true);
          })
          .catch((err) => console.log(err));
      } else {
        setUser(false);
      }
    });
  }, []);

  console.log(
    {
      apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
      authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
      databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
      projectId: `${process.env.REACT_APP_PROJECT_ID}`,
      storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
      messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
      appId: `${process.env.REACT_APP_APP_ID}`,
      measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`,
    },
    auth,
    "=====================",
    user,
    "$Test$"
  );

  if (user === null) {
    return <NinjaLoader />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route
          index
          element={user ? <Navigate to="/dashboard" /> : <FireBaseAuth />}
        />
        <Route
          path="/dashboard"
          element={user ? <Dashboard /> : <FireBaseAuth />}
        />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route
          path="/profile/:id"
          element={user ? <Profile /> : <FireBaseAuth />}
        />
        <Route
          path="/destination/:name"
          element={user ? <AddAddress /> : <FireBaseAuth />}
        />
        <Route
          path="/address/:topic"
          element={user ? <SavedAddress /> : <FireBaseAuth />}
        />
        <Route
          path="/history/order"
          element={user ? <OrderHistory /> : <FireBaseAuth />}
        />
        <Route
          path="/history/payment"
          element={user ? <PaymentHistory /> : <FireBaseAuth />}
        />
        <Route
          path="/notification"
          element={user ? <Notification /> : <FireBaseAuth />}
        />
        <Route
          path="/instruction/:type"
          element={user ? <Instruction /> : <FireBaseAuth />}
        />
        <Route
          path="/payments"
          element={user ? <PaymentDetails /> : <FireBaseAuth />}
        />
        <Route
          path="/coupon/add"
          element={user ? <Coupon /> : <FireBaseAuth />}
        />
        <Route
          path="/register/business"
          element={user ? <ResisterBusiness /> : <FireBaseAuth />}
        />
        <Route
          path="/business"
          element={user ? <Business /> : <FireBaseAuth />}
        />
        <Route
          path="/city/add"
          element={user ? <LocationDetails/> : <FireBaseAuth/>}
        />
        <Route path="/:slug" element={<Businesssharetwo />} />
        <Route path="/businessorder" element={<Businessorder />} />
        <Route path="/test" element={user ? <Test /> : <FireBaseAuth />} />
        <Route
          path="/findingNinja"
          element={user ? <FindingNinja /> : <FireBaseAuth />}
        />
        <Route
          path="/schedule-booking"
          element={user ? <ScheduledBooking /> : <FireBaseAuth />}
        />
        <Route
          path="/ondelivery"
          element={user ? <OnDelivery /> : <FireBaseAuth />}
        />
        <Route
          path="/orderDelivered/:id"
          element={user ? <OrderDelivered /> : <FireBaseAuth />}
        />
        <Route
          path="/noRider"
          element={user ? <NoRider /> : <FireBaseAuth />}
        />
        <Route
          path="/pageNotFound"
          element={user ? <PageNotFound /> : <FireBaseAuth />}
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
